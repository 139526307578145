/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _effects - Small.
*/
/* Links. */
.text-link, .cart-empty p a {
    position: relative;
    display: inline-block;
    font-family: $roboto;
    font-size: 14px;
    font-weight: 400;
    font-style: normal;
    line-height: 14px;
    text-decoration: none !important;

    &:before,
    &:after {
        width: 0;
        height: 2px;
        top: calc(100% + 6px);
        right: 0;
        display: block;
        position: absolute;
        content: '';
    }

    &:before {
        background-color: rgba($white, .2);
        transition: width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s;
    }

    &:after {
        background-color: $white;
        transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
    }
}

.cart-empty p a {
    &:before {
        background-color: rgba($black, .2);
    }

    &:after {
        background-color: $black;
    }
}

/* Buttons. */
.jt-button {
    padding: 0px 0px 0px 30px;
    position: relative;
    color: $white;
    font-family: $roboto;
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    line-height: 20px;
    text-decoration: none;

    &:before {
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        position: absolute;
        transform: translateY(-1px) rotate(-90deg);
        content: url('../images/jt-button-arrow.svg');
    }
}

.materialize-button, #prod_button, #add_prod {
    width: 200px;
    height: 45px;
    padding: 0rem 2rem 0rem 2rem;
    position: relative;
    display: inline-block;
    background-color: $yellow;
    border: none !important;
    border-radius: 5px;
    box-shadow: 3px 3px 12px rgba($black, 0);
    color: $grey !important;
    font-family: $roboto;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    line-height: 45px;
    text-align: center;
    text-decoration: none !important;
    overflow: hidden;
    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

    span {
        pointer-events: none !important;

        &.ripple {
            transform: scale(.25);
            position: absolute;
            background-color: rgba($black, .25);
            border-radius: 100%;
            opacity: 0;
            pointer-events: none !important;
            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
        }
    }

    &:focus {
        color: $grey;
    }
}

@keyframes ripple {
    from {
        opacity: 1;
    }

    to {
        transform: scale(2);
        opacity: 0;
    }
}

/*<button class="circle-button">
    <div class="circle">
        <span class="icon arrow">

        </span>
    </div>
    <span class="text">
        Text Goes Here
    </span>
</button>*/

.circle-button {
    // width: ;
    position: relative;
    cursor: pointer;

    .circle {
        width: 50px;
        height: 50px;
        position: relative;
        // background-color: $;
        border-radius: 25px;
        transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);

        .icon {
            margin: auto;
            top: 0;
            bottom: 0;
            position: absolute;

            &.arrow {
                width: 17.5px;
                height: 2px;
                left: 15px;
                background-color: transparent;
                transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);

                &:before {
                    width: 10px;
                    height: 10px;
                    top: -4px;
                    left: 4px;
                    transform: translateX(0) rotate(45deg);
                    position: absolute;
                    // border-top: 2px solid $;
                    // border-right: 2px solid $;
                    content: '';
                    transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);
                }
            }
        }
    }

    .text {
        top: 0;
        right: 15px;
        position: absolute;
        // color: $ !important;
        // font-family: $;
        // font-size: ;
        // font-weight: ;
        // font-style: ;
        // line-height: ;
        transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);
    }
}

/*<a class="arrow-button" href="">
    <span class="arrow left">
        <span class="shaft">

        </span>
    </span>
    <span class="main">
        <span class="text">
            Next Article
        </span>
        <span class="arrow right">
            <span class="shaft">

            </span>
        </span>
    </span>
</a>*/

.arrow-button {
    padding: 0 !important;
    margin: 0 !important;
    position: relative;
    display: inline-block;
    background-color: transparent !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);

    .arrow {
        width: 60px;
        transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);

        &.left {
            top: calc(50% - 1px);
            left: 0;
            position: absolute;

            > .shaft {
                width: 0;
                // background-color: $;

                &:before, &:after {
                    width: 0;
                    transform: rotate(0);
                    // background-color: $;
                }
            }
        }

        &.right {
            top: 50%;
            position: absolute;

            > .shaft {
                width: 60px;
                transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88) .2s;

                &:before, &:after {
                    width: 10px;
                    transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88) .3s;
                }

                &:before {
                    transform: rotate(45deg);
                }

                &:after {
                    transform: rotate(-45deg);
                }
            }
        }

        > .shaft {
            height: 2px;
            position: relative;
            display: block;
            // background-color: $;
            transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);

            &:before, &:after {
                height: 2px;
                top: 0;
                right: 0;
                position: absolute;
                display: block;
                // background-color: $;
                transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);
                content: '';
            }

            &:before {
                transform-origin: top right;
            }

            &:after {
                transform-origin: bottom right;
            }
        }
    }

    .main {
        display: flex;
        align-items: center;
        transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);

        .text {
            margin: 0px 30px 0px 0px;
            // color: $;
            // font-family: $;
            // font-size: ;
            // font-weight: ;
            // font-style: ;
            // line-height: ;
            transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);
        }

        .arrow {
            position: relative;
        }
    }

    &.frm_button_submit {
        // margin:  !important;
    }
}

/* Wow. */
.wow {
    visibility: hidden;
}

.fadeIn {
    @include fadeIn(
        $function: cubic-bezier(0.51, 0.18, 0, 0.88)
    );
}

.fadeInUp {
    @include fadeInUp(
        $function: cubic-bezier(0.51, 0.18, 0, 0.88)
    );
}

.fadeInLeft {
    @include fadeInLeft(
        $function: cubic-bezier(0.51, 0.18, 0, 0.88)
    );
}

.fadeInRight {
    @include fadeInRight(
        $function: cubic-bezier(0.51, 0.18, 0, 0.88)
    );
}

.flipInX {
    @include flipInX(
        $function: cubic-bezier(0.51, 0.18, 0, 0.88)
    );
}

.flipInY {
    @include flipInY(
        $function: cubic-bezier(0.51, 0.18, 0, 0.88)
    );
}

@include keyframes(zoomOut) {
    0% {
        opacity: 1;
        @include transform(scale3d(1.2, 1.2, 1.2));
    }

    100% {
        opacity: 1;
        @include transform(scale3d(1, 1, 1));
    }
}

@mixin zoomOut($count: $countDefault, $duration: $durationDefault, $delay: $delayDefault, $function: $functionDefault, $fill: $fillDefault, $visibility: $visibilityDefault) {
    @include animation-name(zoomOut);
    @include count($count);
    @include duration($duration);
    @include delay($delay);
    @include function($function);
    @include fill-mode($fill);
    @include visibility($visibility);
}

.zoomOut {
    @include zoomOut(
        $duration: 1.2s,
        $function: cubic-bezier(0.51, 0.18, 0, 0.88)
    );
}

/* Block Reveal. */
.block-reveal-element {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    // background-color: $;
    opacity: 0;
    pointer-events: none;
}

/* Matrix Hover. */
#matrixContainer {
    width: 100%;
    height: 100%;
    display: block;
    perspective: 120px;

    #matrixContainerInner {
        width: 100%;
        height: 100%;
        transition: .4s cubic-bezier(0.51, 0.18, 0, 0.88);

        .image {
            width: 100%;
            height: 100%;
        }
    }
}

/* SmoothState. */
.wrapper {
    position: relative;
    // perspective: 1000px; Search C.
    overflow: hidden;

    .wrapper-inner {
        transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);
        position: relative;
        opacity: 1;
        transition: .4s cubic-bezier(0.51, 0.18, 0, 0.88);
    }

    &.hamburger-is-active {
        .navigation-overlay {
            transform: translateX(0);
            // box-shadow: 3px 0px 30px $black;
            opacity: 1;
            visibility: visible;
        }

        .wrapper-inner {
            // transform: translate3d(0px, 0px, 0px) scale3d(.95, .95, 1);
            transform: translate3d(calc(100% - 25px - .625rem * 2), 0px, 0px) scale3d(1, 1, 1);
        }
    }

    // Search B.
    // &.search-is-active {
    //  .wrapper-inner {
    //      transform: translate3d(0px, 0px, 0px) scale3d(.95, .95, 1);
    //     }
    // }

    // Search C.
    // &.search-is-active {
    //  height: 100vh;
    //  pointer-events: none;

    //  .wrapper-inner {
    //      transform: translate3d(0px, 0px, -2400px) rotate3d(0, 0, 1, 3deg);
    //  }
    // }

    &.slide-out {
        .wrapper-inner {
            transform: translate3d(0px, -240px, 0px) scale3d(1, 1, 1);
            opacity: 0;
        }
    }

    // &.zoom-out {
    //  .wrapper-inner {
    //      transform: translate3d(0px, 0px, 0px) scale3d(.95, .95, 1);
    //      opacity: 0;
    //  }
    // }
}

/**
* _effects - Medium.
*/
@media screen and (min-width: 640px) {
    /* Buttons. */
    .arrow-button {
        .arrow {
            width: 90px;

            &.right {
                > .shaft {
                    width: 90px;
                }
            }
        }

        .main {
            .text {
                // font-size: ;
                // line-height: ;
            }
        }
    }

    /* SmoothState. */
    .wrapper {
        &.hamburger-is-active {
            .wrapper-inner {
                transform: translate3d(360px, 0px, 0px) scale3d(1, 1, 1);
            }
        }
    }
}

/**
* _effects - Large.
*/
@media screen and (min-width: 1024px) {
    /* Links. */
    .text-link, .cart-empty p a {
        &:hover {
            &:before, &:after {
                width: 100%;
                left: 0;
            }
        }
    }

    /* Buttons. */
    .materialize-button, .actions a, .actions button, .actions-toolbar a, .actions-toolbar button, button.action, #prod_button, #add_prod {
        &:hover {
            box-shadow: 3px 3px 12px rgba($black, .3) !important;
            color: $grey !important;
        }
    }

    .circle-button {
        &:hover {
            .circle {
                width: 100%;

                .icon {
                    &.arrow {
                        width: 35px;
                        // background-color: $;

                        &:before {
                            transform: translateX(20px) rotate(45deg);
                        }
                    }
                }
            }

            .text {
                // color: $ !important;
            }
        }
    }

    .arrow-button {
        .arrow {
            width: 120px;

            &.right {
                > .shaft {
                    width: 120px;
                }
            }
        }

        .main {
            .text {
                // font-size: ;
                // line-height: ;
            }
        }

        &:hover {
            > .arrow {
                &.left {
                    > .shaft {
                        width: 120px;
                        // background-color: $;

                        &:before, &:after {
                            width: 10px;
                            // background-color: $;
                        }

                        &:before {
                            transform: rotate(45deg);
                        }

                        &:after {
                            transform: rotate(-45deg);
                        }
                    }
                }
            }

            > .main {
                transform: translateX(150px);

                .text {
                    margin: 0;
                    // color: $;
                }

                > .arrow {
                    &.right {
                        > .shaft {
                            width: 0;
                            transform: translateX(100%);

                            &:before, &:after {
                                width: 0;
                                transform: rotate(0);
                                transition-delay: 0s;
                            }
                        }
                    }
                }
            }
        }
    }

    /* SmoothState. */
    .wrapper {
        &.hamburger-is-active {
            .navigation-overlay {
                transform: translateX(-100%);
                box-shadow: 3px 0px 30px transparent;
                opacity: 0;
                visibility: hidden;
            }

            .wrapper-inner {
                transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1);

                &:before {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
    }
}

/**
* _effects - XL.
*/
@media screen and (min-width: 1200px) {
    /* Buttons. */
    .arrow-button {
        .arrow {
            width: 150px;

            &.right {
                > .shaft {
                    width: 150px;
                }
            }
        }

        &:hover {
            > .arrow {
                &.left {
                    > .shaft {
                        width: 150px;
                    }
                }
            }

            > .main {
                transform: translateX(180px);
            }
        }
    }
}

/**
* _effects - XXL.
*/
@media screen and (min-width: 1440px) {
    /* Buttons. */
    .arrow-button {
        .arrow {
            width: 180px;

            &.right {
                > .shaft {
                    width: 180px;
                }
            }
        }

        &:hover {
            > .arrow {
                &.left {
                    > .shaft {
                        width: 180px;
                    }
                }
            }

            > .main {
                transform: translateX(210px);
            }
        }
    }
}