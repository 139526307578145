/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _corporate-sponsorship - Small.
*/


/**
* _corporate-sponsorship - Medium.
*/
@media screen and (min-width: 640px) {
	
}

/**
* _corporate-sponsorship - Large.
*/
@media screen and (min-width: 1024px) {
	
}

/**
* _corporate-sponsorship - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _corporate-sponsorship - XXL.
*/
@media screen and (min-width: 1440px) {

}