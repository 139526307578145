/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*
* Example Checkbox Text: By selecting this box you agree for us to contact you using the information provided above and to everything outlined in our Privacy Policy.
*
*/

/**
* _forms - Small.
*/
form {
	fieldset {
		margin: 0 !important;
		letter-spacing: normal;

		.field {
			margin: 0rem 0rem 1rem 0rem !important;

			&.note {
				margin: 0rem 0rem 1.5rem 0rem !important;
				color: $grey;
				font-family: $roboto;
				font-size: 14px;
				font-weight: 400;
				font-style: normal;
				line-height: 26px;
			}

			&.street {
				.control {
					input {
						margin: 0rem 0rem 1rem 0rem;
					}

					.nested {
						.field {
							.control {
								input {
									margin: 0;
								}
							}
						}
					}
				}
			}

			label, .label {
				width: 100% !important;
				height: auto;
				padding: 0 !important;
				margin: 0px 0px 5px 0px !important;
				background-color: transparent;
				text-align: left !important;
				float: none !important;

				span {
					color: $grey;
					font-family: $roboto;
					font-size: 14px;
					font-weight: 700;
					font-style: normal;
					line-height: 26px;
				}

				&:after {
					display: none;
				}
			}

			.control {
				width: 100% !important;
				float: none !important;

				input, select, textarea {
					width: 100%;
					height: 40px;
					padding: 0rem 1rem 0rem 1rem;
					margin: 0;
					background-color: $lightGrey;
					border: none;
					border-radius: 5px;
					box-shadow: none;
					color: $grey;
					font-family: $roboto;
					font-size: 14px;
					font-weight: 400;
					font-style: normal;
					line-height: 40px;
					z-index: 0 !important;

					&.mage-error {
						margin: 0 !important;
						color: $grey !important;
						font-family: $roboto !important;
						font-size: 14px !important;
						font-weight: 400 !important;
						font-style: normal !important;
						line-height: 40px !important;
					}
				}

				textarea {
					min-height: 150px;
					padding: 1rem;
					line-height: 26px;
				}

				@include placeholder {
					color: $mediumGrey;
				}

				.mage-error {
					margin: 5px 0px 0px 0px !important;
					font-family: $roboto;
					font-size: 14px;
					font-weight: 700;
					font-style: normal;
					line-height: 26px;
				}
			}

			&.choice {
				input {
					margin: 0rem .25rem 0rem 0rem;
					top: 1px;
					position: relative;
				}

				&:before {
					display: none;
				}
			}

			&:last-of-type {
				margin: 0 !important;

				&.choice {
					margin: 0rem 0rem 2rem 0rem !important;
				}
			}
		}

		.actions-toolbar {
			width: 100%;
			padding: 0;
			margin: 2rem 0rem 0rem 0rem !important;
			display: flex;
			flex-flow: row wrap;

			button {
				width: 200px !important;
			    height: 45px !important;
			    padding: 0rem 2rem 0rem 2rem !important;
			    position: relative !important;
			    display: inline-block !important;
			    background-color: $yellow !important;
			    border: none !important;
			    border-radius: 5px !important;
			    box-shadow: 3px 3px 12px rgba($black, 0) !important;
			    color: $grey !important;
			    font-family: $roboto !important;
			    font-size: 16px !important;
			    font-weight: 700 !important;
			    font-style: normal !important;
			    line-height: 45px !important;
			    text-align: center !important;
			    text-decoration: none !important;
			    overflow: hidden !important;
			    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83) !important;

			    span {
			    	pointer-events: none;
			    	
			        &.ripple {
			            transform: scale(.25);
			            position: absolute;
			            background-color: rgba($black, .25);
			            border-radius: 100%;
			            opacity: 0;
			            pointer-events: none;
			            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
			        }
			    }

			    &:focus {
			        color: $grey !important;
			    }
			}

			.primary, .secondary {
				width: 100%;
				margin: 0;
				text-align: left;
			}

			.secondary {
				margin: 1rem 0rem 0rem 0rem;
				text-align: left;

				a {
					width: auto !important;
				}
			}
		}

		&:after {
			display: none !important;
		}
	}

	.actions-toolbar {
		width: 100%;
		padding: 0;
		margin: 2rem 0rem 0rem 0rem !important;
		display: flex;
		flex-flow: row wrap;

		button {
			width: 200px !important;
		    height: 45px !important;
		    padding: 0rem 2rem 0rem 2rem !important;
		    position: relative !important;
		    display: inline-block !important;
		    background-color: $yellow !important;
		    border: none !important;
		    border-radius: 5px !important;
		    box-shadow: 3px 3px 12px rgba($black, 0) !important;
		    color: $grey !important;
		    font-family: $roboto !important;
		    font-size: 16px !important;
		    font-weight: 700 !important;
		    font-style: normal !important;
		    line-height: 45px !important;
		    text-align: center !important;
		    text-decoration: none !important;
		    overflow: hidden !important;
		    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83) !important;

		    span {
		    	pointer-events: none;

		        &.ripple {
		            transform: scale(.25);
		            position: absolute;
		            background-color: rgba($black, .25);
		            border-radius: 100%;
		            opacity: 0;
		            pointer-events: none;
		            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
		        }
		    }

		    &:focus {
		        color: $grey !important;
		    }
		}

		.primary, .secondary {
			width: 100%;
			margin: 0;
			text-align: left;
		}

		.secondary {
			margin: 1rem 0rem 0rem 0rem;
			text-align: left;

			a {
				width: auto !important;
			}
		}
	}
}

.primary, .secondary {
	text-align: left !important;
}

a.primary {
	width: 200px !important;
    height: 45px !important;
    padding: 0rem 2rem 0rem 2rem !important;
    position: relative !important;
    display: inline-block !important;
    background-color: $yellow !important;
    border: none !important;
    border-radius: 5px !important;
    box-shadow: 3px 3px 12px rgba($black, 0) !important;
    color: $grey !important;
    font-family: $roboto !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    font-style: normal !important;
    line-height: 45px !important;
    text-align: center !important;
    text-decoration: none !important;
    overflow: hidden !important;
    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83) !important;

    span {
    	pointer-events: none;

        &.ripple {
            transform: scale(.25);
            position: absolute;
            background-color: rgba($black, .25);
            border-radius: 100%;
            opacity: 0;
            pointer-events: none;
            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
        }
    }

    &:focus {
        color: $grey !important;
    }

	&:before, &:after {
		display: none;
	}
}

#password-strength-meter-container {
	#password-strength-meter {
		height: auto;
		padding: .625rem;
		margin: 0;
		background-color: transparent;
		color: $grey;
		font-family: $roboto;
		font-size: 14px;
		font-weight: 700;
		font-style: normal;
		line-height: 26px;
	}
}

.pc_form_widget_view_0 {
	.pccf-customer-data-scope {
		.pccf {
			max-width: none !important;

			.form-view {
				form {
					fieldset {
						padding: 0;
						margin: 0 !important;
						border: none;
						letter-spacing: normal;

						.form-group {
							margin: 0rem 0rem 1rem 0rem !important;

							label {
								width: 100% !important;
								height: auto;
								padding: 0 !important;
								margin: 0px 0px 5px 0px !important;
								background-color: transparent;
								color: $grey;
								font-family: $roboto;
								font-size: 14px;
								font-weight: 700;
								font-style: normal;
								line-height: 26px;
								text-align: left !important;
								float: none !important;
							}

							input, select, textarea {
								width: 100%;
								height: 40px;
								padding: 0rem 1rem 0rem 1rem;
								margin: 0;
								background-color: $white;
								border: 1px solid $lightGrey;
								border-radius: 5px;
								box-shadow: none;
								color: $grey;
								font-family: $roboto;
								font-size: 14px;
								font-weight: 400;
								font-style: normal;
								line-height: 38px;
								z-index: 0 !important;

								&.mage-error {
									margin: 0 !important;
									color: $grey !important;
									font-family: $roboto !important;
									font-size: 14px !important;
									font-weight: 400 !important;
									font-style: normal !important;
									line-height: 40px !important;
								}
							}

							input[type="checkbox"] {
								width: auto;
								height: auto;
								margin: 0rem .625rem 0rem 0rem !important;
							}

							textarea {
								min-height: 150px;
								padding: 1rem;
								line-height: 26px;
							}

							@include placeholder {
								color: $mediumGrey;
							}

							.mage-error {
								margin: 5px 0px 0px 0px !important;
								font-family: $roboto;
								font-size: 14px;
								font-weight: 700;
								font-style: normal;
								line-height: 26px;
							}

							&.gdpr {
								label {
									display: none;
								}

								.checkbox {
									label {
										display: flex;
										align-items: flex-start;
									}

									input {
										margin: calc(.625rem / 2) .625rem 0rem 0rem !important;
									}
								}
							}

							&.submit {
								button {
									width: 200px !important;
								    height: 45px !important;
								    padding: 0rem 2rem 0rem 2rem !important;
								    margin: 1rem 0rem 0rem 0rem !important;
								    position: relative !important;
								    display: inline-block !important;
								    background-color: $yellow !important;
								    border: none !important;
								    border-radius: 5px !important;
								    box-shadow: 3px 3px 12px rgba($black, 0) !important;
								    color: $grey !important;
								    font-family: $roboto !important;
								    font-size: 16px !important;
								    font-weight: 700 !important;
								    font-style: normal !important;
								    line-height: 45px !important;
								    text-align: center !important;
								    text-decoration: none !important;
								    overflow: hidden !important;
								    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83) !important;

								    span {
								    	pointer-events: none;
								    	
								        &.ripple {
								            transform: scale(.25);
								            position: absolute;
								            background-color: rgba($black, .25);
								            border-radius: 100%;
								            opacity: 0;
								            pointer-events: none;
								            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
								        }
								    }

								    &:focus {
								        color: $grey !important;
								    }
								}

								label {
									display: none;
								}
							}

							&:last-of-type {
								margin: 0 !important;
							}
						}
					}
				}

				legend {
					display: none;
				}
			}
		}
	}
}

/**
* _forms - Medium.
*/
@media screen and (min-width: 640px) {
    
}

/**
* _forms - Large.
*/
@media screen and (min-width: 1024px) {
    form {
    	fieldset {
    		.actions-toolbar {
    			button {
    				&:hover {
			            box-shadow: 3px 3px 12px rgba($black, .3) !important;
			            color: $grey !important;
			        }
    			}
    		}
    	}
    }

    a.primary {
    	&:hover {
            box-shadow: 3px 3px 12px rgba($black, .3) !important;
            color: $grey !important;
        }
    }

    .pc_form_widget_view_0 {
		.pccf-customer-data-scope {
			.pccf {
				.form-view {
					form {
						fieldset {
							.form-group {
								&.submit {
									button {
										&:hover {
								            box-shadow: 3px 3px 12px rgba($black, 0) !important;
								            color: $grey !important;
								        }
									}
								}
							}
						}
					}
				}
			}
		}
	}

    .mfp-form {
		.pc_form_widget_view_0 {
			.pccf-customer-data-scope {
				.pccf {
					.form-view {
						form {
							fieldset {
								display: flex;
								flex-direction: column;
								flex-flow: row wrap;
								justify-content: space-between;

								.form-group {
									width: calc(50% - .9375rem / 2);

									&.dropdown, &.textarea, &.gdpr, &.submit, &.product-names {
										width: 100% !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
* _forms - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _forms - XXL.
*/
@media screen and (min-width: 1440px) {
    
}