/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _standard - Small.
*/


/**
* _standard - Medium.
*/
@media screen and (min-width: 640px) {
	
}

/**
* _standard - Large.
*/
@media screen and (min-width: 1024px) {
	
}

/**
* _standard - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _standard - XXL.
*/
@media screen and (min-width: 1440px) {

}