/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _magento-product-websiteni - Small.
*/
.page-layout-magento-product-websiteni, .catalog-product-view {
	.page-wrapper {
		padding-bottom: 50px;

		.page-main {
			.columns {
				width: 100%;

				.column {
					display: flex;
					flex-direction: column;
					flex-flow: row wrap;

					.product.media {
						width: 100%;
						margin: 2rem 0rem 0rem 0rem;
						order: 1;
					}

					.product-info-main {
						width: 100%;
						padding: 2rem 0rem 0rem 0rem;
						order: 2;

						.page-title-wrapper {
							width: 100% !important;
							margin: 0rem 0rem .9375rem 0rem !important;

							.page-title {
								font-size: 24px;
    							line-height: 24px;

    							.base {
    								color: $grey;

    								span {
    									display: inline-block;
    								}
    							}
							}
						}

						.product-info-price {
							padding: 0rem 0rem 2rem 0rem;
							margin: 0rem 0rem 2rem 0rem;
							display: flex;
							flex-direction: column;
							flex-flow: row wrap;
							border-bottom: 1px solid $lightGrey;

							.product-info-stock-sku {
								width: 100%;
								display: flex;
								flex-direction: column;
								flex-flow: row wrap;
								order: 1;

								.sku {
									padding: 0 !important;
									// padding: 0rem 1rem 0rem 0rem !important;
									margin: 0;
									// margin: 0rem 1rem 0rem 0rem;
									display: flex;
									order: 1;
									// border-right: 1px solid $lightGrey;

									.type {
										margin: 0rem .25rem 0rem 0rem;
										color: $grey;
										font-family: $roboto;
										font-size: 14px;
										font-weight: 400;
										font-style: normal;
										line-height: 22px;
									}

									.value {
										margin: 0;
										color: $grey;
										font-family: $roboto;
										font-size: 14px;
										font-weight: 700;
										font-style: normal;
										line-height: 22px;
									}
								}

								.stock:not(.available) {
									// margin: 0rem;
									position: relative;
									display: block;
									// display: flex;
									// order: 2;

									span {
										color: $grey;
										font-family: $roboto;
										font-size: 14px;
										font-weight: 400;
										font-style: normal;
										line-height: 0px;
										text-transform: capitalize;
									}

									// &:before {
									// 	display: block;
									// 	color: $grey !important;
									//     font-family: $roboto !important;
									//     font-size: 14px !important;
									//     font-weight: 400 !important;
									//     font-style: normal;
									//     line-height: 22px;
									//     content: 'Back In Stock Soon';
									// }
								}
							}

							.product-reviews-summary {
								width: 100%;
								margin: .625rem 0rem 0rem 0rem;
								display: none;
								order: 2;

								.reviews-actions {
									display: inline-block;
									position: relative;
									color: $grey;
									font-family: $roboto;
									font-size: 14px;
									font-weight: 400;
									font-style: normal;
									line-height: 22px;

									a {
										margin: 0;
										color: $grey;
										font-family: $roboto;
										font-size: 14px;
										font-weight: 400;
										font-style: normal;
										line-height: 22px;
									}

									&:after {
										bottom: 0;
										right: -4px;
										position: absolute;
										content: '.';
									}
								}
							}
						}

						.prices-tier {
							padding: 1rem;
							margin: 0rem 0rem 2rem 0rem;
							order: 2;
							background-color: $offWhite;
							border-radius: 5px;
							list-style: none;
						}

						.product-add-form {
							.box-tocart {
								width: 100%;
								padding: 1rem;
								margin: 0;
								background-color: $offWhite;
								border-radius: 5px;

								.fieldset {
									margin: 0;
									display: flex;
									flex-direction: column;
									flex-flow: row wrap;
									justify-content: space-between;
									align-items: center;

									.price-box {
										width: 25%;
										margin: 0;
										order: 1;

										span {
											span {
												span {
													margin: 0;
													display: block;
													color: $grey;
													font-size: 20px;
													font-weight: 700;
													font-style: normal;
													line-height: 45px;

													&.price-label {
														font-size: 12px;
														font-weight: 400;
														line-height: 12px;
													}
												}
											}

											&.special-price {
												margin: .625rem 0rem .625rem 0rem;
											}
										}
									}

									.field {
										width: 40%;
										padding: 0;
										margin: 0;
										display: flex;
										align-items: center;
										order: 2;

										.label {
											padding: 0rem .9375rem 0rem 0rem;
											background-color: transparent;
											color: $black;
										}

										.control {
											input {
												width: 100%;
												height: 45px;
												padding: 0rem 1rem 0rem 1rem;
												margin: 0;
												background-color: $white;
												border: none;
												border-radius: 5px;
												box-shadow: none;
												color: $grey;
												font-family: $roboto;
												font-size: 14px;
												font-weight: 400;
												font-style: normal;
												line-height: 45px;
												z-index: 0 !important;
											}
										}
									}

									.actions {
										width: 60%;
										padding: 0rem 0rem 0rem .9375rem;
										order: 3;

										button {
											width: 100%;
										    height: 45px;
										    padding: 0rem 1rem 0rem 1rem;
										    position: relative;
										    display: inline-block;
										    background-color: $yellow;
										    border: none;
										    border-radius: 5px;
										    box-shadow: 3px 3px 12px rgba($black, 0);
										    color: $grey !important;
										    font-family: $roboto;
										    font-size: 16px;
										    font-weight: 700;
										    font-style: normal;
										    line-height: 45px;
										    text-align: center !important;
										    text-decoration: none !important;
										    overflow: hidden;
										    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

										    span {
										        pointer-events: none;

										        &.ripple {
										            transform: scale(.25);
										            position: absolute;
										            background-color: rgba($black, .25);
										            border-radius: 100%;
										            opacity: 0;
										            pointer-events: none;
										            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
										        }
										    }

										    &:focus {
										        color: $grey;
										    }
										}
									}
								}
							}

							.product-options-wrapper {
								.fieldset {
									.field {
										display: flex;
										justify-content: center;
										align-items: center;
										flex-flow: row wrap;
										padding: 0;
										margin: 0rem 0rem 2rem 0rem;

										label {
											width: 100px;
											background-color: transparent;
											color: $black;

											&:after {
												display: none;
											}
										}

										.control {
											width: calc(100% - 100px);

											select {
												width: 100%;
												height: 50px;
												padding: 0rem 1rem 0rem 1rem;
												margin: 0;
												background-color: $offWhite;
												border: none;
												border-radius: 5px;
												box-shadow: none;
												color: $grey;
												font-family: $roboto;
												font-size: 14px;
												font-weight: 400;
												font-style: normal;
												line-height: 40px;
												z-index: 0 !important;
											}
										}
									}
								}
							}

							.breaker {
								margin: 2rem 0rem 0rem 0rem;
								border-bottom: 1px solid $lightGrey;
							}

							.social-share {
								padding: 0rem 0rem 2rem 0rem;
								margin: 2rem 0rem 0rem 0rem;
								border-bottom: 1px solid $lightGrey;
							}
						}

						.product.attribute.overview {
							padding: 0 !important;
							margin: 2rem 0rem 0rem 0rem !important;

							.value {
								p {
									a {
										width: auto;
									    height: 45px;
									    padding: 0rem 2rem 0rem 2rem;
									    position: relative;
									    display: inline-block;
									    background-color: $black;
									    border: none;
									    border-radius: 5px;
									    box-shadow: 3px 3px 12px rgba($black, 0);
									    color: $white !important;
									    font-family: $roboto;
									    font-size: 16px;
									    font-weight: 700;
									    font-style: normal;
									    line-height: 45px;
									    text-align: center !important;
									    text-decoration: none !important;
									    overflow: hidden;
									    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

									    span {
									        pointer-events: none;

									        &.ripple {
									            transform: scale(.25);
									            position: absolute;
									            background-color: rgba($white, .25);
									            border-radius: 100%;
									            opacity: 0;
									            pointer-events: none;
									            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
									        }
									    }

									    &:focus {
									        color: $white;
									    }
									}

									&:last-of-type {
										display: flex;
									}
								}
							}
						}

						.product-social-links {
							order: 9;

							.product-addto-links {
								margin: 2rem 0rem 0rem 0rem;

								a {
									&.towishlist {
										width: 200px;
										height: 45px;
										padding: 0rem 1rem 0rem 1rem;
										margin: 0;
										display: inline-block;
										background-color: $yellow;
										border: none;
										border-radius: 5px;
										box-shadow: 3px 3px 12px rgba($black, 0);
										text-align: center;
										overflow: hidden;
										transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

										span {
											// font-size: 0;
											// line-height: 0;

											// &:before {
												color: $grey;
												font-family: $roboto;
												font-size: 16px;
												font-weight: 700;
												font-style: normal;
												line-height: 45px;
												// content: 'Add to Favourites';
											// }
										}

										&:before, &:after {
											display: none;
										}
									}

									&.tocompare {
										display: none;
									}
								}
							}
						}

						.product_downloads {
							padding: 2rem 0rem 0rem 0rem;
							margin: 2rem 0rem 0rem 0rem;
							display: flex;
							justify-content: space-between;
							flex-flow: row wrap;
							order: 5;
							border-top: none;
							// border-top: 1px solid $lightGrey;

							a {
								// width: calc(50% - 2.5px) !important;
								margin-bottom: 5px;
								background-color: $black !important;
								color: $white !important;

								span {
									background-color: rgba($white, .2) !important;
								}
							}
						}
					}

					.product.info.detailed {
						width: 100%;
						margin: 2rem 0rem 0rem 0rem;
						order: 4;

						.product.data.items {
							margin: 0;
							display: flex;
							flex-flow: row wrap;
							// justify-content: space-between;
							border: none;

							.data.item.title {
								margin: 0;
								display: none;

								a {
									height: auto;
									padding: 0rem 1.875rem 0rem 0rem;
									margin: 0;
									background-color: transparent !important;
									border: none;
									color: $grey;
									font-family: $roboto;
									font-size: 14px;
									font-weight: 400;
									font-style: normal;
									line-height: 22px;
								}

								&#tab-label-additional {
									display: none;
								}

								&.active {
									a {
										font-weight: 700;
									}
								}
							}

							.data.item.content {
								width: 100%;
								padding: 0 !important;
								margin: 0rem 0rem 2rem 0rem !important;
								background-color: transparent;
								border: none;

								.product.attribute.description, .product_features, .additional-attributes-wrapper.table-wrapper {
									position: relative;
									background-color: $offWhite;
									border: none;
									color: $black;
									font-family: $roboto;
									font-size: 16px;
									font-weight: 400;
									font-style: normal;
									line-height: 1.75;

									div, table {
										padding: 1rem;

										br {
											&:first-child {
												display: none;
											}
										}
									}

									p {
										margin: 0 !important;
										font-weight: 400 !important;
									}

									ul {
										margin: 0;
										list-style: none;

										li {
											padding: 0;
											display: flex;
											flex-flow: row wrap;

											.label {
												width: 50%;
												min-width: 50%;
												padding: .9375rem 1.875rem .9375rem 1.875rem;
												background-color: $extraGrey;
												border-bottom: 1px solid $white;
												border-right: 1px solid $white;
												color: $white;
												font-family: $roboto;
												font-size: 14px;
												font-weight: 400;
												font-style: normal;
												line-height: 22px;
											}

											.data {
												width: 50%;
												min-width: 50%;
												padding: .9375rem 1.875rem .9375rem 1.875rem;
												background-color: $offWhite;
												border-bottom: 1px solid $white;
												color: $grey;
												font-family: $roboto;
												font-size: 14px;
												font-weight: 400;
												font-style: normal;
												line-height: 22px;
											}
										}
									}

									table {
										width: 100%;
										margin: 0rem !important;
										background-color: transparent;
										border: none;

										tbody {
											background-color: transparent;
											border: none;

											tr {
												display: flex;
												background-color: transparent;
												border: none;

												th {
													width: 50%;
													min-width: 50%;
													padding: .9375rem 1.875rem .9375rem 1.875rem;
													background-color: $extraGrey;
													border-bottom: 1px solid $white;
													border-right: 1px solid $white;
													color: $white;
													font-family: $roboto;
													font-size: 14px;
													font-weight: 400;
													font-style: normal;
													line-height: 22px;
												}

												td {
													width: 50%;
													min-width: 50%;
													padding: .9375rem 1.875rem .9375rem 1.875rem;
													background-color: $offWhite;
													border-bottom: 1px solid $white;
													color: $grey;
													font-family: $roboto;
													font-size: 14px;
													font-weight: 400;
													font-style: normal;
													line-height: 22px;
												}
											}
										}
									}

									&:before {
										padding: 0rem 0rem 1rem 0rem;
										position: relative;
										display: block;
										background-color: $white;
										color: $black;
										font-family: $roboto;
										font-size: 24px;
										font-weight: 700;
										font-style: normal;
										line-height: 24px;
										content: 'Product Overview';
									}
								}

								.product_features, .additional-attributes-wrapper.table-wrapper {
									margin: 0 !important;

									&:before {
										content: 'Specifications';
									}

									h4 {
										display: none;
									}
								}

								.product_downloads {
									margin: 1.875rem 0rem 0rem 0rem;
									display: flex;
									justify-content: space-between;

									a {
										// width: calc(50% - .9375rem / 2) !important;
										margin: 0;
									}
								}

								&:last-of-type {
									margin: 0 !important;
								}

								/* div {
									border: none;

									// .content {
										padding: 1rem;
										background-color: $offWhite;
										color: $black;
										font-family: $roboto;
										font-size: 14px;
										font-weight: 400;
										font-style: normal;
										line-height: 22px;

										ul {
											margin: 0rem 0rem 2rem 1.25rem;

											li {
												padding: 0;
											}
										}

										p {
											margin: 0rem 0rem 0rem 1.25rem;
										}

										br {
											&:first-child {
												display: none;
											}
										}
									// }

									.dark {
										padding: .625rem .9375rem .625rem .9375rem;
										background-color: $extraGrey;
										border-bottom: 1px solid $white;
										border-right: 1px solid $white;
										color: $white;
										font-family: $roboto;
										font-size: 14px;
										font-weight: 400;
										font-style: normal;
										line-height: 22px;
									}

									.light {
										padding: .625rem .9375rem .625rem .9375rem;
										background-color: $offWhite;
										border-bottom: 1px solid $white;
										color: $grey;
										font-family: $roboto;
										font-size: 14px;
										font-weight: 400;
										font-style: normal;
										line-height: 22px;
									}

									.cell {
										&:last-of-type {
											h4 {
												margin: 2rem 0rem 1.5rem 0rem !important;
											}
										}
									}

									div {
										width: 100%;

										&:before {
											display: none;
										}
									}

									&:before {
										padding: 0rem 0rem 1rem 0rem;
										position: relative;
										display: block;
										background-color: $white;
										color: $black;
										font-family: $roboto;
										font-size: 24px;
										font-weight: 700;
										font-style: normal;
										line-height: 24px;
										content: 'Product Overview';
									}
								} */

								&#additional {
									display: block !important;
								}
							}
						}
					}

					.block.related {
						width: 100%;
						margin: 2rem 0rem 0rem 0rem;
						order: 3;

						.block-title {
							position: relative;
							margin: 0rem 0rem 2rem 0rem !important;
							color: $grey;
						    font-family: $roboto;
						    font-size: 24px;
						    font-weight: 700;
						    font-style: normal;
						    line-height: 24px;
							text-indent: -99999px;

							strong {
								color: $grey;
							}

							&:after {
								top: 0;
								left: 0;
								position: absolute;
								color: $grey;
								text-indent: 0;
								content: 'You may also like...';
							}
						}

						.block-content {
							li.product {
								margin-bottom: 0 !important;

								.product-item-info {
									.product-item-photo {
										padding-top: 1.875rem !important;

										.product-image-container {
											width: 100% !important;
										}
									}

									.product-item-details {
										margin: 0;

										.product-item-name {
											margin: 1.875rem 0rem .75rem 0rem;
										}

										.price-box {
											margin: 0rem 0rem 1.875rem 0rem;
										}

										.field.choice.related {
											display: block !important;

											label {
												width: 75%;
												max-width: 150px;
											    height: 45px;
											    padding: 0rem 2rem 0rem 2rem;
											    margin: 0rem auto 2rem auto;
											    position: relative;
											    display: inline-block;
											    background-color: $yellow;
											    border: none;
											    border-radius: 5px;
											    box-shadow: 3px 3px 12px rgba(254, 254, 254, 0);
											    color: $grey !important;
											    font-family: $roboto;
											    font-size: 16px;
											    font-weight: 700;
											    font-style: normal;
											    line-height: 45px;
											    text-align: center !important;
											    text-decoration: none !important;
											    overflow: hidden;
											}

											input {
												display: none;
											}
										}

										.field.choice.related, .product-item-actions {
											display: none;
										}
									}
								}

								&:nth-last-of-type(1),
								&:nth-last-of-type(2) {
									margin-bottom: 0 !important;
								}
							}

							.block-actions {
								display: none;
							}
						}
					}
				}
			}
		}
	}

	.messages {
		div {
			.messages {
				.message {
					div {
						a {
							&:before {
						        background-color: rgba($yellow, .2);
						    }

						    &:after {
						        background-color: $yellow;
						    }
						}
					}
				}
			}
		}
	}
}

.gallery-placeholder {
	position: relative;
	background-color: $white;
	border: none;

	.fotorama {
		.fotorama__wrap {
			.fotorama__stage {
				width: calc(100% - 2px - .9375rem) !important;
				margin: 0rem 0rem 0rem .9375rem;
				border: 1px solid $lightGrey;

				.fotorama__arr {
					opacity: 1 !important;

					&.fotorama__arr--prev {
						transform: translate3d(0, 0, 0) !important;
					}

					&.fotorama__arr--next {
						transform: translate3d(0, 0, 0) !important;
					}
				}

				.fotorama__stage__shaft {
					width: 100% !important;
				}
			}

			.fotorama__nav-wrap {
				margin: 1rem .9375rem 0rem;
				border-top: none;

				.fotorama__nav {
					width: calc(90px + .9375rem);
					min-width: calc(90px + .9375rem);

					.fotorama__nav__shaft {
						.fotorama__thumb-border {
							padding: 0;
							margin: 0;
							border: 2px solid $yellow;
							display: none;
						}

						.fotorama__nav__frame {
							padding: 0;
							margin: 0rem 1rem .9375rem 0rem;
							cursor: pointer;

							.fotorama__thumb {
								background-color: transparent;
								border: 1px solid $lightGrey;
							}

							&.fotorama__nav__frame--dot {
								.fotorama__dot {
									width: 8px;
									height: 8px;
									top: 10px;
									background-color: transparent !important;
									border: 1px solid $yellow !important;
									border-radius: 100%;
								}

								&.fotorama__active {
									.fotorama__dot {
										background-color: $yellow !important;
										border: 1px solid $yellow !important;
									}
								}
							}

							// &:last-of-type {
							// 	margin: 0;
							// }
						}
					}

					.fotorama__thumb__arr {
						.fotorama__thumb--icon {
							background-position: -25px -345px;
						}
					}
				}
			}
		}
	}
}

.fotorama--fullscreen {
	z-index: 99999 !important;
}


.page-layout-magento-product-websiteni, .catalog-product-view {
	.page-wrapper {
		.page-main {
			.columns {
				.column {
					.product-info-main {
						.product-add-form {
							.fieldset .field {
								.control {									
									.add-to-cart-quantity-nav{
										display: none;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
* _magento-product-websiteni - Mobile Only.
*/
@media screen and (max-width: 639px) {
	.gallery-placeholder {
		.fotorama {
			.fotorama__wrap {
				.fotorama__stage {
					width: 100% !important;
					margin: 0 !important;
				}

				.fotorama__nav-wrap {
					margin: 0 !important;

					.fotorama__nav {
						width: 100% !important;
						min-width: 100% !important;
						margin: 0 !important;
						border-left: 1px solid $lightGrey;
						border-bottom: 1px solid $lightGrey;
						border-right: 1px solid $lightGrey;

						.fotorama__nav__shaft {
							.fotorama__nav__frame {
								margin: 0;
							}
						}
					}
				}
			}
		}
	}
}

a {
	&#gallery-prev-area, &#gallery-next-area {
		display: none;
	}
}

.fotorama {
	.fotorama__wrap {
		.fotorama__stage {
			.fotorama__fullscreen-icon {
				background-color: $lightGrey !important;
				border: none !important;
				outline: none !important;
				box-shadow: none !important;

				&:after {
					display: none !important;
				}

				&:hover, &:focus {
					background-color: $lightGrey !important;
					border: none !important;
					outline: none !important;
					box-shadow: none !important;
				}
			}
		}

		.fotorama__nav-wrap {
			border-top: 1px solid $lightGrey;

			.fotorama__nav {
				.fotorama__nav__shaft {
					.fotorama__thumb-border {
						padding: 0;
						margin: 0;
						border: 2px solid $yellow;
					}

					.fotorama__nav__frame {
						padding: 0;
						margin: 0px 2px 0px 0px;
						cursor: pointer;

						&.fotorama__nav__frame--dot {
							.fotorama__dot {
								width: 8px;
								height: 8px;
								top: 10px;
								background-color: transparent !important;
								border: 1px solid $yellow !important;
								border-radius: 100%;
							}

							&.fotorama__active {
								.fotorama__dot {
									background-color: $yellow !important;
									border: 1px solid $yellow !important;
								}
							}
						}
					}
				}

				.fotorama__thumb__arr {
					.fotorama__thumb--icon {
						background-position: -25px -345px;
					}
				}
			}
		}
	}
}

.page-layout-magento-product-websiteni, .catalog-product-view {
	.page-wrapper {
		.page-main {
			.columns {
				.column {
					.product-info-main {
						#actions {
							&.actions {
								padding: 0;
								// padding: 2rem 0rem 0rem 0rem;
								margin: 0rem 0rem 1rem 0rem;
								// margin: 2rem 0rem 0rem 0rem;
								order: 3;
								// order: 10;
								// border-top: 1px solid $lightGrey;

								button {
									&.rfqbutton {
										width: 200px;
									    height: 45px;
									    padding: 0rem 2rem 0rem 2rem;
									    position: relative;
									    display: inline-block;
									    background-color: $yellow;
									    border: none;
									    border-radius: 5px;
									    box-shadow: 3px 3px 12px rgba($black, 0);
									    color: $grey !important;
									    font-family: $roboto;
									    font-size: 16px;
									    font-weight: 700;
									    font-style: normal;
									    line-height: 45px;
									    text-align: center;
									    text-decoration: none !important;
									    overflow: hidden;
									    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

									    span {
									        pointer-events: none;

									        &.ripple {
									            transform: scale(.25);
									            position: absolute;
									            background-color: rgba($black, .25);
									            border-radius: 100%;
									            opacity: 0;
									            pointer-events: none;
									            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
									        }
									    }

									    &:focus {
									        color: $grey;
									    }
									}
								}
							}
						}

						#header-mpdal {
							&.modal {
								padding: 0;
								position: fixed;
								background-color: rgba($black, .5);

								.modal-content {
									width: calc(100% - 1rem - 1rem);
									max-width: 768px;
									height: calc(100% - 1rem - 1rem);
									max-height: 512px;
									padding: 1rem;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									position: absolute;
									background-color: $white;
									border: none;
									overflow-x: hidden;
									overflow-y: scroll;

									form {
										fieldset {
											legend {
												width: 100%;
												padding: 0rem 0rem 1rem 0rem;
												margin: 0rem 0rem 2rem 0rem !important;
												border-bottom: 1px solid rgba($black, .1);

												span {
													span {
														color: $yellow;
													}

													&#crossrequestmodal {
														width: 44px;
														height: 44px;
														top: 1rem;
													    right: 1rem;
													    position: absolute;
													    background-color: transparent;
													    border: 1px solid #1D1D1B !important;
													    border-radius: 100%;
													    color: #1D1D1B;
													    font-size: 30px !important;
													    line-height: 42px !important;
													    text-transform: lowercase;
													    text-align: center;
													    opacity: 1;
													    cursor: pointer;
													}
												}
											}

											.field {
												label {
													color: $grey;
													font-family: $roboto;
													font-size: 14px;
													font-weight: 400;
													font-style: normal;
													line-height: 14px;

													span {
														font-weight: 400;
														line-height: 14px;
													}
												}

												.control {
													display: flex;
													flex-direction: column;

													label {
														color: $grey;
														font-family: $roboto;
														font-size: 14px;
														font-weight: 400;
														font-style: normal;
														line-height: 14px;

														span {
															font-weight: 400;
															line-height: 14px;
														}
													}

													input:not(#prod_button) {
														width: 100% !important;
														margin: 0 !important;
														font-weight: 400 !important;
													}
												}

												&.name {
													.control {
														textarea {
															width: 100% !important;
															height: 40px !important;
															min-height: 40px !important;
															padding: 0rem 1rem 0rem 1rem !important;
															margin: 0rem 0rem 1rem 0rem !important;
															line-height: 40px !important;
														}
													}
												}

												&:nth-of-type(2) {
													margin-top: 2rem !important;
													/* display: flex !important;
													justify-content: space-between !important;
													flex-direction: row !important;
													flex-flow: row wrap !important;

													label {
														width: calc(50% - 1rem / 2) !important;
													}

													.control {
														display: flex !important;
														justify-content: space-between !important;
    													flex-direction: row !important;

    													input {
    														width: calc(50% - 1rem / 2) !important;
    													}
													}

													&:before {
														display: none;
													} */
												}

												&:last-of-type {
													.control {
														flex-flow: row wrap;
														cursor: pointer;

														input:not(#prod_button) {
															width: auto !important;
															height: auto;
															cursor: pointer;
														}

														label {
															width: auto !important;
															padding: 0rem 0rem 0rem .25rem !important;
															margin: 0 !important;
															cursor: pointer;
														}
													}
												}

												&:nth-of-type(4), &:nth-of-type(5), &:nth-of-type(6), , &:nth-of-type(7), , &:nth-of-type(8) {
													display: none;
												}
											}
										}
									}
								}
							}
						}

						#product-mpdal {
							&.modal {
								padding: 0;
								position: fixed;
								background-color: rgba($black, .5);

								.modal-content {
									width: calc(100% - 1rem - 1rem);
									max-width: 1024px;
									height: calc(100% - 1rem - 1rem);
									max-height: 768px;
									padding: 1rem;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
									position: absolute;
									background-color: $white;
									border: none;
									overflow-x: hidden;
									overflow-y: scroll;

									#crossproductmodal {
										width: 44px;
										height: 44px;
										top: 1rem;
									    right: 1rem;
									    position: absolute;
									    background-color: transparent;
									    border: 1px solid #1D1D1B !important;
									    border-radius: 100%;
									    color: #1D1D1B;
									    font-size: 30px !important;
									    line-height: 42px !important;
									    text-transform: lowercase;
									    text-align: center;
									    opacity: 1;
									    cursor: pointer;
									}

									table {
										margin: 2rem 0rem 2rem 0rem;
										border: 1px solid $lightGrey;

										tbody {
											background-color: transparent;
											border: 1px solid $lightGrey;

											tr {
												th, td {
													padding: .9375rem;
													border: 1px solid $lightGrey;
												}

												th {
													color: $grey;
													font-family: $roboto;
													font-size: 18px;
													font-weight: 700;
													font-style: normal;
													line-height: 18px;
												}

												td {
													label {
														padding: 0 !important;
														margin: 0;
														background-color: transparent;
														color: $grey;
														font-family: $roboto;
														font-size: 14px;
														font-weight: 400;
														font-style: normal;
														line-height: 14px;
													}

													input {
														width: 100% !important;
														height: 40px;
														margin: 0;
														background-color: $white;
														border: 1px solid $mediumGrey;
														box-shadow: none;
													}

													&:first-of-type {
														label {
															margin: 0rem 0rem 0rem .25rem;
														}

														input {
															width: 14px !important;
															height: 14px;
															margin: 0;
															top: 2px;
															position: relative;
														}
													}
												}

												&:nth-child(even) {
													background-color: $offWhite;
												}
											}
										}
									}

									#add_prod {
										top: 0 !important;
										left: 0 !important;
										text-align: center !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
* _magento-product-websiteni - Medium.
*/
@media screen and (min-width: 640px) {
	.page-layout-magento-product-websiteni, .catalog-product-view {
    	.page-wrapper {
    		.page-main {
    			.columns {
    				.column {
    					.product-info-main {
    						display: flex;
    						flex-direction: column;

    						.page-title-wrapper {
    							order: 1;
    						}

    						.product-info-price {
    							order: 2;

    						}

    						.product.attribute.overview {
    							order: 3;

    						}

    						.product-add-form {
    							order: 4;
    						}
    					}
    				}
    			}
    		}
    	}
    }

    .page-layout-magento-product-websiteni, .catalog-product-view {
		.page-wrapper {
			padding-bottom: 62.5px;

			.page-main {
				.columns {
					.column {
						.product.media {
							width: 60%;
							margin: 0;
						}

						.product-info-main {
							width: 40%;
							padding: 0px 0px 0px 32px;

							.page-title-wrapper {
								.page-title {
									font-size: 27px;
	    							line-height: 27px;
								}
							}

							.product-info-price {
								.price-box {
									margin: .9375rem 0rem 2rem 0rem;
								}
							}

							.product-add-form {
								.box-tocart {
									padding: 2rem;
								}
								.fieldset .field {
									.control {
										position: relative;
									
										// .add-to-cart-quantity-nav{
										// 	width: 100%;
										// 	position: absolute;
										//     top: 0;
										//     left: 0;
										//     height: 100%;
										//     display: flex;
										//     flex-direction: column;
										//     justify-content: center;

										//     .quantity-up,
										//     .quantity-down{
										//     	background-repeat: no-repeat;
										//     	background-size: contain;
										//     	position: absolute;
										//     	width: 15px;
										//     	height: 15px;
										//     	display: block;
										//     	cursor: pointer;
										//     	text-indent: -999px;
										//     	overflow: hidden;
										//     	top: 15px;
										//     }
										//     .quantity-up{
										//     	background-image: url(../images/plus.svg);
										//     	right: 8px;
										//     }
										//     .quantity-down{
										//     	background-image: url(../images/minus.svg);
										//     	left: 8px;
										//     }
										// }
									}
								}
								.product-options-wrapper {
									.fieldset {
										.field {
											// padding: 0rem 2rem 0rem 2rem;
										}
									}
								}
							}
						}

						.product.info.detailed {
							margin: 50px 0px 0px 0px;

							.product.data.items {
								.data.item.content {
									width: calc(50% - .9375rem / 2);
									margin: 0 !important;

									.product.attribute.description, .product_features, .additional-attributes-wrapper.table-wrapper {
										div, table {
											padding: 1.5rem;
										}

										// table {
										// 	margin: 2rem !important;
										// }
									}

									/* div {
										// width: 50%;

										.cell {
											&:last-of-type {
												h4 {
													margin: 0rem 0rem 1.5rem 0rem !important;
												}
											}
										}
									} */

									&#additional {
										margin: 0rem 0rem 0rem .9375rem !important;
									}
								}
							}
						}

						.block.related {
							margin: 50px 0px 0px 0px;

							.block-content {
								li.product {
									&:last-of-type {
										display: none !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.gallery-placeholder {
		margin: 0px 0px -2px 0px;
	}

	.page-layout-magento-product-websiteni, .catalog-product-view {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						.product-info-main {
							#header-mpdal, #product-mpdal {
								&.modal {
									.modal-content {
										padding: 2rem;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
* _magento-product-websiteni - Large.
*/
@media screen and (min-width: 1024px) {
    .page-layout-magento-product-websiteni, .catalog-product-view {
		.page-wrapper {
			padding-bottom: 75px;

			.page-main {
				.columns {
					.column {
						.product-info-main {
							padding: 0px 0px 0px 55px;

							.page-title-wrapper {
								.page-title {
									font-size: 30px;
	    							line-height: 30px;
								}
							}
						}

						.product.info.detailed {
							margin: 75px 0px 0px 0px;

							.product.data.items {
								.data.item.content {
									.product.attribute.description, .product_features, .additional-attributes-wrapper.table-wrapper {
										div, table {
											padding: 2rem;
										}

										// table {
										// 	margin: 3rem !important;
										// }
									}

									/* div {
										// .content {
											padding: 2rem;
										// }
									} */
								}
							}
						}

						.block.related {
							margin: 75px 0px 0px 0px;

							.block-content {
								li.product {
									&:last-of-type {
										display: block !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.page-layout-magento-product-websiteni, .catalog-product-view {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						.product-info-main {
							#actions {
								&.actions {
									button {
										&.rfqbutton {
											&:hover {
									            box-shadow: 3px 3px 12px rgba($black, .3) !important;
									            color: $grey !important;
									        }
									    }
									}
								}
							}

							#header-mpdal, #product-mpdal {
								&.modal {
									.modal-content {
										padding: 3rem;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.page-layout-magento-product-websiteni, .catalog-product-view {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						.product-info-main {
							.product-add-form {
								.fieldset .field {
									.control {
										position: relative;
									
										.add-to-cart-quantity-nav{
											width: 100%;
											position: absolute;
										    top: 0;
										    left: 0;
										    height: 100%;
										    display: flex;
										    flex-direction: column;
										    justify-content: center;

										    .quantity-up,
										    .quantity-down{
										    	background-repeat: no-repeat;
										    	background-size: contain;
										    	position: absolute;
										    	width: 15px;
										    	height: 15px;
										    	display: block;
										    	cursor: pointer;
										    	text-indent: -999px;
										    	overflow: hidden;
										    	top: 15px;
										    }
										    .quantity-up{
										    	background-image: url(../images/plus.svg);
										    	right: 8px;
										    }
										    .quantity-down{
										    	background-image: url(../images/minus.svg);
										    	left: 8px;
										    }
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

}

/**
* _magento-product-websiteni - XL.
*/
@media screen and (min-width: 1200px) {
	.page-layout-magento-product-websiteni, .catalog-product-view {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						.product.info.detailed {
							.product.data.items {
								.data.item.content {
    								.product_downloads {
										a {
											width: calc(50% - .9375rem / 2) !important;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
* _magento-product-websiteni - XXL.
*/
@media screen and (min-width: 1440px) {
    .page-layout-magento-product-websiteni, .catalog-product-view {
    	.page-wrapper {
    		.page-main {
    			.columns {
    				.column {
    					.product-info-main {
    						// display: flex;
    						// flex-direction: column;

    						.page-title-wrapper {
    							// order: 1;
    						}

    						.product-info-price {
    							// position: relative;
    							// order: 2;

    							/*.price-box {
    								top: 136px;
    								left: 31px;
    								position: absolute;
    							}*/
    						}

    						.product.attribute.overview {
    							padding: 0rem 0rem 2rem 0rem !important;
    							// padding: 0rem 0rem 3rem 0rem !important;
								margin: 0 !important;
								// margin: 0px 0px -7px 0px !important;
    							// order: 3;

    							.value {
    								color: $grey;
    								font-family: $roboto;
									font-size: 16px;
									font-weight: 400;
									font-style: normal;
									line-height: 1.75;
    							}
    						}

    						.product-add-form {
    							// order: 4;

    							.box-tocart {
    								.fieldset {
    									// width: 65%;
    									// float: right;

    									.price-box {
    										span {
    											span {
    												span {
    													font-size: 30px;
    												}
    											}
    										}
    									}
    								}
    							}
    						}
    					}
    				}
    			}
    		}
    	}
    }
}