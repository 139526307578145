// Mixins
@import "mixins/media-queries";

// Vendor Libraries
@import 'vendor/normalize/lib';
@import 'vendor/magento-ui/lib';

// Theme Variables
@import 'variables';

// Reset Default Styles with "magento-reset"
@import 'blocks/reset';

// Theme Blocks
@import 'blocks/actions-toolbar';
@import 'blocks/breadcrumbs';
@import 'blocks/buttons';
@import 'blocks/extends';
@import 'blocks/forms';
@import 'blocks/icons';
@import 'blocks/layout';
@import 'blocks/loaders';
@import 'blocks/messages';
@import 'blocks/navigation';
@import 'blocks/pages';
@import 'blocks/popups';
@import 'blocks/price';
@import 'blocks/sections';
@import 'blocks/tables';
@import 'blocks/tooltips';
@import 'blocks/typography';

// Components Styles (Modal/Sliding Panel)
@import 'blocks/components/modals';
@import 'blocks/components/modals_extend';

// Modules
@import '../Magento_AdvancedCheckout/styles/module';
@import '../Magento_Braintree/styles/module';
@import '../Magento_Bundle/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../Magento_CatalogEvent/styles/module';
@import '../Magento_CatalogSearch/styles/module';
@import '../Magento_Checkout/styles/module';
@import '../Magento_Customer/styles/module';
@import '../Magento_Downloadable/styles/module';
@import '../Magento_GiftCard/styles/module';
@import '../Magento_GiftCardAccount/styles/module';
@import '../Magento_GiftMessage/styles/module';
@import '../Magento_GiftRegistry/styles/module';
@import '../Magento_GiftWrapping/styles/module';
@import '../Magento_GroupedProduct/styles/module';
@import '../Magento_Invitation/styles/module';
@import '../Magento_LayeredNavigation/styles/module';
@import '../Magento_Msrp/styles/module';
@import '../Magento_MultipleWishlist/styles/module';
@import '../Magento_Multishipping/styles/module';
@import '../Magento_Newsletter/styles/module';
@import '../Magento_Paypal/styles/module';
@import '../Magento_ProductVideo/styles/module';
@import '../Magento_Review/styles/module';
@import '../Magento_Reward/styles/module';
@import '../Magento_Rma/styles/module';
@import '../Magento_Sales/styles/module';
@import '../Magento_SalesRule/styles/module';
@import '../Magento_SendFriend/styles/module';
@import '../Magento_Swatches/styles/swatches';
@import '../Magento_Theme/styles/module';
@import '../Magento_Vault/styles/module';
@import '../Magento_Weee/styles/module';
@import '../Magento_Wishlist/styles/module';

// Widgets
@import '../Magento_AdvancedCheckout/styles/widgets';
@import '../Magento_Banner/styles/widgets';
@import '../Magento_Catalog/styles/widgets';
@import '../Magento_CatalogEvent/styles/widgets';
@import '../Magento_Cms/styles/widgets';
@import '../Magento_MultipleWishlist/styles/widgets';
@import '../Magento_VersionsCms/styles/widgets';

// Vendor Libraries
@import url("https://cdnjs.cloudflare.com/ajax/libs/foundation/6.6.2/css/foundation.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/hamburgers/1.1.3/hamburgers.min.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/bxslider/4.2.15/jquery.bxslider.css");
@import url("https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/magnific-popup.min.css");
@import 'vendor/animate/animate';

// Slick Slider
@import 'vendor/slick/slick';
@import 'vendor/slick/slick-theme';

// Custom
@import "helper/variables";
@import "helper/colours";
@import "helper/fonts";
@import "helper/cookie-policy";

@import "helper/mixins";

@import "helper/classes";
@import "helper/effects";
@import "helper/forms";
@import "helper/responsive-table";
@import "helper/search";

@import "layout/header";
@import "layout/footer";

@import "partial/homepage";
@import "partial/all-departments";
@import "partial/standard";
@import "partial/warranty-and-repairs";
@import "partial/corporate-sponsorship";
@import "partial/competitions";
@import "partial/events";
@import "partial/news";
@import "partial/become-a-stockist";
@import "partial/contact";
@import "partial/error-404";

@import "magento/magento-websiteni";
@import "magento/magento-category-websiteni";
@import "magento/magento-product-websiteni";
@import "magento/magento-account-websiteni";
@import "magento/magento-cart-websiteni";
@import "magento/magento-checkout-websiteni";

@import 'extends';
