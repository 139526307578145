/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _magento-checkout-websiteni - Small.
*/
.page-layout-checkout {
	&.checkout-index-index {
		overflow-x: hidden;

		.page-wrapper {
			padding-top: 50px;

			.page-main {
				min-height: calc(100vh - 75px - 100px);
				padding-left: 0 !important;
				padding-right: 0 !important;

				.columns {
					.column {
						padding-left: 0 !important;
						padding-right: 0 !important;

						.checkout-container {
							margin: 0;

							.opc-progress-bar {
								max-width: none;
								padding: 25px;
								margin: 0px 0px 50px 0px;
								background-color: $white;
								border-top: 3px solid $yellow;
								box-shadow: 3px 3px 12px rgba($black, .1);
								list-style: none;

								li {
									padding: 0;

									span {
										color: $grey;
										font-family: $roboto;
										font-size: 20px;
										font-weight: 700;
										font-style: normal;
										line-height: 20px;

										&:before {
											background-color: $mediumGrey;
										}

										&:after {
											display: flex;
											justify-content: center;
											align-items: center;
											color: $grey;
											font-family: $roboto;
											font-size: 16px;
											font-weight: 700;
											font-style: normal;
											line-height: 16px;
										}
									}

									&:before {
										height: 6px;
										background-color: $mediumGrey;
										border-radius: 0;
									}

									&._active, &._complete {
										span {
											&:before {
												background-color: $yellow;
											}

											&:after {
												font-family: "icons-blank-theme";
												font-size: 30px;
												line-height: 30px;
											}
										}

										&:before {
											background-color: $yellow;
										}
									}
								}
							}

							.opc-wrapper {
								width: 100%;
								padding: 0;
								margin: 0;

								.opc {
									max-width: none;

									.checkout-shipping-address, .checkout-shipping-method, .checkout-payment-method {
										max-width: none;
										padding: 0;

										.step-title {
											padding: 0px 0px 20px 0px;
											margin: 0px 0px 20px 0px;
											border-bottom: 1px solid $lightGrey;
											color: $grey;
											font-family: $roboto;
											font-size: 20px;
											font-weight: 700;
											font-style: normal;
											line-height: 20px;
										}

										.step-content {
											margin: 0;

											form {
												max-width: none;
												margin: 0;

												fieldset, .fieldset {
													.field {
														margin: 0rem 0rem 1rem 0rem;

														label, legend.label {
															width: 100%;
														    padding: 0;
														    margin: 0px 0px 5px 0px;
														    background-color: transparent;
														    text-align: left;
														    float: none;

														    span {
														    	color: $grey;
															    font-family: $roboto;
															    font-size: 14px;
															    font-weight: 700;
															    font-style: normal;
															    line-height: 22px;
														    }

														    &:after {
														    	display: none;
														    }
														}

														.control {
															width: 100%;

															input, select, textarea {
																width: 100%;
																height: 40px;
																padding: 0rem 1rem 0rem 1rem;
																margin: 0;
																background-color: $white;
																border: 1px solid $lightGrey;
																border-radius: 5px;
																box-shadow: none;
																color: $grey;
																font-family: $roboto;
																font-size: 14px;
																font-weight: 400;
																font-style: normal;
																line-height: 40px;
																z-index: 0 !important;

																&.mage-error {
																	color: $grey !important;
																	font-family: $roboto !important;
																	font-size: 14px !important;
																	font-weight: 400 !important;
																	font-style: normal !important;
																	line-height: 40px !important;
																}
															}

															textarea {
																min-height: 150px;
																padding: 1rem;
																line-height: 26px;
															}

															.mage-error, .field-error {
																margin: 5px 0px 0px 0px !important;
																font-family: $roboto;
																font-size: 12px;
																font-weight: 700;
																font-style: normal;
																line-height: 20px;

																span {
																	font-family: $roboto;
																	font-size: 12px;
																	font-weight: 700;
																	font-style: normal;
																	line-height: 20px;
																}
															}

															span {
																&.note {
																	margin: .625rem 0rem 0rem 0rem;
																}
															}

															.field-tooltip {
																display: none;
															}
														}

														&[name="shippingAddress.country_id"] {
															margin-top: 1rem !important;
														}
													}

													&.field {
														margin: 0 !important;
													}

													&#customer-email-fieldset {
														.fieldset {
															margin: 0px 0px 50px 0px !important;

															.fieldset {
																.actions-toolbar {
																	margin: 0 !important;

																	.primary {
																		button {
																			&:hover {
																				background-color: $yellow;
																				border: none;
																				color: $white;
																			}
																		}
																	}
																}
															}
														}
													}
												}

												&.form-login {
													margin: 0px 0px 50px 0px;

													fieldset {
														.field {
															.control {
																.note {
																	&:before {
																		display: none;
																	}

																	span {
																		font-weight: 600;
																	}
																}
															}
														}
													}
												}
											}

											.addresses {
												.control {
													.shipping-address-items {
														.shipping-address-item {
															width: 33.33333%;
															padding: .9375rem;
															margin: 0rem .9375rem 0rem 0rem;
															border: 2px solid $lightGrey;

															button {
																width: 100%;
															    height: 45px;
															    padding: 0rem 2rem 0rem 2rem;
															    position: relative;
															    display: inline-block;
															    background-color: $yellow;
															    border: none !important;
															    border-radius: 5px;
															    box-shadow: 3px 3px 12px rgba($black, 0);
															    color: $grey !important;
															    font-family: $roboto;
															    font-size: 16px;
															    font-weight: 700;
															    font-style: normal;
															    line-height: 45px;
															    text-align: center;
															    text-decoration: none !important;
															    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

															    &.edit-address-link {
															    	display: none !important;
															    }

															    &:hover, &:focus {
															        color: $grey;
															    }
															}

															&.selected-item {
																border-color: $yellow;

																&:after {
																	background-color: $yellow;
																	color: $grey;
																}
															}
														}
													}
												}
											}

											button {
												&.action-show-popup {
													display: none;
												}
											}
										}
									}

									.checkout-shipping-method {
										.checkout-shipping-method {
											.step-title {
												margin: 50px 0px 20px 0px;
											}

											.step-content {
												margin: 0;

												form {
													#checkout-shipping-method-load {
														table {
															width: 100%;
															max-width: none;

															thead, tbody, tfoot {
																background-color: $lightGrey;
																border: 1px solid $lightGrey;

																tr {
																	td {
																		padding: .9375rem .9375rem 0rem .9375rem;
																		color: $grey;
																		font-family: $roboto;
																		font-size: 16px;
																		font-weight: 700;
																		font-style: normal;
																		line-height: 16px;

																		input {
																			margin: 0;
																			top: 1px;
																			position: relative;
																		}
																	}
																}
															}
														}
													}

													.actions-toolbar {
														margin: 50px 0px 0px 0px;

														.primary {
															button {
																min-width: 200px;
																background-color: $yellow;
																border: none;
																color: $white;
															    font-family: $roboto;
															    font-size: 16px;
															    font-weight: 700;
															    font-style: normal;
																line-height: 40px;
																float: right;
															}
														}
													}
												}
											}
										}
									}

									.checkout-payment-method {
										.step-content {
											form {
												fieldset {
													legend, br {
														display: none;
													}

													.opc-payment {
														.items {
															.payment-group {
																.payment-method {
																	.payment-method-title {
																		padding: 0;

																		label {
																			width: auto !important;
																		}
																	}

																	.payment-method-content {
																		padding: 0px 0px 20px 0px;

																		.payment-method-billing-address {
																			.checkout-billing-address {
																				margin: 0;

																				.billing-address-same-as-shipping-block {
																					label {
																						width: auto !important;

																						span {
																							&:after {
																								position: relative;
																								content: '.';
																							}
																						}
																					}
																				}

																				fieldset {
																					.billing-address-form {
																						max-width: none;

																						form {
																							fieldset {
																								.field {
																									&[name="billingAddresscheckmo.country_id"] {
																										margin-top: 1rem !important;
																									}
																								}
																							}
																						}
																					}

																					.actions-toolbar {
																						justify-content: flex-end;

																						.primary {
																							width: auto;

																							button {
																								border-radius: 5px !important;
																								text-decoration: none !important;
																							}

																							.action-cancel {
																								margin: 0rem .625rem 0rem 0rem !important;
																							}
																						}
																					}
																				}

																				.billing-address-details {
																					display: none !important;
																				}
																			}
																		}
																	}

																	&:last-of-type {
																		.payment-method-title {
																			padding: 20px 0px 0px 0px;
																			border-top: 1px solid $lightGrey !important;
																		}

																		.payment-method-content {
																			padding: 0;
																		}
																	}
																}
															}
														}
													}

													.discount-code {
														display: none;
													}
												}
											}
										}
									}
								}
							}

							.opc-sidebar {
								display: none !important;
							}

							.authentication-wrapper {
								display: none;
							}
						}
					}
				}
			}

			.page-header {
				display: none;
			}
		}
	}

	&.checkout-onepage-success {
		.page-wrapper {
			padding-top: 50px;

			.page-main {
				.page-title-wrapper {
					a {
						&.print {
							display: none;
						}
					}
				}

				.widget {
					display: none;
				}
			}
		}
	}
}

.checkout-shipping-method .no-quotes-block {
	margin-bottom: 0 !important;
}

/**
* _magento-checkout-websiteni - Medium.
*/
@media screen and (min-width: 640px) {
    .page-layout-checkout {
		&.checkout-index-index {

		}
	}
}

/**
* _magento-checkout-websiteni - Large.
*/
@media screen and (min-width: 1024px) {
    .page-layout-checkout {
		&.checkout-index-index {

		}
	}
}

/**
* _magento-checkout-websiteni - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _magento-checkout-websiteni - XXL.
*/
@media screen and (min-width: 1440px) {
    
}