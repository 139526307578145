/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _magento-account-websiteni - Small.
*/
aside {
	&.modal-popup {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba($black, .3);

		.modal-inner-wrap {
			width: 100%;
			max-width: calc(100% - .625rem * 2);
			padding: 1rem 1rem 0rem 1rem;
			margin: 0;
			position: relative;
			background-color: $white;
			box-shadow: 3px 3px 12px rgba($black, .6);

			.modal-header {
				padding: 0;
				margin: 0;
				border-bottom: none;

				button {
					padding: 1rem;
					background-color: $yellow;

					&:before {
						color: $grey;
						font-weight: 700;
					}
				}
			}

			.modal-content {
				padding: 0rem 1rem 0rem 1rem;

				.block-authentication {
					display: flex;
					flex-direction: column;
					border: none;

					.block-new-customer {
						width: 100%;
						padding: 0rem 0rem 2rem 0rem;
						border: none;
						border-bottom: 1px solid $lightGrey;

						.block-title {
							margin: 0rem 0rem 2rem 0rem !important;

							strong {
								text-transform: capitalize;
							}
						}

						.block-content {
							p {
								margin: 0rem 0rem 1rem 0rem;
							}

							ul {
								margin-bottom: 2rem;
								list-style: circle;

								li {
									margin: 0;
									font-family: $roboto;
									font-size: 14px;
									font-weight: 400;
									font-style: normal;
									line-height: 22px;
								}
							}

							.actions-toolbar {
								.primary {
									float: left;
								}
							}
						}
					}

					.block-customer-login {
						width: 100%;
						padding: 2rem 0rem 1rem 0rem;
						border: none;

						.block-title {
							margin: 0rem 0rem 2rem 0rem !important;

							strong {
								text-transform: capitalize;
							}
						}

						.block-content {
							form {
								.fieldset {
									.field {
										margin: 0rem 0rem 1rem 0rem !important;

										label, .label {
											width: 100% !important;
											height: auto;
											padding: 0 !important;
											margin: 0px 0px 5px 0px !important;
											background-color: transparent;
											text-align: left !important;
											float: none !important;

											span {
												color: $grey;
												font-family: $roboto;
												font-size: 14px;
												font-weight: 700;
												font-style: normal;
												line-height: 26px;
											}

											&:after {
												display: none;
											}
										}

										.control {
											width: 100% !important;
											float: none !important;

											input {
												width: 100%;
												height: 40px;
												padding: 0rem 1rem 0rem 1rem;
												margin: 0;
												background-color: $lightGrey;
												border: none;
												border-radius: 5px;
												box-shadow: none;
												color: $grey;
												font-family: $roboto;
												font-size: 14px;
												font-weight: 400;
												font-style: normal;
												line-height: 40px;
												z-index: 0 !important;
											}

											@include placeholder {
												color: $mediumGrey;
											}

											.mage-error {
												margin: 5px 0px 0px 0px !important;
												font-family: $roboto;
												font-size: 14px;
												font-weight: 700;
												font-style: normal;
												line-height: 26px;
											}
										}

										&:last-of-type {
											margin: 0 !important;
										}
									}

									.actions-toolbar {
										margin: 1rem 0rem 0rem 0rem !important;

										.primary, .secondary {
											width: 50%;
											padding: 0;
											margin: 0;
											float: left;
										}

										.secondary {
											display: flex;
											justify-content: flex-end;
											align-items: center;

											a {
												margin: 0;
												background-color: transparent !important;
												border: none !important;
												box-shadow: none !important;
												color: $grey !important;
												font-family: $roboto;
												font-size: 14px;
												font-weight: 700;
												font-style: normal;
												line-height: 22px;
												text-decoration: none !important;

												&:hover {
													background-color: transparent !important;
													border: none !important;
													box-shadow: none !important;
													color: $grey !important;
													text-decoration: none !important;
												}
											}
										}
									}
								}
							}
						}

						&:before {
							display: none;
						}
					}
				}
			}
		}
	}
}

a {
	&.action {
		&.remind {
			box-shadow: none !important;
		}
	}
}

.page-layout-magento-account-websiteni {
	.page-wrapper {
		.page-main {
			padding-top: 0;

			.columns {
				display: flex;

				.sidebar-main {
					width: 100%;
					padding: 0;
					flex-grow: unset;
    				flex-basis: unset;
					order: 0;

					.block {
						margin: 0;

						.content {
							padding-top: .625rem;
							padding-bottom: 1.875rem;
							padding-right: 0;
							margin-bottom: 1.875rem;
							margin-right: 0;
							background-color: transparent;
							border-bottom: 1px solid $lightGrey;

							ul {
								margin: 0;
								list-style: none;

								li {
									padding: 0rem 0rem .625rem 0rem !important;
									margin: 0;
									color: $grey;
									font-family: $roboto;
									font-size: 14px;
									font-weight: 400;
									font-style: normal;
									line-height: 26px;

									a {
										padding: 0;
										border: none;
										color: $grey;
										font-family: $roboto;
										font-size: 14px;
										font-weight: 400;
										font-style: normal;
										line-height: 26px;
										transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);

										&:hover {
											background-color: transparent;
										}
									}

									strong {
										padding: 0;
										font-weight: 700;
										border: none;
									}

									.delimiter {
										width: 160px;
										margin: 0;
										border-top: 1px solid $lightGrey;
									}

									// &:nth-of-type(3), &:nth-of-type(6) {
									// 	display: none;
									// }

									&:last-of-type {
										padding: 0 !important;
									}
								}
							}
						}
					}
				}

				.column {
					width: 100%;
					padding: 0;
					flex-grow: unset;
    				flex-basis: unset;
					order: 1;

					.block {
						&.block-dashboard-info {
							padding: 0 !important;
							// padding: 0px 0px 30px 0px !important;
							margin: 0 !important;
							// margin: 0px 0px 30px 0px !important;
							border-bottom: none !important;
							// border-bottom: 1px solid $lightGrey !important;
						}

						&.block-dashboard-addresses {
							display: none;
						}
					}

					.actions-toolbar {
						.secondary {
							float: none;

							a {
								max-width: none;
								text-transform: capitalize;
							}
						}
					}
				}

				.sidebar-additional {
					display: none;
				}
			}
		}
	}

	&.customer-account-index {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						.block-dashboard-addresses {
							.block-title {
								a {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}

	&.customer-account-edit {
		form {
			fieldset {
				&.password {
					margin-bottom: -1rem;

					.field {
						margin-bottom: 1rem !important;

						&:last-of-type {
							margin-bottom: 1rem !important;
						}
					}
				}
			}

			.field {
				&.password-info {
					margin: 1rem 0rem 0rem 0rem;
				}
			}
		}
	}

	&.wishlist-index-index {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						.toolbar {
							padding: 0;
							margin: 0px 0px 50px 0px;
							display: flex;
							order: 1;
							text-align: left;

							.toolbar-amount, .limiter, .sorter {
								display: inline-flex !important;
								align-items: center !important;
								float: none !important;
							}

							.toolbar-amount {
								height: 14px;
								padding: 0px 15px 0px 0px;
								margin: 13px 15px 0px 0px;
								border-right: 1px solid $grey;
								order: 1;
								color: $grey;
								font-family: $roboto;
								font-size: 14px;
								font-weight: 400;
								font-style: normal;
								line-height: 22px;

								.toolbar-number {
									padding: 0rem .25rem 0rem .25rem;
								}
							}

							.limiter {
								order: 2;

								label, span, strong {
									padding: 0;
									background-color: transparent;
									color: $grey;
									font-family: $roboto;
									font-size: 14px;
									font-weight: 400;
									font-style: normal;
									line-height: 40px;
								}

								label {
									padding: 0rem .625rem 0rem 0rem;

									span {
										padding: 0;
									}
								}

								span {
									display: block;
									padding: 0rem 0rem 0rem .625rem;
								}

								strong {
									padding: 0rem .625rem 0rem 0rem;
								}

								.control {
									position: relative;

									&:after {
										width: 14px;
										height: 14px;
										top: 50%;
										right: 19px;
										transform: translateY(-50%) rotate(-90deg);
										position: absolute;
										display: block;
										pointer-events: none;
										content: url('../images/arrow.svg');
									}
								}

								select {
									width: 80px;
									height: 40px;
									padding: 0rem .9375rem 0rem .9375rem;
									margin: 0;
									background-color: transparent;
									border: 1px solid $lightGrey;
									border-radius: 0;
									box-shadow: none;
									color: $grey;
									font-family: $roboto;
									font-size: 14px;
									font-weight: 400;
									font-style: normal;
									line-height: 40px;
									text-align: left;
								}
							}

							.sorter {
								padding: 0;
								margin: 0px 0px 0px auto;
								position: relative;
								order: 3;

								label {
									padding: 0rem .625rem 0rem 0rem;
									display: none;
									color: $grey;
									font-family: $roboto;
									font-size: 14px;
									font-weight: 400;
									font-style: normal;
									line-height: 40px;
								}

								select {
									width: 200px;
									height: 40px;
									padding: 0rem .9375rem 0rem .9375rem;
									margin: 0;
									background-color: transparent;
									border: 1px solid $lightGrey;
									border-radius: 0;
									box-shadow: none;
									color: $grey;
									font-family: $roboto;
									font-size: 14px;
									font-weight: 400;
									font-style: normal;
									line-height: 40px;
									text-align: left;
								}

								a {
									&.sorter-action {
										display: none;
									}
								}

								&:after {
									width: 14px;
									height: 14px;
									top: 50%;
									right: 19px;
									transform: translateY(-50%) rotate(-90deg);
									position: absolute;
									display: block;
									pointer-events: none;
									content: url('../images/arrow.svg');
								}
							}

							.toolbar-amount, .limiter, .modes {
								display: none !important;
							}

							&:last-of-type {
								width: 100%;
								display: none;
								order: 4;

								.pages {
									width: 100%;
									float: none;

									ul {
										width: 100%;
										position: relative;
										display: flex;
										justify-content: center;
										align-items: center;

										.item {
											width: 40px;
											height: 40px;
											padding: 0;
											margin: 0px 2px 0px 2px;
											display: flex;
											align-items: center;
											background-color: transparent;
											border: 1px solid $lightGrey;
											border-radius: 0;
											box-shadow: none;

											strong, a {
												width: 100%;
												margin: 0;
												display: block;
												border: none;
												color: $mediumGrey;
												font-family: $roboto;
												font-size: 14px;
												font-weight: 400;
												font-style: normal;
												line-height: 40px;
												text-align: center;

												&:before, &:after {
													display: none;
												}
											}

											&.current {
												background-color: $grey;
												border: 1px solid $grey;

												strong, a {
													color: $white;
												}
											}

											&.pages-item-previous, &.pages-item-next {
												width: 100px;
												padding: 0rem .9375rem 0rem .9375rem;
												margin: 0rem 1.875rem 0rem 0rem;
												position: relative;

												span {
													width: 100%;
													height: 40px;
													margin: 0;
													position: relative;

													&.label {
														display: none;
													}

													&:before, &:after {
														display: none;
													}
												}
											}

											&.pages-item-next {
												margin: 0rem 0rem 0rem 1.875rem;
											}
										}
									}
								}

								.toolbar-amount, .limiter, .sorter, .modes {
									display: none !important;
								}
							}
						}

						.products-grid {
							.product-items {
								.product-item {
									.product-item-name {
										margin: 0rem 0rem .75rem 0rem;
										text-align: center;
									}

									.price-box {
										margin: 0;
										text-align: center;

										span {
											span {
												span {
													color: $grey;
												    font-family: $roboto;
												    font-size: 20px;
												    font-weight: 700;
												    font-style: normal;
												    line-height: 20px;
												}
											}
										}
									}

									.product-item-inner {
										padding: .625rem;
										margin: .625rem;
										background-color: $offWhite;
										border-radius: 5px;

										textarea {
											width: 100%;
											height: 150px;
											padding: 1rem;
											margin: 0rem 0rem calc(.625rem * 2) 0rem;
											background-color: $white;
											border: none;
											border-radius: 5px;
											box-shadow: none;
											color: $grey;
											font-family: $roboto;
											font-size: 14px;
											font-weight: 400;
											font-style: normal;
											line-height: 26px;
											z-index: 0 !important;
										}

										@include placeholder {
											color: $mediumGrey;
										}

										.box-tocart {
											.fieldset {
												.qty {
													padding: 0;
													display: flex;
													align-items: center;

													.label {
														margin: 0 !important;
													}

													.control {
														input {
															width: 100%;
															margin: 0;
															background-color: $white;
														}
													}
												}
											}
										}

										.product-item-actions {
											margin: calc(.625rem * 2) 0rem 0rem 0rem !important;
											display: flex;
											justify-content: space-between;

											.actions-primary {
												width: 100%;
												display: block;

												button {
													width: 100%;
													height: 45px;
													padding: 0rem 1rem 0rem 1rem;
													margin: 0;
													display: inline-block;
													background-color: $yellow;
													border: none;
													border-radius: 5px;
													box-shadow: 3px 3px 12px rgba($black, 0);
													text-align: center !important;
													overflow: hidden;
													transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

													span {
														// font-size: 0;
														// line-height: 0;

														// &:before {
															color: $grey;
															font-family: $roboto;
															font-size: 16px;
															font-weight: 700;
															font-style: normal;
															line-height: 45px;
															// content: 'Add to Favourites';
														// }
													}
												}
											}
										}
									}
								}
							}
						}

						.actions-toolbar {
							margin: 0 !important;

							.primary {
								display: flex;

								button {
									margin: 0px 5px 5px 0px !important;

									span {
										pointer-events: none;
									}
								}
							}

							.secondary {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}

/**
* _magento-category-websiteni - Mobile Only.
*/
@media screen and (max-width: 639px) {
	.page-layout-magento-account-websiteni {
		&.wishlist-index-index {
			.page-wrapper {
				.page-main {
					.columns {
						.column {
							.toolbar {
	    						.sorter {
	    							width: 100%;
	    							margin: 0;

	    							.sorter-label {
	    								width: 80px;
	    								display: block;
	    								font-size: 16px;
	    								font-weight: 700;
	    							}

	    							select {
	    								width: calc(100% - 80px);
	    							}
	    						}

	    						&:last-of-type {
	    							margin-bottom: -1px;
	    							top: -1px;
	    							position: relative;
	    						}
	    					}
						}
					}
				}
			}
		}
	}
}

/**
* _magento-account-websiteni - Medium.
*/
@media screen and (min-width: 640px) {
	aside {
		&.modal-popup {
			.modal-inner-wrap {
				max-width: 500px;
				padding: 2rem 2rem 0rem 2rem;

				.modal-content {
					padding: 0rem 2rem 0rem 2rem;

					.block-authentication {
						.block-customer-login {
							padding: 2rem 0rem 2rem 0rem;
						}
					}
				}
			}
		}
	}

	.page-layout-magento-account-websiteni {
		.page-wrapper {
			.page-main {
				.columns {
					.sidebar-main {
						width: 30%;

						.block {
							.content {
								padding-top: 0;
								padding-bottom: .9375rem;
								padding-right: 1.875rem;
								margin-bottom: 0;
								margin-right: 1.875rem;
								border-bottom: none;
								border-right: 1px solid $lightGrey;

								ul {
									li {
										padding: 0rem 0rem .9375rem 0rem !important;

										&:last-of-type {
											padding: 0 !important;
										}
									}
								}
							}
						}
					}

					.column {
						width: 70%;
					}
				}
			}
		}

		&.sales-order-history, &.customer-address-form, &.customer-account-edit, &.vault-cards-listaction, &.review-customer-index, &.newsletter-manage-index {
			.page-wrapper {
				&:before {
					width: 100%;
			        max-width: 1320px;
			        padding-top: 25px;
			        padding-left: .9375rem;
			        padding-bottom: 25px;
			        padding-right: .9375rem;
			        margin: 0 auto 0 auto;
			        color: $grey;
                    font-family: $roboto;
                    font-size: 14px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: 18px;
				    content: 'Home > My Account > My Orders';
				}
			}
		}

		&.wishlist-index-index {
			.page-wrapper {
				.page-main {
					.columns {
						.column {
							.toolbar {
								.toolbar-amount, .limiter {
									display: inline-flex !important;
								}

								&:last-of-type {
									padding-top: 22px;
								}
							}
						}
					}
				}
			}
		}

		&.customer-address-form {
			.page-wrapper {
				.page-main {
					.columns {
						.column {
							.block:not(:last-of-type), fieldset:not(:last-of-type), .fieldset:not(:last-of-type) {
								padding: 0px 0px 30px 0px !important;
								margin: 0px 0px 30px 0px !important;
								border-bottom: 1px solid $lightGrey !important;
							}
						}
					}
				}

				&:before {
					content: 'Home > My Account > My Addresses';
				}
			}
		}

		&.customer-account-edit {
			.page-wrapper {
				&:before {
					content: 'Home > My Account > My Information';
				}
			}
		}

		&.vault-cards-listaction {
			.page-wrapper {
				&:before {
					content: 'Home > My Account > My Payment Methods';
				}
			}
		}

		&.review-customer-index {
			.page-wrapper {
				&:before {
					content: 'Home > My Account > My Product Reviews';
				}
			}
		}

		&.newsletter-manage-index {
			.page-wrapper {
				.page-main {
					.columns {
						.column {
							form {
								fieldset, .fieldset {
									.field {
										&:last-of-type {
											&.choice {
												margin: 0 !important;
											}
										}
									}
								}
							}
						}
					}
				}

				&:before {
					content: 'Home > My Account > My Newsletter Subscriptions';
				}
			}
		}
	}
}

/**
* _magento-account-websiteni - Large.
*/
@media screen and (min-width: 1024px) {
    aside {
		&.modal-popup {
			.modal-inner-wrap {
				padding: 3rem 3rem 0rem 3rem;

				.modal-content {
					padding: 0rem 3rem 0rem 3rem;

					.block-authentication {
						.block-customer-login {
							padding: 2rem 0rem 3rem 0rem;
						}
					}
				}
			}
		}
	}

	a {
		&.action {
			&.remind {
				&:hover {
					box-shadow: none !important;
				}
			}
		}
	}
}

/**
* _magento-account-websiteni - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _magento-account-websiteni - XXL.
*/
@media screen and (min-width: 1440px) {
    
}