/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _homepage - Small.
*/
.page-layout-homepage {
	.page-wrapper {
		.page-main {
			display: none;
		}

		.homepage-slider {
			padding-top: 30px;
			padding-bottom: 30px;

			#homepage-slider, #homepage-customer-slider {
				.bx-wrapper {
					margin: 0;
		            left: 0;
		            background-color: transparent;
		            border: none;
		            box-shadow: none;

		            .bx-viewport {
		            	ul {
		            		margin: 0;
		            		list-style: none;
		            	}
		            }

		            .bx-controls {
		            	.bx-pager {
		            		max-width: calc(1320px - .625rem * 2);
		            		padding: 0rem .625rem 0rem .625rem;
		            		margin: auto;
		            		left: 0;
		            		bottom: .625rem;
		            		right: 0;
		            		text-align: left;

		            		.bx-pager-item {
		            			a {
		            				width: 15px;
		            				height: 15px;
		            				margin: 0px 3px 0px 3px;
		            				background-color: transparent;
		            				border: 2px solid $white;
		            				border-radius: 100%;

		            				&.active {
		            					background-color: $black;
		            					border: 2px solid $black;
		            				}
		            			}

		            			&:first-of-type {
		            				a {
		            					margin: 0px 3px 0px 0px;
		            				}
		            			}

		            			&:last-of-type {
		            				a {
		            					margin: 0px 0px 0px 3px;
		            				}
		            			}
		            		}
		            	}
		            }
				}
			}
		}

		.homepage-blocks {
			.block {
				min-height: 250px;
				position: relative;
				transform: translate(0);
				box-shadow: 3px 3px 12px rgba($black, 0);
				transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);

				p {
					height: 100%;
					margin: 0;

					a {
						height: 100%;
						display: block;

						img {
							min-width: 100%;
							height: 250px;
							object-fit: cover;
							object-position: center;
						}
					}
				}

				.absolute {
					top: 30px;
					left: 30px;
					// pointer-events: none;

					h3, h4 {
						color: $yellow;

						&:first-of-type {
							color: $white;
						}

						&:last-of-type {
							margin: 0rem 0rem 2rem 0rem !important;
						}
					}

					p {
						height: auto;

						a {
							height: auto;
							padding: 0px 0px 0px 30px;
						    position: relative;
						    color: $white;
						    font-family: $roboto;
						    font-size: 14px;
						    font-weight: 700;
						    font-style: normal;
						    line-height: 20px;
						    text-decoration: none;

						    &:before {
						    	width: 20px;
						    	height: 20px;
						        top: 0;
						        left: 0;
						        position: absolute;
						        transform: translateY(-1px) rotate(-90deg);
						        content: url('../images/jt-button-arrow.svg');
						    }
						}
					}
				}

				&.block-one {
					margin-bottom: calc(.625rem * 2);
				}

				&.block-two, &.block-three {
					.absolute {
						top: 30px;
						left: 30px;
					}
				}

				&.block-three {
					.absolute {
						h4 {
							color: $grey;

							&:last-of-type {
								margin: 0rem 0rem .625rem 0rem;
							}
						}

						p {
							&:nth-last-of-type(2) {
								max-width: 100px;
								margin: 0rem 0rem 2rem 0rem;
								color: $grey;
							}
						}

						a {
							color: $grey;

							&:before {
								content: url('../images/jt-button-arrow-grey.svg');
							}
						}
					}

					&:before {
						background-color: rgba($white, .4);
					}
				}

				&:before {
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					position: absolute;
					background-color: rgba($black, .5);
					content: '';
				}
			}
		}

		.homepage-categories {
			h5 {
				margin: 1.25rem 0rem 1.25rem 0rem;
			}

			.all-departments {
				color: $grey;
				text-decoration: none;
			}
		}

		.homepage-about {
			.image {
				position: relative;

				&:before {
					width: 25%;
					height: 1px;
					top: 50%;
					left: 0;
					position: absolute;
					background-color: $mediumGrey;
					content: '';
				}

				&:after {
					width: 25%;
					height: 1px;
					top: 50%;
					right: 0;
					position: absolute;
					background-color: $mediumGrey;
					content: '';
				}
			}

			.buttons {
				p {
					width: calc(50% - .625rem / 2);
					height: 45px;
					margin: 0;
					display: inline-block;

					a {
						width: 100%;
						padding: 0rem 1rem 0rem 1rem;
						font-size: 12px;
					}

					&:first-of-type {
						float: left;
					}

					&:last-of-type {
						float: right;
					}
				}
			}
		}
	}
}

/**
* _homepage - Medium.
*/
@media screen and (min-width: 640px) {
	.page-layout-homepage {
		.page-wrapper {
			.homepage-slider {
				#homepage-slider, #homepage-customer-slider {
					.bx-wrapper {
			            .bx-controls {
			            	.bx-pager {
			            		max-width: calc(1320px - .9375rem * 2);
			            		padding: 0rem .9375rem 0rem .9375rem;
			            		bottom: .9375rem;
			            	}
			            }
					}
				}
			}

			.homepage-blocks {
				.block {
					p {
						a {
							img {
								height: auto;
								min-height: 250px;
								max-height: 375px;
							}
						}
					}

					.absolute {
						top: 40px;
						left: 40px;
					}

					&.block-one {
						margin-bottom: calc(.9375rem * 2);
					}

					&:before {
						display: none;
					}
				}
			}

			.homepage-categories {
				h5 {
					margin: 1.875rem 0rem 1.875rem 0rem;
				}
			}

			.homepage-about {
				p {
					&:nth-of-type(3) {
						margin: 0;
					}
				}

				.image {
					p {
						margin: 0;
					}

					&:before {
						width: 1px;
						height: 100px;
						top: -125px;
						left: 50%;
					}

					&:after {
						width: 1px;
						height: 100px;
						top: auto;
						left: 50%;
						bottom: -125px;
					}
				}

				.buttons {
					margin: 3rem 0rem 0rem 0rem;

					p {
						a {
							max-width: 200px;
							font-size: 16px;
						}

						&:first-of-type {
							text-align: right;
						}
					}
				}
			}
		}
	}
}

/**
* _homepage - Large.
*/
@media screen and (min-width: 1024px) {
	.page-layout-homepage {
		.page-wrapper {
			.homepage-blocks {
				.block {
					.absolute {
						top: 50px;
						left: 50px;
					}

					&.block-one {
						p {
							a {
								img {
									height: 530px;
									max-height: none;
								}
							}
						}
					}

					&.block-two, &.block-three {
						p {
							a {
								img {
									height: 250px;
								}
							}
						}
					}

					&:hover {
						transform: translateY(-.9375rem);
						box-shadow: 3px 3px 12px rgba($black, .3);
					}
				}
			}

			.homepage-categories {
				h5 {
					margin: 0rem 0rem 1.875rem 0rem;
				}
			}

			.homepage-about {
				.image {
					&:before {
						height: 75px;
						top: -100px;
					}

					&:after {
						height: 75px;
						bottom: -100px;
					}
				}

				.buttons {
					margin: 5rem 0rem 0rem 0rem;
				}
			}
		}
	}
}

/**
* _homepage - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _homepage - XXL.
*/
@media screen and (min-width: 1440px) {

}