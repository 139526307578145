/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* Global Styles - Small.
*/
.amsearch-related-terms {
    display: none !important;
}

/* Prevent Horizontal Scrollbar. */
html, body {
	max-width: 100%;
	// overflow-x: hidden;
    font-size: 16px;
}

body {
    width: 100%;
    display: flex;
    flex-direction: column;
    // overflow-x: hidden;

    .global-pre-header {
        order: 1;
    }

    .global-header {
        order: 2;
    }

    .page-wrapper {
        min-height: auto;
        display: flex;
        flex-direction: column;
        order: 3;
    }

    .global-newsletter {
        order: 4;
    }

    .global-footer {
        order: 5;
    }

    .global-sub-footer {
        order: 6;
    }

    .page-wrapper {
        position: relative;
        // background-image: url('../images/background-graphic-flag.svg');
        // background-size: 100% auto;
        // background-position: top center;
        // background-repeat: no-repeat;

        .homepage-slider, .homepage-blocks, .homepage-categories, .homepage-about, .breadcrumbs, .page-main, .all-departments, .warranties, .register-your-purchase, .warranty-repairs, .need-assistance, .fake-sidebar, .contact-map, &:before {
            position: relative;
            z-index: 1;
        }

        &:after {
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            background-image: linear-gradient(rgba($white, .8), rgba($white, 1));
            z-index: 0;
            pointer-events: none;
            content: '';
        }
    }
}

.freeshippingback {
    width: 100%;
    padding: 0rem 1rem 0rem 1rem;
    position: relative;
    display: inline-block;
    background-color: $yellow;
    border: none;
    border-radius: 0;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, .2);
    color: $grey !important;
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    line-height: 45px;
    text-align: center !important;
    text-decoration: none !important;
    overflow: hidden;
    transition: 0.4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

    p {
        margin: 0 !important;
        display: inline-block;
        font-size: 18px;
        line-height: 45px;

        &.disclaimer {
            font-size: 12px;
            font-style: italic;
        }
    }
}

// SHOW / HIDE LONG LAYERED NAV ITEMS
.filter-options-content li{
    &.more.toggleable {
        display: none!important;
    }
    &.more{
        margin: 10px 0 0!important;
        color: #000;
        font-weight: 700;
        cursor: pointer;
    }
}

.page-wrapper {
    padding-bottom: 50px;
    opacity: 0;

    .breadcrumbs {
        width: 100%;
        max-width: 1320px;
        padding-top: 25px;
        padding-left: .625rem;
        padding-bottom: 25px;
        padding-right: .625rem;
        margin: 0 auto 0 auto;
        display: none;
        order: 1;

        ul {
            margin: 0;
            list-style: none;

            li {
                margin: 0;

                a, strong {
                    position: relative;
                    display: inline-block;
                    color: $grey;
                    font-family: $roboto;
                    font-size: 14px;
                    font-weight: 400;
                    font-style: normal;
                    line-height: 14px;
                    text-transform: none;
                    text-decoration: none !important;

                    &:before,
                    &:after {
                        width: 0;
                        height: 2px;
                        top: calc(100% + 6px);
                        right: 0;
                        display: block;
                        position: absolute;
                        content: '';
                    }

                    &:before {
                        background-color: rgba($black, .2);
                        transition: width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s;
                    }

                    &:after {
                        background-color: $black;
                        transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
                    }
                }

                &:after {
                    color: $mediumGrey;
                }
            }
        }
    }

    .page-main {
        width: 100%;
        max-width: 1320px;
        padding-left: .625rem;
        padding-right: .625rem;
        order: 2;

        .page-title-wrapper, .event-page-description {
            width: 100%;
            margin: 25px 0px 25px 0px;

            .page-title {
                margin: 0;

                .base {
                    color: $yellow;

                    span {
                        display: block;
                        color: $grey;
                    }
                }
            }
        }

        .event-page-description {
            .description {
                margin: 2rem 0rem 0rem 0rem !important;
                color: $grey;
                font-family: $roboto;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                line-height: 22px;
            }
        }

        .columns {
            width: 100%;

            .column {
                min-height: auto !important;
                padding-bottom: 0;
            }
        }

        a {
            &#contentarea {
                display: none;
            }
        }
    }

    header.page-header, .nav-sections, footer.page-footer {
        display: none;
    }
}

.customer-account-createpassword {
    .page-wrapper {
        padding-top: 50px;
    }
}

.grid-container {
    width: 100%;
    max-width: 1320px !important;

    &.full {
        width: 100%;
        max-width: 100% !important;
    }
}

.page-main, .internal-content {
    h1, h2, h3, h4, h5, h6, p, ul, ol, li, a {
        color: $grey;

        &:last-child {
            margin-bottom: 0;
        }
    }

    ul, ol {
        margin: 0rem 0rem 2rem 3rem;
        font-family: $roboto;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 22px;

        li {
            padding: 0rem 0rem .625rem 0rem;
            margin: 0;

            &:last-of-type {
                padding: 0;
            }
        }
    }

    h1, h2, h3 {
        margin: 3rem 0rem 2rem 0rem;

        &:first-child {
            margin: 0rem 0rem 2rem 0rem;
        }
    }

    h4, h5, h6 {
        margin: 2rem 0rem 1.5rem 0rem !important;

        &:first-of-type {
            margin: 0rem 0rem 1.5rem 0rem !important;
        }
    }

    p {
        &:first-child {
            margin: 0rem 0rem 1.5rem 0rem;
            font-weight: 700;

            a {
                font-weight: 700;
            }
        }
    }

    ul {
        list-style: circle;
    }

    a:not(.accordion-title):not(.photo):not(.fc-event):not(.exclude) {
        position: relative;
        display: inline-block;
        font-family: $roboto;
        font-size: 14px;
        font-weight: 700;
        font-style: normal;
        line-height: 14px;
        text-decoration: none !important;

        &:before,
        &:after {
            width: 0;
            height: 2px;
            top: calc(100% + 6px);
            right: 0;
            position: absolute;
            content: '';
        }

        &:before {
            background-color: rgba($black, .2);
            transition: width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s;
        }

        &:after {
            background-color: $black;
            transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
        }
    }

    // :not(.filter-options a)
}

.cms-about {
    .page-main, .internal-content {
        p {
            &:nth-last-child(2) {
                margin: 0;
            }
        }
    }
}

.categories {
    .category {
        transform: translate(0);
        box-shadow: 3px 3px 12px rgba($black, 0);
        text-decoration: none;
        transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);

        .content {
            padding: 1.875rem 0rem .9375rem .9375rem;

            &.black {
                background-color: $black;

                h6 {
                    max-width: 90px;
                    color: $white;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($white, .1);
                }
            }

            &.yellow {
                background-color: $yellow;

                h6 {
                    max-width: 90px;
                    color: $grey;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($black, .1);
                }

                .jt-button {
                    color: $grey;

                    &:before {
                        content: url('../images/jt-button-arrow-grey.svg');
                    }
                }
            }

            &.grey {
                background-color: $greyAlt;

                h6 {
                    max-width: 90px;
                    color: $white;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($white, .1);
                }
            }
        }

        &.category-8 {
            order: 1;

            .content {
                background-color: $yellow;

                h6 {
                    max-width: 90px;
                    color: $grey;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($black, .1);
                }

                .jt-button {
                    color: $grey;

                    &:before {
                        content: url('../images/jt-button-arrow-grey.svg');
                    }
                }
            }
        }

        &.category-9 {
            order: 2;

            .content {
                background-color: $black;

                h6 {
                    max-width: 90px;
                    color: $white;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($white, .1);
                }

                .jt-button {
                    color: $white;

                    &:before {
                        content: url('../images/jt-button-arrow.svg');
                    }
                }
            }
        }

        &.category-10 {
            order: 3;

            .content {
                background-color: $greyAlt;

                h6 {
                    max-width: 90px;
                    color: $white;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($white, .1);
                }

                .jt-button {
                    color: $white;

                    &:before {
                        content: url('../images/jt-button-arrow.svg');
                    }
                }
            }
        }

        &.category-11 {
            order: 4;

            .content {
                background-color: $yellow;

                h6 {
                    max-width: 90px;
                    color: $grey;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($black, .1);
                }

                .jt-button {
                    color: $grey;

                    &:before {
                        content: url('../images/jt-button-arrow-grey.svg');
                    }
                }
            }
        }

        &.category-4 {
            order: 5;

            .content {
                background-color: $black;

                h6 {
                    max-width: 90px;
                    color: $white;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($white, .1);
                }

                .jt-button {
                    color: $white;

                    &:before {
                        content: url('../images/jt-button-arrow.svg');
                    }
                }
            }
        }

        &.category-12 {
            order: 6;

            .content {
                background-color: $greyAlt;

                h6 {
                    max-width: 90px;
                    color: $white;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($white, .1);
                }

                .jt-button {
                    color: $white;

                    &:before {
                        content: url('../images/jt-button-arrow.svg');
                    }
                }
            }
        }

        &.category-5 {
            order: 7;

            .content {
                background-color: $yellow;

                h6 {
                    max-width: 90px;
                    color: $grey;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($black, .1);
                }

                .jt-button {
                    color: $grey;

                    &:before {
                        content: url('../images/jt-button-arrow-grey.svg');
                    }
                }
            }
        }

        &.category-2 {
            order: 8;

            .content {
                background-color: $black;

                h6 {
                    max-width: 90px;
                    color: $white;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($white, .1);
                }

                .jt-button {
                    color: $white;

                    &:before {
                        content: url('../images/jt-button-arrow.svg');
                    }
                }
            }
        }

        &.category-6 {
            order: 9;

            .content {
                background-color: $greyAlt;

                h6 {
                    max-width: 90px;
                    color: $white;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($white, .1);
                }

                .jt-button {
                    color: $white;

                    &:before {
                        content: url('../images/jt-button-arrow.svg');
                    }
                }
            }
        }

        &.category-13 {
            order: 10;

            .content {
                background-color: $yellow;

                h6 {
                    max-width: 90px;
                    color: $grey;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($black, .1);
                }

                .jt-button {
                    color: $grey;

                    &:before {
                        content: url('../images/jt-button-arrow-grey.svg');
                    }
                }
            }
        }

        &.category-16 {
            order: 15;

            .content {
                background-color: $greyAlt;

                h6 {
                    max-width: 90px;
                    color: $white;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($white, .1);
                }

                .jt-button {
                    color: $white;

                    &:before {
                        content: url('../images/jt-button-arrow.svg');
                    }
                }
            }
        }

        &.category-14 {
            order: 12;

            .content {
                background-color: $greyAlt;

                h6 {
                    max-width: 90px;
                    color: $white;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($white, .1);
                }

                .jt-button {
                    color: $white;

                    &:before {
                        content: url('../images/jt-button-arrow.svg');
                    }
                }
            }
        }

        &.category-1 {
            order: 13;

            .content {
                background-color: $yellow;

                h6 {
                    max-width: 90px;
                    color: $grey;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($black, .1);
                }

                .jt-button {
                    color: $grey;

                    &:before {
                        content: url('../images/jt-button-arrow-grey.svg');
                    }
                }
            }
        }

        &.category-3 {
            order: 14;

            .content {
                background-color: $black;

                h6 {
                    max-width: 90px;
                    color: $white;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($white, .1);
                }

                .jt-button {
                    color: $white;

                    &:before {
                        content: url('../images/jt-button-arrow.svg');
                    }
                }
            }
        }

        &.category-15 {
            order: 11;

            .content {
                background-color: $black;

                h6 {
                    max-width: 90px;
                    color: $white;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($white, .1);
                }

                .jt-button {
                    color: $white;

                    &:before {
                        content: url('../images/jt-button-arrow.svg');
                    }
                }
            }
        }

        &.category-7 {
            order: 16;

            .content {
                background-color: $yellow;

                h6 {
                    max-width: 90px;
                    color: $grey;
                }

                hr {
                    width: 100%;
                    margin: 1rem 0rem 1rem 0rem;
                    border-top: 1px solid rgba($black, .1);
                }

                .jt-button {
                    color: $grey;

                    &:before {
                        content: url('../images/jt-button-arrow-grey.svg');
                    }
                }
            }
        }

        &.category-17,&.category-18 {
            display: none;
        }
    }
}

.global-newsletter {
    background-color: $greyAltAlt;

    .company-logo {
        p {
            margin: 0;
        }
    }

    .form {
        h5 {
            margin: 0rem 0rem 2rem 0rem;
            color: $white;

            span {
                font-weight: 400;
            }
        }

        .block.newsletter {
            width: 100%;
            margin: 0;

            .content {
                form {
                    width: 100%;
                    margin: 0;
                    display: flex;
                    flex-direction: column;
                    flex-flow: row wrap;
                    border: none;

                    .field {
                        width: 100%;
                        margin: 0rem 0rem 1rem 0rem;
                        order: 1;

                        .control {
                            label {
                                input {
                                    width: 100%;
                                    height: 45px;
                                    padding: 0rem 1rem 0rem 1rem;
                                    margin: 0;
                                    background-color: $white;
                                    border: none;
                                    border-radius: 5px;
                                    box-shadow: none;
                                    color: $grey;
                                    font-family: $roboto;
                                    font-size: 14px;
                                    font-weight: 400;
                                    font-style: normal;
                                    line-height: 45px;
                                    text-transform: capitalize;
                                    z-index: 0 !important;

                                    &.mage-error {
                                        margin: 0 !important;
                                        font-family: $roboto !important;
                                        font-size: 14px !important;
                                        font-weight: 400 !important;
                                        font-style: normal !important;
                                        line-height: 45px !important;
                                    }
                                }

                                @include placeholder {
                                    color: $grey;
                                }

                                .mage-error {
                                    margin: 5px 0px 0px 0px !important;
                                    font-family: $roboto;
                                    font-size: 14px;
                                    font-weight: 700;
                                    font-style: normal;
                                    line-height: 26px;
                                    text-align: left;
                                }
                            }

                            &:before {
                                display: none;
                            }
                        }
                    }

                    .actions {
                        width: 100%;
                        margin: 0;
                        order: 2;

                        button {
                            width: 100%;
                            height: 45px;
                            padding: 0rem 2rem 0rem 2rem;
                            margin: 0;
                            position: relative;
                            display: inline-block;
                            background-color: $yellow;
                            border: none;
                            border-radius: 5px;
                            box-shadow: 3px 3px 12px rgba($white, 0);
                            color: $grey !important;
                            font-family: $roboto;
                            font-size: 16px;
                            font-weight: 700;
                            font-style: normal;
                            line-height: 45px;
                            text-align: center !important;
                            text-decoration: none !important;
                            overflow: hidden;
                            transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

                            span {
                                pointer-events: none;

                                &.ripple {
                                    transform: scale(.25);
                                    position: absolute;
                                    background-color: rgba($black, .25);
                                    border-radius: 100%;
                                    opacity: 0;
                                    pointer-events: none;
                                    animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
                                }
                            }

                            &:focus {
                                color: $grey;
                            }
                        }
                    }
                }
            }

            .title {
                display: none;
            }
        }
    }

    .socials {
        p {
            width: 40px;
            height: 40px;
            margin: 0px 3px 0px 3px;
            position: relative;
            border: 2px solid $white;
            border-radius: 100%;

            a {
                width: 40px;
                height: 40px;
                display: block;
                font-size: 0;
                line-height: 0;
            }

            &:nth-of-type(1) {
                &:before {
                    width: 20px;
                    height: 20px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                    pointer-events: none;
                    content: url('../images/linkedin.svg');
                }
            }

            &:nth-of-type(2) {
                &:before {
                    width: 20px;
                    height: 20px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                    pointer-events: none;
                    content: url('../images/facebook.svg');
                }
            }

            &:nth-of-type(3) {
                &:before {
                    width: 20px;
                    height: 20px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                    pointer-events: none;
                    content: url('../images/instagram.svg');
                }
            }

            &:nth-of-type(4) {
                &:before {
                    width: 20px;
                    height: 20px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                    pointer-events: none;
                    content: url('../images/twitter.svg');
                }
            }

            &:nth-of-type(5) {
                &:before {
                    width: 20px;
                    height: 20px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    position: absolute;
                    pointer-events: none;
                    content: url('../images/youtube.svg');
                }
            }
        }
    }
}

.global-sub-footer {
    background-color: $black;

    span {
        color: $white;
        font-family: $roboto;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 14px;
    }

    .text-link {
        color: $white;
    }

    .space-sm {
        margin: 1rem 0rem 1rem 0rem;
    }
}

button {
    outline: 0 !important;
}

/* Magnific Popup. */
.mfp-fade {
    &.mfp-bg {
        opacity: 0;
        transition: .4s cubic-bezier(0.51, 0.18, 0, 0.88);

        &.mfp-ready {
            opacity: .75;
        }

        &.mfp-removing {
            opacity: 0;
        }
    }

    &.mfp-wrap {
        .mfp-content {
            opacity: 0;
            transition: .4s cubic-bezier(0.51, 0.18, 0, 0.88);
        }

        &.mfp-ready {
            .mfp-content {
                opacity: 1;
            }
        }

        &.mfp-removing {
            .mfp-content {
                opacity: 0;
            }
        }
    }
}

.mfp-iframe-holder {
    .mfp-close {
        padding: 0;
        top: -60px;
        right: 0;
    }
}

.mfp-form, .mfp-profile {
    width: calc(100% - .625rem * 2);
    max-width: 600px;
    padding: 1rem;
    margin: auto;
    position: relative;
    background-color: $white;

    button {
        &.mfp-close {
            top: 1rem;
            right: 1rem;
            position: absolute;
            background-color: transparent;
            border: 1px solid $grey;
            border-radius: 100%;
            color: $grey;
            font-size: 30px;
            line-height: 30px;
            opacity: 1;

            &:hover {
                background-color: transparent;
            }
        }
    }

    h3 {
        max-width: 75%;
        margin: 0;
        color: $yellow;

        span {
            color: $grey;
        }
    }

    hr {
        width: 100%;
        max-width: 300px;
        margin: 1rem 0rem 2rem 0rem;
        border-top: 1px solid rgba($black, .1);
        text-align: left;
    }
}

.mfp-profile {
    h4 {
        margin: 0 !important;
    }

    .text-link {
        margin: 1rem 0rem 2rem 0rem;
    }
    
    p {
        &:last-of-type {
            margin: 0;
        }
    }
}

.block-new-customer{display:none !important;}

// .other-brand-14{display:none !important;}

.pp-link label:after{
    content:"You can view our privacy policy at the following URL: https://jeffersontools.com/privacy-policy";
    display:block;
}

.pp-link label{display:block !important;}

.cms-careers .page-wrapper .page-main .columns{width:100% !important;}

// AILEEN'S REQUEST - REMOVE ALL BUTTONS IN "MY ACCOUNT" WHICH ALLOW USERS TO EDIT THEIR ACCOUNT DETAILS
.account a.action.edit{display:none !important;}
.account button.action.primary.add{display:none !important;}

/**
* Global Styles - Medium.
*/

@media screen and (max-width: 450px) {
    .cms-homepage .category .image img{min-height: 206px;}
}

@media screen and (min-width: 640px) {
    .page-wrapper {
        .breadcrumbs {
            padding-left: .9375rem;
            padding-right: .9375rem;
            display: block;
        }

        .page-main {
            padding-left: .9375rem;
            padding-right: .9375rem;

            .page-title-wrapper, .event-page-description {
                width: 83.33333%;
                margin: 0px 0px 37.5px 0px;
            }

            .columns {
                width: 83.33333%;
            }
        }
    }

    .page-main, .internal-content {
        ul, ol {
            li {
                padding: 0rem 0rem .9375rem 0rem;
            }
        }
    }

    .categories {
        .category {
            .image {
                img {
                    min-height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            .grid-x {
                min-height: 100%;
            }
        }
    }

    .global-newsletter {
        .form {
            border-top: 1px solid rgba($white, .1);

            .block.newsletter {
                padding: 0px 50px 0px 50px;
            }
        }
    }

    .global-sub-footer {
        .remove-space-md {
            margin: 0;
        }
    }

    /* Magnific Popup. */
    .mfp-form, .mfp-profile {
        width: calc(100% - .9375rem * 2);
        max-width: 768px;
        padding: 2rem;
    }
}

/**
* Global Styles - Large.
*/
@media screen and (min-width: 1024px) {
    .page-wrapper {
        .breadcrumbs {
            ul {
                li {
                    a, strong {
                        &:hover {
                            &:before, &:after {
                                width: 100%;
                                left: 0;
                            }
                        }
                    }
                }
            }
        }

        .page-main {
            .page-title-wrapper, .event-page-description, .columns {
                width: 66.66667%;
            }

            .page-title-wrapper, .event-page-description {
                 margin: 0px 0px 50px 0px;
            }
        }
    }

    .page-main, .internal-content {
        a:not(.accordion-title):not(.photo):not(.fc-event):not(.exclude):not(.am-show-more) {
            &:hover {
                &:before, &:after {
                    width: 100%;
                    left: 0;
                }
            }
        }
    }

    .categories {
        .category {
            .image {
                img {
                    min-width: 100%;
                }
            }

            &:hover {
                transform: translateY(-.9375rem);
                box-shadow: 3px 3px 12px rgba($black, .3);
            }
        }
    }
    
    .global-newsletter {
        .form {
            border-top: none;
            border-left: 1px solid rgba($white, .1);
            border-right: 1px solid rgba($white, .1);

            .block.newsletter {
                .content {
                    form {
                        .field {
                            width: 66.66666%;
                        }

                        .actions {
                            width: 33.33333%;

                            button {
                                &:hover {
                                    box-shadow: 3px 3px 12px rgba($white, .3) !important;
                                    color: $grey !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    /* Magnific Popup. */
    .mfp-form, .mfp-profile {
        max-width: 1024px;
        padding: 3rem;
    }
}

/**
* Global Styles - XL.
*/
@media screen and (min-width: 1200px) {

}

/**
* Global Styles - XXL.
*/
@media screen and (min-width: 1440px) {

}