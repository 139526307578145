/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _variables - Small.
*/
$paddingSmall: 50px;
$paddingMedium: 75px;
$paddingLarge: 100px;

$marginSmall: 50px;
$marginMedium: 75px;
$marginLarge: 100px;

/**
* _variables - Medium.
*/
@media screen and (min-width: 640px) {
    
}

/**
* _variables - Large.
*/
@media screen and (min-width: 1024px) {
    
}

/**
* _variables - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _variables - XXL.
*/
@media screen and (min-width: 1440px) {
    
}