/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _magento-category-websiteni - Small.
*/
.page-layout-magento-category-websiteni, .catalog-category-view.page-layout-2columns-left, .catalogsearch-result-index {
	.page-wrapper {
		.page-main {
			.page-title-wrapper {
				width: 100%;
				padding: 0rem 0rem 2rem 0rem;
				margin: 0rem 0rem 2rem 0rem;
				border-bottom: 1px solid $lightGrey;

				.page-title {
					font-size: 24px;
					line-height: 24px;
				}
			}

			.columns {
				width: 100%;

				.column, .sidebar-main {
					.toolbar {
						padding: 0;
						margin: 0;
						display: flex;
						order: 1;
						text-align: left;

						.toolbar-amount, .limiter, .sorter {
							display: inline-flex !important;
							align-items: center !important;
							float: none !important;
						}

						.toolbar-amount {
							height: 14px;
							padding: 0px 15px 0px 0px;
							margin: 13px 15px 0px 0px;
							border-right: 1px solid $grey;
							order: 1;
							color: $grey;
							font-family: $roboto;
							font-size: 14px;
							font-weight: 400;
							font-style: normal;
							line-height: 22px;

							.toolbar-number {
								padding: 0rem .25rem 0rem .25rem;
							}
						}

						.limiter {
							order: 2;

							label, span, strong {
								padding: 0;
								background-color: transparent;
								color: $grey;
								font-family: $roboto;
								font-size: 14px;
								font-weight: 400;
								font-style: normal;
								line-height: 40px;
							}

							label {
								padding: 0rem .625rem 0rem 0rem;

								span {
									padding: 0;
								}
							}

							span {
								display: block;
								padding: 0rem 0rem 0rem .625rem;
							}

							strong {
								padding: 0rem .625rem 0rem 0rem;
							}

							.control {
								position: relative;

								&:after {
									width: 14px;
									height: 14px;
									top: 50%;
									right: 19px;
									transform: translateY(-50%) rotate(-90deg);
									position: absolute;
									display: block;
									pointer-events: none;
									content: url('../images/arrow.svg');
								}
							}

							select {
								width: 80px;
								height: 40px;
								padding: 0rem .9375rem 0rem .9375rem;
								margin: 0;
								background-color: transparent;
								border: 1px solid $lightGrey;
								border-radius: 0;
								box-shadow: none;
								color: $grey;
								font-family: $roboto;
								font-size: 14px;
								font-weight: 400;
								font-style: normal;
								line-height: 40px;
								text-align: left;
							}
						}

						.sorter {
							padding: 0;
							margin: 0px 0px 0px auto;
							position: relative;
							order: 3;

							label {
								padding: 0rem .625rem 0rem 0rem;
								display: none;
								color: $grey;
								font-family: $roboto;
								font-size: 14px;
								font-weight: 400;
								font-style: normal;
								line-height: 40px;
							}

							select {
								width: 200px;
								height: 40px;
								padding: 0rem .9375rem 0rem .9375rem;
								margin: 0;
								background-color: transparent;
								border: 1px solid $lightGrey;
								border-radius: 0;
								box-shadow: none;
								color: $grey;
								font-family: $roboto;
								font-size: 14px;
								font-weight: 400;
								font-style: normal;
								line-height: 40px;
								text-align: left;
							}

							a {
								&.sorter-action {
									display: none;
								}
							}

							&:after {
								width: 14px;
								height: 14px;
								top: 50%;
								right: 19px;
								transform: translateY(-50%) rotate(-90deg);
								position: absolute;
								display: block;
								pointer-events: none;
								content: url('../images/arrow.svg');
							}
						}

						.toolbar-amount, .limiter, .modes {
							display: none !important;
						}

						&:last-of-type {
							width: 100%;
							order: 4;

							.pages {
								width: 100%;
								float: none;

								ul {
									width: 100%;
									position: relative;
									display: flex;
									justify-content: center;
									align-items: center;

									.item {
										width: 40px;
										height: 40px;
										padding: 0;
										margin: 0px 2px 0px 2px;
										display: flex;
										align-items: center;
										background-color: transparent;
										border: 1px solid $lightGrey;
										border-radius: 0;
										box-shadow: none;

										strong, a {
											width: 100%;
											margin: 0;
											display: block;
											border: none;
											color: $mediumGrey;
											font-family: $roboto;
											font-size: 14px;
											font-weight: 400;
											font-style: normal;
											line-height: 40px;
											text-align: center;

											&:before, &:after {
												display: none;
											}
										}

										&.current {
											background-color: $grey;
											border: 1px solid $grey;

											strong, a {
												color: $white;
											}
										}

										&.pages-item-previous, &.pages-item-next {
											width: 100px;
											padding: 0rem .9375rem 0rem .9375rem;
											margin: 0rem 1.875rem 0rem 0rem;
											position: relative;

											span {
												width: 100%;
												height: 40px;
												margin: 0;
												position: relative;

												&.label {
													display: none;
												}

												&:before, &:after {
													display: none;
												}
											}
										}

										&.pages-item-next {
											margin: 0rem 0rem 0rem 1.875rem;
										}
									}
								}
							}

							.toolbar-amount, .limiter, .sorter, .modes {
								display: none !important;
							}
						}
					}

					dl {
						&.block {
							padding: 1rem;
							margin: 0rem 0rem 3rem 0rem;
							top: 2rem;
							position: relative;
							display: none;
							border: 1px solid $lightGrey;
							box-shadow: 3px 3px 12px rgba($black, .1);
							clear: both;

							.title {
								margin: 0rem 0rem .9375rem 0rem;
								color: $grey;
								font-family: $roboto;
								font-size: 18px;
								font-weight: 700;
								font-style: normal;
								line-height: 18px;
								text-transform: uppercase;
							}

							.item {
								margin: 0rem 0rem .625rem 0rem;
								position: relative;

								a {
									padding: 0rem 0rem 0rem .9375rem !important;
									color: $grey;
									font-family: $roboto;
									font-size: 14px;
									font-weight: 400;
									font-style: normal;
									line-height: 22px;
								}

								&:before {
									width: 6px;
									height: 6px;
									border: 1px solid $grey;
									border-radius: 100%;
									top: .625rem;
									left: 0;
									position: absolute;
									content: '';
								}

								&:last-of-type {
									margin: 0;
								}
							}
						}
					}

					.filter {
						padding: 2rem 0rem 0rem 0rem;
						margin: 0;
						order: 2;

						.filter-title {
							padding: 0px 0px 25px 0px !important;
							margin: 0px 0px 40px 0px !important;
							border-bottom: 1px solid $lightGrey !important;
							color: $grey !important;
							font-family: $roboto !important;
							font-size: 16px !important;
							font-weight: 700 !important;
							font-style: normal !important;
							line-height: 40px !important;

							strong {
								font-size: 16px !important;
								font-weight: 700 !important;
								line-height: 40px !important;
							}
						}

						.filter-content {
							.filter-subtitle {
								display: none;
							}

							.filter-current {
								.filter-current-subtitle {
									margin: 0px 0px 20px 0px;
									color: $grey;
									font-family: $roboto;
								}

								ol {
									margin: 0;

									li {
										padding: 0px !important;
										margin: 0px !important;
										border-top: 1px solid $lightGrey;

										.filter-label {
											margin: 0px 0px 5px 0px;
										}

										.filter-value {
											padding: 0px 0px 0px 25px;
											margin: 0px 0px 25px 0px;
											position: relative;
											display: block;

											&:before {
												width: 20px;
												height: 20px;
												top: 3px;
												left: 0;
												position: absolute;
												background-color: $yellow;
												border: 1px solid $lightGrey;
												border-radius: 3px;
												content: '';
											}
										}

										&:first-of-type {
											margin: 0 !important;
										}

										&:last-of-type {
											padding: 0px 0px 10px 0px !important;
											border-bottom: 0px solid $lightGrey;
										}
									}
								}
							}

							.filter-actions {
								padding: 0px 0px 25px 0px;
								margin: 25px 0px 25px 0px;
								display: flex;
								border-bottom: 1px solid $lightGrey;

								a {
									width: 200px;
								    height: 45px;
								    padding: 0rem 2rem 0rem 2rem;
								    position: relative;
								    display: inline-block;
								    background-color: $yellow;
								    border-radius: 5px;
								    box-shadow: 3px 3px 12px rgba($black, 0);
								    color: $grey !important;
								    font-family: $roboto;
								    font-size: 16px;
								    font-weight: 700;
								    font-style: normal;
								    line-height: 45px;
								    text-align: center;
								    text-decoration: none !important;
								    overflow: hidden;
								    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

								    span {
								    	pointer-events: none;

								        &.ripple {
								            transform: scale(.25);
								            position: absolute;
								            background-color: rgba($black, .25);
								            border-radius: 100%;
								            opacity: 0;
								            pointer-events: none;
								            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
								        }

								        &:before, &:after {
								        	display: none;
								        }
								    }

								    &:focus {
								        color: $grey;
								    }
								}
							}

							.filter-options {
								.filter-options-title {
									padding: 0;
									margin: 0px 0px 25px 0px;
									position: relative;
									color: $grey;
									font-family: $roboto;
									font-size: 16px;
									font-weight: 700;
									font-style: normal;
									line-height: 16px;
								}

								.filter-options-content {
									margin: 0;
									a.am-show-more{
										margin: -25px 0 25px;
									}
									ol {
										padding: 0px 0px 25px 0px;
										margin: 0px 0px 25px 0px;
										border-bottom: 1px solid $lightGrey;

										li {
											margin: 0;

											a {
												padding: 0px 0px 0px 30px;
												position: relative;
												display: flex;
												color: $grey;
												font-size: 14px;
												font-weight: 400;
												line-height: 27px;
												text-decoration: none !important;

												span {
													&.price {
														&:nth-of-type(1) {
															margin: 0rem .25rem 0rem 0rem;
														}

														&:nth-of-type(2) {
															margin: 0rem 0rem 0rem .25rem;
														}
													}

													&.count {
														display: none;
													}
												}

												&:before {
													width: 20px;
													height: 20px;
													top: 3px;
													left: 0;
													position: absolute;
													display: block !important;
													background-color: transparent;
													border: 1px solid $lightGrey;
													border-radius: 3px;
													content: '';
													transition: .4s cubic-bezier(0.51, 0.18, 0, 0.88);
												}

												&:hover, &.am_shopby_link_selected {
													&:before {
														background-color: $yellow;
													}
												}
											}
										}
									}

									&:last-of-type {
										ol {
											margin: 0;
										}
									}
								}
							}
						}
					}

					.products-grid {
						width: 100%;
						order: 3;
						float: none;
					}
				}

				.sidebar-additional {
					display: none;
				}
			}

			.category-view {
				display: none;
			}
		}
	}

	&.page-products {
		.page-wrapper {
			.page-main {
				.columns {
					.sidebar-main {
						.filter {
							float: none;
						}
					}
				}
			}
		}
	}

	&.cms-brand-new-products {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						.block {
							margin: 0;

							.block-title {
								display: none;
							}

							.block-content {
								.products-grid {
									width: 100%;
									margin: 2rem 0rem 0rem 0rem;
									float: none;

									.product-items {
										.product-item {
											.product-item-info {
												.product-item-details {
													.product-item-actions {
														margin: 2rem 0rem 0rem 0rem !important;

														.actions-primary {
															display: flex;
															justify-content: center;

															button {
																width: auto;
															    height: 45px;
															    padding: 0rem 2rem 0rem 2rem;
															    position: relative;
															    display: inline-block;
															    background-color: $yellow;
															    border: none;
															    border-radius: 5px;
															    box-shadow: 3px 3px 12px rgba($black, 0);
															    color: $grey !important;
															    font-family: $roboto;
															    font-size: 16px;
															    font-weight: 700;
															    font-style: normal;
															    line-height: 45px;
															    text-align: center;
															    text-decoration: none !important;
															    overflow: hidden;
															    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

															    span {
															    	pointer-events: none;
															    	
															        &.ripple {
															            transform: scale(.25);
															            position: absolute;
															            background-color: rgba($black, .25);
															            border-radius: 100%;
															            opacity: 0;
															            pointer-events: none;
															            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
															        }
															    }

															    &:focus {
															        color: $grey;
															    }
															}

															.stock {
																&.unavailable {
																	span {
																		width: auto !important;
																	    height: 40px;
																	    padding: 0rem 1rem 0rem 1rem !important;
																	    margin: 0 !important;
																	    background-color: transparent !important;
																	    border: none !important;
																	    border-radius: 5px;
																	    box-shadow: none;
																	    // color: $ !important;
																	    // font-family: $ !important;
																	    font-size: 14px !important;
																	    font-weight: 400 !important;
																	    font-style: normal;
																	    line-height: 40px;
																	    text-transform: capitalize;
																	}
																}
															}
														}

														.actions-secondary {
															display: none !important;
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.category-products.page-layout-magento-category-websiteni {
	.page-wrapper {
		.page-main {
			.columns {
				.column {
					.filter {
						.filter-content {
							.filter-options {
								.filter-options-title, .filter-options-content {
									display: none;

									&:first-of-type {
										display: block;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.catalog-category-view.page-layout-2columns-left, .catalogsearch-result-index {
	.page-wrapper {
		.page-main {
			.columns {
				.column {
					width: 75%;
					float: right;

					.toolbar, .products-grid {
						width: 100% !important;
					}

					.toolbar {
						.sorter {
							margin: 0px 0px 25px 0px !important;
						}
					}
				}

				.sidebar {
					&.sidebar-main {
						width: 100% !important;
						padding: 0 !important;
						float: none !important;

						.filter {
							width: 100% !important;
							padding: 0 !important;
						}
					}

					&.sidebar-additional {
						display: none;
					}
				}
			}
		}
	}
}


// AMASTY LAYERED NAV CUSTOM STYLES

.filter-options{
	.items{
		list-style: none;
		margin: 0 0 2rem 0;
		li{
			line-height: 22px;
			// input{
			// 	margin: 0 0 .5rem;
			// }
			a{
				padding: 0px 0px 0px 30px;
		        position: relative;
		        display: flex;
		        font-size: 14px;
		        font-weight: 400;
		        line-height: 27px;
		        text-decoration: none !important;

		        .count{
		        	display: none;
		        }
			}
		}
		.label{
			background:transparent!important;
			color: #1D1D1B!important;
		}
		input[type="checkbox"], input[type="radio"]{
			display: none;
		}
	}
	.items.am-filter-items-attr_category_ids{
		margin: 0 0 2rem 0;

		li a{
			padding-left:0px;
			line-height: 22px;
			cursor: pointer;
			.label{
				font-weight: 400!important;
			}
		} 
	}
}
.amshopby-filter-current.filter-current{
	li.item.amshopby-item{
		border-top:0px!important;

		&:before {
		    content: "x";
		    font-weight: 700;
		    color: #ccc;
		    font-size: 14px;
		    display: inline-block;
		    position: absolute;
		    left: 0;

		}
	}
	.amshopby-filter-name{
		color: #363632;
	    margin-right:5px;
	}
	// li.amshopby-button{
	// 	border:0px !important;
	// 	padding:0px!important;
	// }
}











/**
* _magento-category-websiteni - Mobile Only.
*/
@media screen and (max-width: 639px) {
    .page-layout-magento-category-websiteni, .catalog-category-view.page-layout-2columns-left, .catalogsearch-result-index {
    	.page-wrapper {
    		.page-main {
    			.page-title-wrapper {
    				margin: 2rem 0rem 2rem 0rem;
    			}

    			.columns {
    				.column, .sidebar-main {
    					.toolbar {
    						.sorter {
    							width: 100%;
    							margin: 0;

    							.sorter-label {
    								width: 80px;
    								display: block;
    								font-size: 16px;
    								font-weight: 700;
    							}

    							select {
    								width: calc(100% - 80px);
    							}
    						}

    						&:last-of-type {
    							margin-bottom: -1px;
    							top: -1px;
    							position: relative;
    						}
    					}

    					.filter {
    						.filter-title {
    							margin: 0px 0px 25px 0px !important;
    							position: relative;
    							line-height: 16px !important;

    							strong {
    								position: relative;
    								line-height: 16px !important;

    								&:after {
    									padding: 0rem 0rem 0rem .625rem;
    									font-size: 12px;
    									font-weight: 400;
    									content: 'Click here for filters.';
    								}
    							}

    							&:after {
    								top: 0;
    								right: 0;
    								position: absolute;
    								display: block;
    								content: '+';
    							}
    						}

    						.filter-content {
    							margin: 0px 0px 25px 0px !important;
    							display: none;

    							&.visible {
    								display: block;
    							}
    						}
    					}
    				}
    			}
    		}
    	}
    }
}

/**
* _magento-category-websiteni - Medium.
*/
@media screen and (min-width: 640px) {
    .page-layout-magento-category-websiteni, .catalog-category-view.page-layout-2columns-left, .catalogsearch-result-index {
		.page-wrapper {
			padding-bottom: 62.5px;

			.page-main {
				.page-title-wrapper {
					.page-title {
						font-size: 27px;
						line-height: 27px;
					}
				}

				.columns {
					.column, .sidebar-main {
						.toolbar {
							.toolbar-amount, .limiter {
								display: inline-flex !important;
							}

							&:last-of-type {
								padding-top: 22px;
							}
						}

						.filter {
							width: calc(25% - 8.33333%);
							float: left;
						}

						.products-grid {
							width: 75%;
							margin: 45px 0px 0px 0px;
							float: right;
						}
					}
				}
			}
		}
	}

	.catalog-category-view.page-layout-2columns-left, .catalogsearch-result-index {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						.toolbar {
							.sorter {
								    margin: 0px 0px 0px auto !important;
							}
						}

						.products-grid {
							margin: 25px 0px 0px 0px !important;
						}
					}

					.sidebar {
						&.sidebar-main {
							width: calc(25% - 8.33333%) !important;
							float: left !important;
						}
					}
				}
			}
		}
	}
}

/**
* _magento-category-websiteni - Large.
*/
@media screen and (min-width: 1024px) {
    .page-layout-magento-category-websiteni, .catalog-category-view.page-layout-2columns-left, .catalogsearch-result-index {
		.page-wrapper {
			padding-bottom: 75px;

			.page-main {
				.page-title-wrapper {
					.page-title {
						font-size: 30px;
						line-height: 30px;
					}
				}

				.columns {
					.column, .sidebar-main {
						.toolbar {
							width: 75%;
							float: right;
						}

						.filter {
							padding: 0;

							.filter-title {
								strong {
									top: -1px;
									position: relative;
								}
							}

							.filter-content {
								.filter-actions {
									a {
										&:hover {
								            box-shadow: 3px 3px 12px rgba($black, .3);
								            color: $grey;
								        }
								    }
								}
							}
						}

						.products-grid {
							margin: 25px 0px 0px 0px;
						}
					}
				}
			}
		}
	}
}

/**
* _magento-category-websiteni - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _magento-category-websiteni - XXL.
*/
@media screen and (min-width: 1440px) {
    
}