/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _cookie-policy - Small.
*/
.cookie-policy {
	width: calc(100% - .625rem * 2);
	max-width: 480px;
	padding: .625rem;
	left: .625rem;
	bottom: .625rem;
	position: fixed;
	background-color: $yellow;
	box-shadow: 3px 3px 12px rgba($black, .5);
	z-index: 99999;

	img {
		width: 100%;
		height: auto;
		display: block;
	}

	p {
		margin: 0rem 0rem 1rem 0rem;
		color: $grey;

		a {
			display: inline-block;
			color: $grey !important;
			font-weight: 700;
			text-decoration: line-through;

			&:before {
				background-color: rgba($grey, .25);
			}

			&:after {
				background-color: rgba($grey, 1);
			}
		}

		&:first-of-type {
			font-size: 16px;
			font-weight: 700;
			line-height: 24px;
		}

		&:last-of-type {
			margin: 0;
		}
	}

	button {
		padding: .625rem 1.25rem .625rem 1.25rem;
		margin: 1.5rem 0rem 0rem 0rem;
		display: inline-block;
		background-color: rgba($grey, .05) !important;
		border: none !important;
		border-radius: 0 !important;
		box-shadow: none !important;
		color: $grey !important;
		font-family: $roboto;
	    font-size: 16px;
	    font-weight: 700;
	    font-style: normal;
	    line-height: 24px;
		cursor: pointer;
	}
}

/**
* _cookie-policy - Medium.
*/
@media screen and (min-width: 640px) {
    .cookie-policy {
    	width: calc(100% - .9375rem * 2);
    	padding: .9375rem;
    	left: .9375rem;
    	bottom: .9375rem;
    }
}

/**
* _cookie-policy - Large.
*/
@media screen and (min-width: 1024px) {
    
}

/**
* _cookie-policy - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _cookie-policy - XXL.
*/
@media screen and (min-width: 1440px) {
    
}