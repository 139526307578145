/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _colours - Small.
*/
$white: 			#FEFEFE;
$offWhite: 			#F2F2F2;
$lightGrey: 		#E5E5E5;
$mediumGrey: 		#CCCCCC;
$darkGrey: 			#262626;
$black: 			#000000;

$yellow:			#FFD203;
$grey:				#1D1D1B;
$greyAlt:			#5E626D;
$greyAltAlt:		#0F0F0F;

$extraGrey:			#616161;

/**
* _colours - Medium.
*/
@media screen and (min-width: 640px) {
    
}

/**
* _colours - Large.
*/
@media screen and (min-width: 1024px) {
    
}

/**
* _colours - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _colours - XXL.
*/
@media screen and (min-width: 1440px) {
    
}