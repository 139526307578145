/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _contact - Small.
*/
.page-layout-contact {
	.page-wrapper {
		padding-bottom: 0;

		.page-main {
			.columns {
				width: 100%;

				.column {
					padding-bottom: 50px;

					.icon {
						width: 50px;
						height: 50px;
						margin: 0rem .9375rem 0rem 0rem;
						background-color: $grey;
						border-radius: 100%;

						img {
							width: 24px;
							height: 24px;
						}
					}

					p {
						a {
							&:before, &:after {
								top: 100%;
							}
						}
					}

					hr {
						width: 100%;
						max-width: none;
						margin: 25px 0px 25px 0px;
						border-top: 1px solid $lightGrey;
					}

					.line {
						width: 1px;
						height: 100%;
						top: 0;
						left: 41.66667%;
						background-color: $lightGrey;
					}
				}
			}
		}

		.contact-map {
			margin-bottom: -5px;
			order: 3;
		}
	}
}

/**
* _contact - Medium.
*/
@media screen and (min-width: 640px) {
	
}

/**
* _contact - Large.
*/
@media screen and (min-width: 1024px) {
	
}

/**
* _contact - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _contact - XXL.
*/
@media screen and (min-width: 1440px) {

}