/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _become-a-stockist - Small.
*/
.page-layout-become-a-stockist {
	.pc_form_widget_view_0 {
		margin: 3rem 0rem 0rem 0rem;

		.pccf-customer-data-scope {
			.pccf {
				padding: .9375rem;
				background-color: rgba($offWhite, .4);

				.form-view {
					form {
						fieldset {
							.row {
								width: 100%;
								display: flex;

								.col-md-2, .col-md-8 {
									text-align: left !important;

									h4 {
										margin: 2rem 0rem 1.5rem 0rem !important;
									}

									h6 {
										margin: .5rem 0rem 1.5rem 0rem !important;
									}

									p {
										font-weight: 400;
									}
								}

								&:first-of-type {
									.col-md-2, .col-md-8 {
										h4 {
											margin: calc(1.5rem - .9375rem) 0rem 1.5rem 0rem !important;
										}
									}
								}
							}

							.form-group {
								&.multi-check {
									.checkbox {
										label {
											font-weight: 400;

											input {
												top: 2px;
												position: relative;
											}
										}

										&:last-of-type {
											label {
												margin: 0 !important;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
* _become-a-stockist - Medium.
*/
@media screen and (min-width: 640px) {
	
}

/**
* _become-a-stockist - Large.
*/
@media screen and (min-width: 1024px) {
	.page-layout-become-a-stockist {
		.pc_form_widget_view_0 {
			.pccf-customer-data-scope {
				.pccf {
					.form-view {
						form {
							fieldset {
								display: flex;
								flex-direction: column;
								flex-flow: row wrap;
								justify-content: space-between;

								.form-group {
									width: calc(50% - .9375rem / 2);

									&.full-width, &.dropdown, &.textarea, &.gdpr, &.submit {
										width: 100% !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
* _become-a-stockist - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _become-a-stockist - XXL.
*/
@media screen and (min-width: 1440px) {

}