/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _warranty-and-repairs - Small.
*/
.page-layout-warranty-and-repairs, .page-layout-product-registration-form, .page-layout-troubleshoot-form, .page-layout-returns-form {
	.page-wrapper {
		padding-bottom: 0;

		.page-main {
			.page-title-wrapper {
				width: 100%;
			    padding: 0rem 0rem 2rem 0rem;
			    margin: 2rem 0rem 2rem 0rem;
			    border-bottom: 1px solid $lightGrey;

			    .page-title {
			    	font-size: 24px;
    				line-height: 24px;
			    }
			}
		}

		.warranties {
			padding-top: 2rem;
			order: 3;

			.warranty {
				h5 {
					margin: 2rem 0rem 1rem 0rem;
				}

				p {
					&:first-of-type {
						text-align: center;
					}
				}
			}
		}

		.register-your-purchase {
			order: 4;

			.target {
				h4 {
					margin: 0rem 0rem 2rem 0rem !important;
				}
			}

			hr {
				width: 100%;
                max-width: 1320px;
				margin: 2rem 0rem 2rem 0rem;
				border-top: 1px solid $lightGrey;
			}

			h5 {
				margin: 0rem 0rem 2rem 0rem;
			}
		}

		.warranty-repairs {
			order: 5;

			hr {
				width: 100%;
                max-width: 1320px;
				margin: 2rem 0rem 2rem 0rem;
				border-top: 1px solid $lightGrey;
			}

			h5 {
				margin: 0rem 0rem 2rem 0rem;
			}

			.target {
				&:last-of-type {
					p {
						&:last-of-type {
							margin: 0;
						}
					}
				}
			}
		}

		.need-assistance {
			order: 6;

			hr {
				width: 100%;
                max-width: 1320px;
				margin: 2rem 0rem 2rem 0rem;
				border-top: 1px solid $lightGrey;
			}

			.target {
				h5 {
					margin: 0rem 0rem 1rem 0rem;

					a {
						color: $grey;
						text-decoration: none;
					}
				}
			}
		}

        .fake-sidebar {
            order: 7;
            z-index: 2;

            hr {
                width: 100%;
                max-width: 1320px;
                margin: 2rem 0rem 2rem 0rem;
                border-top: 1px solid $lightGrey;
            }

            ul {
                margin: 0rem 0rem 0rem 1.25rem;
                list-style: circle;

                li {
                    margin: 0;

                    a {
                        color: $grey;
                        font-family: $roboto;
                        font-size: 15px;
                        font-weight: 700;
                        font-style: normal;
                        line-height: 30px;
                        text-decoration: none;
                    }
                }
            }
        }
	}
}

.page-layout-product-registration-form, .page-layout-troubleshoot-form, .page-layout-returns-form {
    .page-wrapper {
        .page-main {
            .columns {
                .column {
                    padding: .625rem;
                    background-color: $offWhite;
                }
            }
        }
    }

    .pc_form_widget_view_0 {
        .pccf-customer-data-scope {
            .pccf {
                .form-view {
                    form {
                        fieldset {
                            .row {
                                .col-md-8 {
                                    text-align: left;

                                    p {
                                        margin: 1.875rem 0rem 0rem 0rem !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.page-layout-product-registration-form, .page-layout-returns-form {
    .pc_form_widget_view_0 {
        .pccf-customer-data-scope {
            .pccf {
                .form-view {
                    form {
                        fieldset {
                            .row {
                                width: 100%;

                                .col-md-2, .col-md-8 {
                                    h4 {
                                        margin: 2rem 0rem 1.5rem 0rem !important;
                                    }
                                }

                                &:first-of-type {
                                    .col-md-2, .col-md-8 {
                                        h4 {
                                            margin: calc(1.5rem - .9375rem) 0rem 1.5rem 0rem !important;
                                        }
                                    }
                                }
                            }

                            .form-group {
                                &.gdpr {
                                    margin-top: 2rem !important;
                                }

                                &.radio {
                                    .radio {
                                        label {
                                            display: flex;

                                            input {
                                                width: auto;
                                                height: auto;
                                                margin: 6px 9px 0px 0px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.page-layout-returns-form {
    .pc_form_widget_view_0 {
        .pccf-customer-data-scope {
            .pccf {
                .form-view {
                    form {
                        fieldset {
                            .row {
                                &.important {
                                    p {
                                        margin-top: 0 !important;
                                    }
                                }

                                &.not-important {
                                    p {
                                        font-weight: 400;
                                    }
                                }

                                &.margin {
                                    p {
                                        margin: 0rem 0rem 2rem 0rem !important;
                                    }

                                    &.small-margin {
                                        p {
                                            margin: 0rem 0rem 1rem 0rem !important;
                                        }
                                    }
                                }
                            }

                            .form-group {
                                &.gdpr {
                                    margin-top: 0rem !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/**
* _warranty-and-repairs - Medium.
*/
@media screen and (min-width: 640px) {
	.page-layout-warranty-and-repairs, .page-layout-product-registration-form, .page-layout-troubleshoot-form, .page-layout-returns-form {
		.page-wrapper {
            position: relative;

			.page-main {
				.page-title-wrapper {
					margin: 0rem 0rem 2rem 0rem;

					.page-title {
						font-size: 27px;
    					line-height: 27px;
    				}
    			}

    			.columns {
    				width: 66.66667%;
    			}
    		}

    		.warranties {
    			.grid-x {
    				width: 66.66667%;
    			}
    		}

    		.register-your-purchase {
    			.target {
    				justify-content: space-between;

    				h4 {
    					margin: 0 !important;
    				}

    				p {
    					margin: 0;
    				}
    			}

    			.grid-x {
    				width: 66.66667%;
    			}
    		}

    		.warranty-repairs {
    			.target {
    				padding-left: 100px;
    				position: relative;

    				&:before {
    					top: 0;
    					left: 0;
    					position: absolute;
    					color: $grey;
    					font-family: $roboto;
					    font-size: 20px;
					    font-weight: 700;
					    font-style: normal;
					    line-height: 20px;
    					content: '';
    				}

    				&:nth-of-type(1) {
    					&:before {
    						content: 'Step 1';
    					}
    				}

    				&:nth-of-type(2) {
    					padding-top: 2rem;
    					margin: 2rem 0rem 0rem 0rem;
    					border-top: 1px solid $lightGrey;

    					&:before {
    						top: 2rem;
    						content: 'Step 2';
    					}
    				}

    				&:nth-of-type(3) {
    					padding-top: 2rem;
    					margin: 2rem 0rem 0rem 0rem;
    					border-top: 1px solid $lightGrey;

    					&:before {
    						top: 2rem;
    						content: 'Step 3';
    					}
    				}

    				&:nth-of-type(4) {
    					padding-top: 2rem;
    					margin: 2rem 0rem 0rem 0rem;
    					border-top: 1px solid $lightGrey;

    					&:before {
    						top: 2rem;
    						content: 'Step 4';
    					}
    				}
    			}

    			.grid-x {
    				width: 66.66667%;
    			}
    		}

    		.need-assistance {
    			.target {
    				padding-left: 75px;
    				position: relative;

    				h5 {
    					margin: 0;
    					line-height: 50px;

    					&:nth-of-type(1) {
    						margin-right: 25px;
    					}
    				}

    				&:before {
    					width: 50px;
    					height: 50px;
    					top: 0;
    					left: 0;
    					position: absolute;
    					background-color: $yellow;
    					border: none;
    					border-radius: 100%;
    					line-height: 63px;
    					text-align: center;
    					content: url('../images/call-us-alt.svg');
    				}
    			}
    		}

            .fake-sidebar {
                top: 101px;
                right: .9375rem;
                position: absolute;

                ul {
                    margin-top: 4rem;
                }

                hr {
                    display: none;
                }
            }
    	}
    }

    .page-layout-product-registration-form, .page-layout-troubleshoot-form, .page-layout-returns-form {
        .page-wrapper {
            .page-main {
                .columns {
                    .column {
                        padding: .9375rem;
                    }
                }
            }
        }
    }
}

/**
* _warranty-and-repairs - Large.
*/
@media screen and (min-width: 1024px) {
	.page-layout-warranty-and-repairs, .page-layout-product-registration-form, .page-layout-troubleshoot-form, .page-layout-returns-form {
		.page-wrapper {
			.page-main {
				.page-title-wrapper {
					.page-title {
						font-size: 30px;
    					line-height: 30px;
    				}
    			}
    		}

    		.warranty-repairs {
    			.target {
    				padding-left: 150px;
    			}
    		}

            .fake-sidebar {
                top: 106px;
            }
    	}
    }

    .page-layout-product-registration-form, .page-layout-troubleshoot-form, .page-layout-returns-form {
        .page-wrapper {
            .page-main {
                .columns {
                    .column {
                        padding: 1.875rem;
                    }
                }
            }
        }
    }

    .page-layout-product-registration-form, .page-layout-returns-form {
        .pc_form_widget_view_0 {
            .pccf-customer-data-scope {
                .pccf {
                    .form-view {
                        form {
                            fieldset {
                                display: flex;
                                flex-direction: column;
                                flex-flow: row wrap;
                                justify-content: space-between;

                                .form-group {
                                    width: calc(50% - .9375rem / 2);

                                    &.full-width, &.dropdown, &.textarea, &.gdpr, &.submit {
                                        width: 100% !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/**
* _warranty-and-repairs - XL.
*/
@media screen and (min-width: 1200px) {
    .page-layout-warranty-and-repairs, .page-layout-product-registration-form, .page-layout-troubleshoot-form, .page-layout-returns-form {
		.page-wrapper {
    		.warranty-repairs {
    			.target {
    				padding-left: 200px;
    			}
    		}

            .fake-sidebar {
                left: 75%;
                right: auto;
            }
    	}
    }

    .page-layout-product-registration-form, .page-layout-troubleshoot-form, .page-layout-returns-form {
        .page-wrapper {
            .page-main {
                .columns {
                    .column {
                        padding: 50px;
                    }
                }
            }
        }
    }
}

/**
* _warranty-and-repairs - XXL.
*/
@media screen and (min-width: 1440px) {

}

/**
* _warranty-and-repairs - XXXL.
*/
@media screen and (min-width: 1920px) {
    .page-layout-warranty-and-repairs, .page-layout-product-registration-form, .page-layout-troubleshoot-form, .page-layout-returns-form {
        .page-wrapper {
            .fake-sidebar {
                left: 72.75%;
            }
        }
    }
}