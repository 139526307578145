/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _search - Small.
*/
.catalogsearch-advanced-index {
	.page-wrapper {
		.page-main {
			.columns {
				.column {
					form {
						fieldset {
							legend {
								margin: 0rem 0rem 2rem 0rem !important;
							}

							.field {
								&.price {
									.control {
										.range {
											.field {
												margin: 0 !important;

												.control {
													padding: 0 !important;

													&:after {
														width: 7px;
														top: 8px;
														right: calc(-1.25rem - 3px);
													}
												}

												&.with-addon {
													label {
														display: none;
													}
												}

												&:first-of-type {
													width: calc(50% - 1.25rem) !important;
													float: left !important;
												}

												&:last-of-type {
													width: calc(50% - 1.25rem) !important;
													float: right !important;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
* _search - Medium.
*/
@media screen and (min-width: 640px) {
    
}

/**
* _search - Large.
*/
@media screen and (min-width: 1024px) {
    
}

/**
* _search - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _search - XXL.
*/
@media screen and (min-width: 1440px) {
    
}