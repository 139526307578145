/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _magento-cart-websiteni - Small.
*/

.checkout-cart-index .page-wrapper .page-main .columns {
    width: 100% !important;
}
.checkout-cart-index .page-wrapper {
    padding-top: 40px;
}
.message.success{
	color: #fff !important;
	padding-left: 40px !important;
}
.message.success > :first-child::before {
    color: #fff !important;
    margin: -3px 0 0 !important;
}
.checkout-cart-index {
	#block-shipping{
		display: none!important;
	}
	.page-wrapper {
		.page-main {
			.columns {
				.column {
					.cart-container {
						display: flex;
						justify-content: flex-end;
						flex-flow: row wrap;

						.cart-summary {
							width: 100%;
							max-width: 512px;
							padding: .625rem;
							margin: 0;
							top: 0 !important;
							order: 2;
							background-color: rgba($lightGrey, .2);
							border-radius: 5px;

							span {
								&.label {
									width: 100% !important;
									height: auto;
									padding: 0 !important;
									margin: 0px 0px 5px 0px !important;
									background-color: transparent;
									text-align: left !important;
									float: none !important;
									color:#1D1D1B!important;

									span {
										color: $grey;
										font-family: $roboto;
										font-size: 14px;
										font-weight: 700;
										font-style: normal;
										line-height: 26px;
									}

									&:after {
										display: none;
									}
								}
							}

							.summary {
								margin: 0rem 0rem 2rem 0rem;
								color: $grey;
								font-family: $roboto;
								font-size: 20px;
								font-weight: 700;
								font-style: normal;
								line-height: 20px;
							}

							.shipping {
								padding: 0 !important;
								margin: 0 !important;
								// display: none !important;

								.title {
									padding: 2rem 4rem 2rem 0rem;
									margin: 2rem 0rem 0rem 0rem;
									border-top: 1px solid $lightGrey;

									strong {
										color: $grey;
										font-family: $roboto;
										font-size: 20px;
										font-weight: 700;
										font-style: normal;
										line-height: 20px;
									}

									&:after {
										top: 50%;
										right: 0;
										transform: translateY(-50%);
										position: absolute;
										color: $grey;
										font-size: 40px;
									}
								}

								.content {
									form {
										.fieldset {
											margin: 0;

											.field {
												margin: 0;

												label {
													width: 100% !important;
													height: auto;
													padding: 0 !important;
													margin: 0px 0px 5px 0px !important;
													background-color: transparent;
													text-align: left !important;
													float: none !important;

													span {
														color: $grey;
														font-family: $roboto;
														font-size: 14px;
														font-weight: 700;
														font-style: normal;
														line-height: 26px;
													}

													&:after {
														display: none;
													}
												}

												.control {
													width: 100%;

													input, select {
														width: 100%;
														height: 40px;
														padding: 0rem 1rem 0rem 1rem;
														margin: 0;
														background-color: $white;
														border: 1px solid $lightGrey;
														border-radius: 5px;
														box-shadow: none;
														color: $grey;
														font-family: $roboto;
														font-size: 14px;
														font-weight: 400;
														font-style: normal;
														line-height: 38px;
														z-index: 0 !important;
													}

													@include placeholder {
														color: $mediumGrey;
													}
												}

												&.note {
													margin: 0rem 0rem 1.5rem 0rem !important;
												}
											}

											.actions-toolbar {
												.primary {
													button {
														width: 200px;
													    height: 45px;
													    padding: 0rem 2rem 0rem 2rem;
													    position: relative;
													    display: inline-block;
													    background-color: $yellow;
													    border-radius: 5px;
													    box-shadow: 3px 3px 12px rgba($black, 0);
													    color: $grey !important;
													    font-family: $roboto;
													    font-size: 16px;
													    font-weight: 700;
													    font-style: normal;
													    line-height: 45px;
													    text-align: center;
													    text-decoration: none !important;
													    overflow: hidden;
													    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

													    span {
													    	pointer-events: none;

													        &.ripple {
													            transform: scale(.25);
													            position: absolute;
													            background-color: rgba($black, .25);
													            border-radius: 100%;
													            opacity: 0;
													            pointer-events: none;
													            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
													        }
													    }

													    &:focus {
													        color: $grey;
													    }
													}
												}
											}
										}

										&#co-shipping-method-form {
											margin: 1rem 0rem 0rem 0rem;

											.fieldset {
												dl {
													text-align: left !important;
													
													dt {
														margin: 0px 0px 5px 0px;

														span {
															color: $grey;
														    font-family: $roboto;
															font-size: 14px;
															font-weight: 700;
															font-style: normal;
															line-height: 26px;
														}
													}

													dd {
														margin: 0;

														.field {
															margin: 0 !important;

															input {
																width: auto;
																height: auto;
																padding: 0rem 1rem 0rem 1rem;
																margin: 0rem .25rem 2rem 0rem;
																top: 2px;
																position: relative;
																// background-color: $;
																border: none;
																border-radius: 5px;
																box-shadow: none;
																// color: $;
																// font-family: $;
																font-size: 14px;
																font-weight: 400;
																font-style: normal;
																line-height: 40px;
															}

															@include placeholder {
																// color: $;
															}

															label {
																width: 100% !important;
																height: auto;
																padding: 0 !important;
																margin: 0px 0px 5px 0px !important;
																background-color: transparent;
																color: $grey;
																font-family: $roboto;
																font-size: 14px;
																font-weight: 700;
																font-style: normal;
																line-height: 26px;
																text-align: left !important;
																float: none !important;

																&:after {
																	display: none;
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}

							.cart-totals {
								padding: 2rem 0rem 0rem 0rem;
								border-top: 1px solid $lightGrey;

								.table-wrapper {
									table {
										margin: 0rem 0rem 2rem 0rem;

										tbody {
											background-color: transparent;
											border: none;

											tr {
												background-color: transparent !important;

												th, td {
													padding: .625rem 0rem .625rem 0rem;
													color: $grey;
													text-align: left;
												}

												th {
													font-family: $roboto;
													font-size: 14px;
													font-weight: 700;
													font-style: normal;
													line-height: 20px;

													strong {
														font-weight: 700;
													}
												}

												td {
													font-family: $roboto;
													font-size: 16px;
													font-weight: 400;
													font-style: normal;
													line-height: 20px;
												}

												&:first-of-type {
													th, td {
														padding: 0rem 0rem .625rem 0rem;
													}
												}

												&:last-of-type {
													th, td {
														padding: .625rem 0rem 0rem 0rem;
													}
												}
											}
										}
									}
								}
							}

							.discount {
								.title {
									padding: 2rem 4rem 2rem 0rem;
									margin: 2rem 0rem 2rem 0rem;
									border-top: 1px solid $lightGrey;

									strong {
										color: $grey;
										font-family: $roboto;
										font-size: 20px;
										font-weight: 700;
										font-style: normal;
										line-height: 20px;
									}

									&:after {
										top: 50%;
										right: 0;
										transform: translateY(-50%);
										position: absolute;
										color: $grey;
										font-size: 40px;
									}
								}

								.content {
									form {
										.fieldset {
											margin: -2rem 0rem 2rem 0rem;
											display: flex;
											flex-flow: row wrap;

											.field {
												width: 50%;
												margin: 0;

												label {
													width: 100% !important;
													height: auto;
													padding: 0 !important;
													margin: 0px 0px 5px 0px !important;
													background-color: transparent;
													text-align: left !important;
													float: none !important;

													span {
														color: $grey;
														font-family: $roboto;
														font-size: 14px;
														font-weight: 700;
														font-style: normal;
														line-height: 26px;
														text-transform: capitalize;
													}

													&:after {
														display: none;
													}
												}

												.control {
													width: 100%;

													input {
														width: 100%;
														height: 40px;
														padding: 0rem 1rem 0rem 1rem;
														margin: 0;
														background-color: $white;
														border: 1px solid $lightGrey;
														border-radius: 5px;
														box-shadow: none;
														color: $grey;
														font-family: $roboto;
														font-size: 14px;
														font-weight: 400;
														font-style: normal;
														line-height: 38px;
														z-index: 0 !important;

													    &.mage-error {
													    	margin: 0;
													    }
													}

													@include placeholder {
														color: $mediumGrey;
														text-transform: capitalize;
													}

													.mage-error {
														margin: 0rem 0rem 2rem 0rem;
														font-family: $roboto;
														font-size: 14px;
														font-weight: 700;
														font-style: normal;
														line-height: 26px;
														text-align: left;
													}
												}
											}

											.actions-toolbar {
												width: 50%;
												margin: 31px 0px 0px 0px !important;

												.primary {
													float: right;

													button {
														width: 225px !important;
													    height: 40px !important;
													    padding: 0rem 2rem 0rem 2rem;
													    margin: 0;
													    position: relative;
													    display: inline-block;
													    background-color: $yellow;
													    border-radius: 5px;
													    box-shadow: 3px 3px 12px rgba($black, 0);
													    color: $grey !important;
													    font-family: $roboto;
													    font-size: 16px;
													    font-weight: 700;
													    font-style: normal;
													    line-height: 40px !important;
													    text-align: center;
													    text-decoration: none !important;
													    overflow: hidden;
													    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

													    span {
													    	pointer-events: none;

													        &.ripple {
													            transform: scale(.25);
													            position: absolute;
													            background-color: rgba($black, .25);
													            border-radius: 100%;
													            opacity: 0;
													            pointer-events: none;
													            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
													        }
													    }

													    &:focus {
													        color: $grey;
													    }
													}
												}
											}
										}
									}
								}
							}

							.methods {
								max-width: none;
								margin: 0;
								text-align: right;

								li {
									max-width: none;
									padding: 0;
									margin: 0;
									display: none !important;

									button {
										width: 225px;
									    height: 45px;
									    padding: 0rem 2rem 0rem 2rem;
									    position: relative;
									    display: inline-block;
									    background-color: $yellow;
									    border: none;
									    border-radius: 5px;
									    box-shadow: 3px 3px 12px rgba($black, 0);
									    color: $grey !important;
									    font-family: $roboto;
									    font-size: 16px;
									    font-weight: 700;
									    font-style: normal;
									    line-height: 45px;
									    text-align: center !important;
									    text-decoration: none !important;
									    overflow: hidden;
									    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

									    span {
									        pointer-events: none;
									        
									        &.ripple {
									            transform: scale(.25);
									            position: absolute;
									            background-color: rgba($black, .25);
									            border-radius: 100%;
									            opacity: 0;
									            pointer-events: none;
									            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
									        }
									    }

									    &:focus {
									        color: $grey;
									    }
									}

									&:first-of-type {
										display: block !important;
									}
								}
							}
						}

						.form-cart {
							width: 100%;
							order: 1;

							.table-wrapper {
								margin: 0px 0px 50px 0px;

								table {
									background-color: rgba($lightGrey, .2);
									border: 1px solid $lightGrey;

									thead, tbody, tfoot {
										background-color: transparent;
										border: none;

										tr {
											th, td {
												padding: .625rem;
												color: $grey;
												text-align: left;
											}

											th {
												font-family: $roboto;
												font-size: 14px;
												font-weight: 700;
												font-style: normal;
												line-height: 20px;
											}

											td {
												font-family: $roboto;
												font-size: 16px;
												font-weight: 400;
												font-style: normal;
												line-height: 20px;

												.qty {
													input {
														width: 100%;
														max-width: 100px;
														height: 40px;
														padding: 0rem 1rem 0rem 1rem;
														margin: 0;
														background-color: $white;
														border: 1px solid $lightGrey;
														border-radius: 5px;
														box-shadow: none;
														color: $grey;
														font-family: $roboto;
														font-size: 14px;
														font-weight: 400;
														font-style: normal;
														line-height: 40px;
														z-index: 0 !important;
													}

													@include placeholder {
														color: $mediumGrey;
													}
												}

												&.item {
													display: inline-flex;
													align-items: center;
												}

												&.price, &.qty, &.subtotal {
													padding-top: 29px;
												}
											}

											&.item-actions {
												background-color: rgba($lightGrey, .2);
												border-top: 1px solid $lightGrey;

												td {
													.actions-toolbar {
														/*.gift-options-cart-item {
															// margin: 0rem 1rem 0rem 0rem !important;

															button {
																text-transform: capitalize;

																&:after {
																	display: none;
																}
															}

															.gift-content {
																&._active {
																	margin: 50px 0px 0px 0px;

																	.gift-options {
																		.gift-message {
																			.gift-options-title {
																				span {
																					// color: $;
																					// font-family: $;
																				    font-size: 20px;
																				    font-weight: 700;
																				    font-style: normal;
																				    line-height: 26px;
																				    text-transform: capitalize;
																				}
																			}
																		}

																		.actions-toolbar {
																			.secondary {
																				button {
																					&.action-cancel {
																						border-radius: 5px;
																						text-decoration: none;
																					}
																				}
																			}
																		}
																	}
																}
															}
														}*/

														a {
															width: auto !important;
														    height: 45px;
														    padding: 0rem 2rem 0rem 2rem;
														    position: relative;
														    display: inline-block;
														    background-color: $yellow;
														    border-radius: 5px;
														    box-shadow: 3px 3px 12px rgba($black, 0);
														    color: $grey !important;
														    font-family: $roboto;
														    font-size: 16px;
														    font-weight: 700;
														    font-style: normal;
														    line-height: 45px;
														    text-transform: capitalize;
														    text-align: center;
														    text-decoration: none !important;
														    overflow: hidden;
														    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

														    span {
														        pointer-events: none;

														        &.ripple {
														            transform: scale(.25);
														            position: absolute;
														            background-color: rgba($black, .25);
														            border-radius: 100%;
														            opacity: 0;
														            pointer-events: none;
														            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
														        }
														    }

															&.action-towishlist {
																display: none !important;
															}

															&.action-edit {
																margin: 0rem 1rem 0rem 0rem !important;
															}

															&.action-delete {
																margin: 0;
															}

															&:before, &:after {
																display: none !important;
															}

															&:hover, &:focus {
																background-color: $yellow !important;
																color: $grey !important;
																border: none !important;
															}
														}
													}
												}
											}
										}
									}

									thead, tbody {
										border-bottom: 1px solid $lightGrey;
									}

									tbody {
										background-color: transparent;

										tr {
											td {
												.product-item-photo {
													width: 100%;
													padding: 0;
													top: 0;
													margin: 0;

													.product-image-container {
														display: block;

														.product-image-wrapper {
															img {
																width: 75px;
																max-height: 75px;
																margin: 0;
																object-fit: contain;
																object-position: center;
															}
														}

														&:before, &:after {
															display: none;
														}
													}

													&:before, &:after {
														display: none;
													}
												}

												.product-item-details {
													white-space: nowrap;

													.product-item-name {
														margin: 0;
														display: block !important;
													}
												}

												span {
													span {
														span {
															font-family: $roboto;
															font-size: 12px;
															font-weight: 400;
															font-style: normal;
															line-height: 20px;
														}
													}
												}
											}

											&.item-actions {
												.actions-toolbar {
													margin: 0 !important;
												}
											}
										}
									}
								}
							}

							.actions {
								margin: 0px 0px 50px 0px;

								a, button {
									width: auto !important;
								    height: 45px;
								    padding: 0rem 2rem 0rem 2rem;
								    margin: 0;
								    position: relative;
								    display: inline-block;
								    background-color: $yellow;
								    border: none;
								    border-radius: 5px;
								    box-shadow: 3px 3px 12px rgba($black, 0);
								    color: $grey !important;
								    font-family: $roboto;
								    font-size: 16px;
								    font-weight: 700;
								    font-style: normal;
								    line-height: 45px;
								    text-align: center;
								    text-decoration: none !important;
								    overflow: hidden;
								    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

								    span {
								        pointer-events: none;

								        &.ripple {
								            transform: scale(.25);
								            position: absolute;
								            background-color: rgba($black, .25);
								            border-radius: 100%;
								            opacity: 0;
								            pointer-events: none;
								            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
								        }
								    }

								    &:before {
								    	display: none;
								    }

								    &:hover, &:focus {
								    	background-color: $yellow !important;
								    	border: none !important;
								        color: $grey !important;
								    }
								}

								button {
									&.update {
										margin: 0rem 0rem 0rem 1.25rem !important;

										&:before {
											display: none;
										}
									}
								}
							}
						}

						#gift-options-cart {
							display: none;
						}
					}
				}
			}
		}
	}
}

/**
* _magento-cart-websiteni - Mobile Only.
*/
@media screen and (max-width: 639px) {
	.checkout-cart-index {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						.cart-container {
							.form-cart {
								.table-wrapper {
									margin: 0rem 0rem 2rem 0rem;

									table {
										tbody {
											tr {
												td {
													.product-item-photo {
														margin: 0rem .9375rem 0rem 0rem;
													}

													&.item {
														display: flex;
													}
												}
											}
										}
									}
								}

								.actions {
									margin: 0rem 0rem 2rem 0rem;

									a, button {
										width: 100% !important;
										margin: 0 !important;
									}

									button {
										&.clear {
											margin: .625rem 0rem .625rem 0rem !important;
										}

										&.update {
											margin: 0 !important;
										}
									}
								}
							}
						}

						.cart-summary {
							.discount {
								.content {
									form {
										.fieldset {
											.actions-toolbar {
												.primary {
													button {
														max-width: 100%;
														padding: 0 !important;
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

html {
	body {
		.fancybox-overlay {
			width: 100% !important;
			height: 100% !important;
			top: 0 !important;
			left: 0 !important;
			position: fixed !important;
			background-image: none !important;
			background-color: rgba($black, .75) !important;
		}

		.fancybox-wrap {
			width: calc(100% - 1rem - 1rem) !important;
			max-width: 1024px !important;
			background-color: $white !important;
			border: none !important;
			border-radius: 0 !important;

			.fancybox-skin {
				padding: 3rem !important;
				border-radius: 0 !important;
				box-shadow: none !important;

				.fancybox-outer {
					.fancybox-inner {
						width: 100% !important;

						.wrapper {
							margin: 0 !important;
							display: flex !important;
							justify-content: space-between !important;
							flex-flow: row wrap !important;
							float: none !important;

							.left-content {
								width: calc(50% - 3rem / 2) !important;
								position: relative;
								border-right: none !important;
								float: none !important;

								.product_detail {
									display: flex !important;
									flex-flow: row wrap !important;

									.product-image {
										width: 100% !important;
										margin: 0rem 0rem 1rem 0rem !important;
									}

									.product_name {
										width: 100% !important;
										margin: 0rem 0rem 2rem 0rem !important;

										a {
											color: $grey !important;
										    font-family: $roboto !important;
										    font-size: 16px !important;
										    font-weight: 700 !important;
										    font-style: normal !important;
										    line-height: 16px !important;
										    text-decoration: none !important;
										}
									}
								}

								.action {
									button {
										width: 200px !important;
									    height: 45px !important;
									    padding: 0rem 2rem 0rem 2rem !important;
									    position: relative !important;
									    display: inline-block !important;
									    background-color: $yellow !important;
									    border: none !important;
									    border-radius: 5px !important;
									    color: $grey !important;
									    font-family: $roboto !important;
									    font-size: 16px !important;
									    font-weight: 700 !important;
									    font-style: normal !important;
									    line-height: 45px !important;
									    text-align: center !important;
									    text-decoration: none !important;
									}
								}

								&:after {
									width: 1px;
									height: 100%;
									top: 0;
									right: -1.5rem;
									position: absolute;
									background-color: #F2F2F2;
									content: '';
								}
							}

							.right-content {
								width: calc(50% - 3rem / 2) !important;
								float: none !important;
								text-align: left !important;

								.cart-info {
									.items-count, .subtotal {
										color: $black !important;
										font-family: $roboto !important;
										font-size: 20px !important;
										font-weight: 400 !important;
										font-style: normal !important;
										line-height: 30px !important;

										span {
											a {
												color: $black !important;
												text-transform: capitalize !important;
												text-decoration: none !important;
											}
										}
									}

									.subtotal {
										margin: .625rem 0rem 0rem 0rem !important;
										font-weight: 700 !important;
									}

									i {
										display: none !important;
									}
								}

								.action {
									button {
										width: 200px !important;
									    height: 45px !important;
									    padding: 0rem 2rem 0rem 2rem !important;
									    position: relative !important;
									    display: inline-block !important;
									    background-color: $yellow !important;
									    border: none !important;
									    border-radius: 5px !important;
									    color: $grey !important;
									    font-family: $roboto !important;
									    font-size: 16px !important;
									    font-weight: 700 !important;
									    font-style: normal !important;
									    line-height: 45px !important;
									    text-align: center !important;
									    text-decoration: none !important;
									}
								}
							}
						}

						.products-ordered {
							margin: 6rem 0rem 0rem 0rem;
							position: relative;

							ol {
								width: calc(100% - 90px) !important;

								.product-item-info {
									background-color: $white;

									.product-item-details {
										margin: 1rem 0rem 1rem 0rem;
									}

									.actions-primary {
										display: none;
									}
								}

								.owl-nav {
									display: block !important;

									div {
										width: 30px !important;
										height: 30px !important;
										padding: 0 !important;
										margin: 0 !important;
										top: 50% !important;
										transform: translateY(-50%) !important;
										position: absolute !important;

										&.owl-prev {
											left: -45px !important;
										}

										&.owl-next {
											right: -45px !important;
										}
									}
								}
							}

							&:before {
								top: -3rem;
								left: 0;
								position: absolute;
								color: $grey !important;
							    font-family: $roboto !important;
							    font-size: 24px !important;
							    font-weight: 700 !important;
							    font-style: normal !important;
							    line-height: 24px !important;
							    content: 'Don\'t Forget These';
							}
						}

						.related_items {
							display: none;
						}
					}
				}

				.fancybox-close {
					width: 50px;
					height: 50px;
					top: 1rem;
					right: 1rem;
					position: absolute;
					display: flex;
					justify-content: center;
					align-items: center;
					background-image: none;
					background-color: transparent;
		            border: 1px solid $grey;
		            border-radius: 100%;
		            color: $grey;
		            font-size: 30px;
		            line-height: 30px;
		            text-decoration: none !important;
				}
			}
		}
	}
}

/**
* _magento-cart-websiteni - Medium.
*/
@media screen and (min-width: 640px) {
	.checkout-cart-index {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						.cart-container {
							.cart-summary {
								padding: .9375rem;
							}
						}
					}
				}
			}
		}
	}
}

/**
* _magento-cart-websiteni - Large.
*/
@media screen and (min-width: 1024px) {

}

/**
* _magento-cart-websiteni - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _magento-cart-websiteni - XXL.
*/
@media screen and (min-width: 1440px) {
    
}