/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _fonts - Small.
*/
$roboto:			'Roboto', sans-serif;

h1 {
	margin: 0;
	font-family: $roboto;
	font-size: 40px;
	font-weight: 700;
	font-style: normal;
	line-height: 40px;
}

h2 {
	margin: 0;
	// font-family: $;
	// font-size: ;
	// font-weight: ;
	// font-style: ;
	// line-height: ;
}

h3, h2.events-section-title, .quickrfq .fieldset .legend span {
	margin: 0;
	font-family: $roboto;
	font-size: 24px;
	font-weight: 700;
	font-style: normal;
	line-height: 24px;
}

h4, .block-title, .block-title strong, legend.legend, legend.legend span {
	margin: 0 !important;
	font-family: $roboto !important;
	font-size: 21px !important;
	font-weight: 700 !important;
	font-style: normal !important;
	line-height: 21px !important;
}

h5 {
	margin: 0;
	font-family: $roboto;
	font-size: 20px;
	font-weight: 700;
	font-style: normal;
	line-height: 20px;
}

h6 {
	margin: 0;
	font-family: $roboto;
	font-size: 16px;
	font-weight: 700;
	font-style: normal;
	line-height: 16px;
}

p, address {
	margin: 0rem 0rem 2rem 0rem;
	font-family: $roboto;
	font-size: 14px;
	font-weight: 400;
	font-style: normal;
	line-height: 22px;
}

p {
	strong {
		// font-family: $;
		// font-weight: ;
	}
}

label {
	margin: 0;
	// font-family: $;
	// font-size: ;
	// font-weight: ;
	// font-style: ;
	// line-height: ;
}

blockquote {
    padding: 0rem 0rem 0rem 1rem;
    margin: 2rem 0rem 2rem 0rem;
    position: relative;
    border-left: 3px solid rgba($lightGrey, .3);
    overflow: hidden;

    p {
        font-weight: 400 !important;

        &:last-of-type {
            font-weight: 700 !important;
        }
    }

    &:after {
        width: auto;
        height: auto;
        top: -.625rem;
        right: 0;
        position: absolute;
        opacity: .3;
        z-index: -1;
        content: url('../images/quotes.svg');
    }
}

/**
* _fonts - Medium.
*/
@media screen and (min-width: 640px) {
    h1 {
    	font-size: 55px;
    	line-height: 55px;
    }

    h2 {
    	// font-size: ;
    	// line-height: ;
    }

    h3, h2.events-section-title, .quickrfq .fieldset .legend span {
    	font-size: 39px;
    	line-height: 32px;
    }

    h4, .block-title, .block-title strong, legend.legend, legend.legend span {
    	font-size: 28px !important;
    	line-height: 28px !important;
    }

    h5 {
    	// font-size: ;
    	// line-height: ;
    }

    h6 {
    	font-size: 17px;
    	line-height: 17px;
    }

    p, address {
    	// font-size: ;
    	// line-height: ;
    }
}

/**
* _fonts - Large.
*/
@media screen and (min-width: 1024px) {
    h1 {
    	font-size: 70px;
    	line-height: 70px;
    }

    h2 {
    	// font-size: ;
    	// line-height: ;
    }

    h3, h2.events-section-title, .quickrfq .fieldset .legend span {
    	font-size: 54px;
    	line-height: 47px;
    }

    h4, .block-title, .block-title strong, legend.legend, legend.legend span {
    	font-size: 35px !important;
    	line-height: 33px !important;
    }

    h5 {
    	// font-size: ;
    	// line-height: ;
    }

    h6 {
    	font-size: 18px;
    	line-height: 18px;
    }

    p, address {
    	// font-size: ;
    	// line-height: ;
    }
}

/**
* _fonts - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _fonts - XXL.
*/
@media screen and (min-width: 1440px) {
    
}