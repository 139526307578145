/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _error-404 - Small.
*/
.page-layout-error-404 {
	.page-wrapper {
		padding-top: 25px;
	}
}

/**
* _error-404 - Medium.
*/
@media screen and (min-width: 640px) {
	.page-layout-error-404 {
		.page-wrapper {
			padding-top: 37.5px;
		}
	}
}

/**
* _error-404 - Large.
*/
@media screen and (min-width: 1024px) {
	.page-layout-error-404 {
		.page-wrapper {
			padding-top: 50px;
		}
	}
}

/**
* _error-404 - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _error-404 - XXL.
*/
@media screen and (min-width: 1440px) {

}