/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _magento-websiteni - Small.
*/

// Hides category filter from search results
// Hides Stock status on product page
.catalogsearch-result-index dl#narrow-by-list dt:first-of-type, 
.catalogsearch-result-index dl#narrow-by-list dd:first-of-type,
//.product-info-main .stock.available, 
//.product-info-main .stock.unavailable 
{
    display: none!important;
}
.products-grid.products-related .slick-initialized .slick-slide {
    float: left!important;
    display: block!important;
}
.products-grid.products-related .product-item .product-item-info.related-available {
    width: 100%;
}
.products-grid:not(.products-related) {
	margin: 0;

	.product-items {
		width: calc(100% + .625rem * 2) !important;
		max-width: none !important;
		margin: 0 !important;
		margin-left: -.625rem !important;
		margin-right: -.625rem !important;
		display: flex !important;
		flex-flow: row wrap !important;

		.product-item {
			width: calc(50% - .625rem * 2) !important;
			max-width: none !important;
			padding: 0 !important;
			margin: 5px .625rem 50px .625rem !important;
			background-color: $white !important;
			cursor: pointer !important;
			transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83) !important;

			.product-item-info {
				width: 100% !important;

				.product-item-photo {
					.product-image-container {
						.product-image-wrapper {
							img {
								width: 100% !important;
								height: 100% !important;
								padding: 0 !important;
								object-fit: contain !important;
								object-position: center !important;
							}
						}
					}
				}
			}

			&.featured_product {
				position: relative;

				.ribbon-featured {
					width: 75px;
					height: 75px;
					top: -5px;
					right: -5px;
					position: absolute;
					text-align: right;
					overflow: hidden;
					z-index: 2;

					span {
						width: 100px;
						top: 19px;
						right: -21px;
						transform: rotate(45deg);
						position: absolute;
						display: block;
						background-image: linear-gradient($yellow, darken($yellow, 10%));
						box-shadow: 0px 3px 10px -5px rgba($black, .1);
						color: $black;
						font-size: 10px;
						font-weight: 700;
						font-style: normal;
						line-height: 20px;
						text-transform: uppercase;
						text-align: center;

						&:before {
							top: 100%;
							left: 0;
							position: absolute;
							border-top: 3px solid darken($yellow, 10%);
							border-left: 3px solid darken($yellow, 10%);
							border-bottom: 3px solid transparent;
							border-right: 3px solid transparent;
							z-index: -1;
							content: '';
						}

						&:after {
							top: 100%;
							right: 0;
							position: absolute;
							border-top: 3px solid darken($yellow, 10%);
							border-left: 3px solid transparent;
							border-bottom: 3px solid transparent;
							border-right: 3px solid darken($yellow, 10%);
							z-index: -1;
							content: '';
						}
					}
				}
			}
		}
	}
}

.item:not(.col), .product, .product-item {
	padding: 0 !important;

	.product, .product-item-info {
		border: 0px solid $lightGrey;

		.product-item-photo {
			width: 100% !important;
			max-width: none !important;
			padding: 0rem !important;
			display: flex !important;
			justify-content: center !important;
			align-items: center !important;
			// background-color: $ !important;
			border: none !important;
			float: none !important;
			text-decoration: none !important;
			overflow: hidden;

			.product-image-container {
				min-width: 75px !important;

				.product-image-wrapper {
					min-width: 75px !important;
					overflow: visible !important;

					img {
						min-width: 75px !important;
					}
				}
			}

			&:after {
				display: none !important;
			}
		}

		.product-item-details {
			margin: 10px 0px -22.5px 0px;
			padding: 0;
			border: none !important;
			text-align: center;

			.product-item-name {
				padding: 0rem 1rem 0rem 1rem !important;
				margin: 0rem auto 1.5rem auto;
				border: none !important;

				// display: contents;
				word-break: unset;
				word-wrap: unset;
				overflow-wrap: unset;
				hyphens: unset;

				a {
					color: $grey !important;
					font-family: $roboto;
					font-size: 14px;
					font-weight: 700;
					font-style: normal;
					line-height: 17px;
					text-decoration: none !important;

					// display: contents;
					word-break: unset;
					word-wrap: unset;
					overflow-wrap: unset;
					hyphens: unset;

					&:after {
						display: none;
					}
				}
			}

			.product-reviews-summary {
				display: none !important;
			}

			.product-item-pricing {
				.price-container {
					.price-wrapper {
						span {
							margin: 0;
							// color: $;
							// font-family: $;
							font-size: 25px;
							font-weight: 700;
							font-style: normal;
							line-height: 25px;
						}
					}
				}

				.details-qty {
					padding: 2rem 0rem 0rem 0rem;
					margin: 2rem 0rem 0rem 0rem;
					// border-top: 1px solid $;

					label {
						width: 100% !important;
						height: auto;
						padding: 0 !important;
						margin: 0px 0px 5px 0px !important;
						background-color: transparent;
						// color: $;
						// font-family: $;
						font-size: 14px;
						font-weight: 700;
						font-style: normal;
						line-height: 26px;
						text-align: left !important;
						float: none !important;

						&:after {
							display: none;
						}
					}

					input {
						width: 100%;
						height: 40px;
						padding: 0rem 1rem 0rem 1rem;
						margin: 0;
						// background-color: $;
						border: none;
						border-radius: 5px;
						box-shadow: none;
						// color: $;
						// font-family: $;
						font-size: 14px;
						font-weight: 400;
						font-style: normal;
						line-height: 26px;
					}

					@include placeholder {
						// color: $;
					}
				}
			}

			.price-box {
				margin: 0;

				span {
					span {
						span {
							color: $grey;
							font-family: $roboto;
							font-size: 20px;
							font-weight: 700;
							font-style: normal;
							line-height: 20px;

							&.price-label {
								color: $grey;
								font-family: $roboto;
								font-size: 12px;
								font-weight: 400;
								font-style: normal;
								line-height: 12px;
								text-transform: lowercase;
							}
						}
					}
				}

				.price-label {
					color: $black;
				}
			}

			.actions {
				margin: 2rem 0rem 0rem 0rem !important;
				border: none !important;

				.primary, .secondary {
					a {
						&:after {
							display: none !important;
						}
					}
				}
			}

			.product-item-inner {
				height: 45px;

				.actions {
					.actions-primary {
						display: flex;
						justify-content: center;

						form {
							button {
								width: auto;
							    height: 45px;
							    padding: 0rem 2rem 0rem 2rem;
							    position: relative;
							    display: inline-block;
							    background-color: $yellow;
							    border: none;
							    border-radius: 5px;
							    box-shadow: 3px 3px 12px rgba($black, 0);
							    color: $grey !important;
							    font-family: $roboto;
							    font-size: 16px;
							    font-weight: 700;
							    font-style: normal;
							    line-height: 45px;
							    text-align: center;
							    text-decoration: none !important;
							    overflow: hidden;
							    transition: .4s cubic-bezier(0.29, 0.18, 0.26, 0.83);

							    span {
							    	pointer-events: none;
							    	
							        &.ripple {
							            transform: scale(.25);
							            position: absolute;
							            background-color: rgba($black, .25);
							            border-radius: 100%;
							            opacity: 0;
							            pointer-events: none;
							            animation: ripple .8s cubic-bezier(0.29, 0.18, 0.26, 0.83);
							        }
							    }

							    &:focus {
							        color: $grey;
							    }
							}
						}

						.stock {
							&.unavailable {
								margin: -1.5rem 0rem 0rem 0rem;
								text-transform: none;

								span {
									width: auto !important;
								    height: 40px;
								    padding: 0rem 1rem 0rem 1rem !important;
								    margin: 0 !important;
								    background-color: transparent !important;
								    border: none !important;
								    border-radius: 5px;
								    box-shadow: none;
								    // color: $ !important;
								    // font-family: $ !important;
								    font-size: 14px !important;
								    font-weight: 400 !important;
								    font-style: normal;
								    line-height: 0px;
								    text-transform: capitalize;
								}

								// &:before {
								// 	display: block;
								// 	color: $grey !important;
								//     font-family: $roboto !important;
								//     font-size: 14px !important;
								//     font-weight: 400 !important;
								//     font-style: normal;
								//     line-height: 40px;
								//     text-transform: none;
								//     content: 'Back In Stock Soon';
								// }
							}
						}
					}

					.actions-secondary {
						display: none !important;
					}
				}
			}
		}
	}

	a {
		&:before, &:after {
			display: none;
		}
	}
}

.page-layout-magento-websiteni, .page-layout-magento-account-websiteni {
	.page-wrapper {
		.page-main {
			.page-title-wrapper {
				width: 100%;

				.page-title {
					.base {
						span {
							display: inline-block;
						}
					}
				}
			}

			.messages {
				margin: 0;
			}

			.columns {
				width: 100%;

				.column {
					.block, fieldset, .fieldset {
						padding: 0;
						margin: 0;
						border-bottom: none;
						float: none;
						clear: both;

						.block-title, legend, .legend {
							padding: 0;
							margin: 0rem 0rem 2rem 0rem !important;
							border: none;

							strong, span {
								color: $grey;
								font-family: $roboto;
								font-size: 21px;
								font-weight: 700;
								font-style: normal;
								line-height: 21px;
								text-transform: capitalize;
							}
						}

						.block-content {
							.box {
								width: 100%;
								padding: 0;
								margin: 0;
								border-bottom: none;
								float: none;
								clear: both;

								.box-title {
									margin: 0rem 0rem 2rem 0rem;

									span {
										color: $grey;
										font-family: $roboto;
										font-size: 17px;
										font-weight: 700;
										font-style: normal;
										line-height: 23px;
									}
								}

								.box-actions {
									margin: 1rem 0rem 0rem 0rem;

									a {
										margin-right: 1.875rem;
									}
								}
							}

							.box:not(:last-of-type) {
								padding: 0px 0px 30px 0px;
								margin: 0px 0px 30px 0px;
								border-bottom: 1px solid $lightGrey;
							}
						}
					}

					.block:not(:last-of-type), fieldset:not(:last-of-type), .fieldset:not(:last-of-type) {
						padding: 0px 0px 30px 0px;
						margin: 0px 0px 30px 0px;
						border-bottom: 1px solid $lightGrey;
					}
				}
			}
		}
	}

	&.customer-account-logoutsuccess {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						#authenticationPopup + p {
							margin: 0;
						}
					}
				}
			}
		}
	}

	&.customer-account-forgotpassword {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						form {
							.actions-toolbar {
								.primary {
									button {
										width: 225px !important;
									}
								}

								.secondary {
									a {
										text-transform: capitalize;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.message {
// .message, .info:not(.fieldset):not(.product.info) {
	width: 100% !important;
	height: auto !important;
	padding: .9375rem !important;
	margin: 0rem 0rem 2rem 0rem !important;
	background-color: $grey !important;
	border-top: none !important;
	border-left: none !important;
	border-bottom: none !important;
	border-right: none !important;
	box-shadow: 3px 3px 12px rgba($black, .2) !important;

	span {
		margin: 0 !important;
		color: $white !important;
		font-family: $roboto !important;
		font-size: 14px !important;
		font-weight: 400 !important;
		font-style: normal !important;
		line-height: 26px !important;

		&:before {
			display: none !important;
		}
	}
}

p {
	&.message {
		color: $white !important;
		text-transform: lowercase !important;

		i {
			display: none !important;
		}

		&:after {
			position: absolute !important;
			content: '.' !important;
		}
	}
}

.message {
	&.notice, &.info, &.empty, &.error {
		width: 100% !important;
		height: auto !important;
		padding: .9375rem !important;
		margin: 0rem 0rem 2rem 0rem !important;
		background-color: $grey !important;
		border-top: none !important;
		border-left: none !important;
		border-bottom: none !important;
		border-right: none !important;
		box-shadow: 3px 3px 12px rgba($black, .2) !important;

		div {
			margin: 0 !important;
			color: $white !important;
			font-family: $roboto !important;
			font-size: 14px !important;
			font-weight: 400 !important;
			font-style: normal !important;
			line-height: 26px !important;

			&:before {
				display: none !important;
			}
		}
	}
}

.messages {
	div {
		.messages {
			.message {
				width: 100% !important;
				height: auto !important;
				padding: .9375rem !important;
				margin: 0rem 0rem 2rem 0rem !important;
				background-color: $grey !important;
				border-top: none !important;
				border-left: none !important;
				border-bottom: none !important;
				border-right: none !important;
				box-shadow: 3px 3px 12px rgba($black, .2) !important;

				div {
					margin: 0 !important;
					color: $white !important;
					font-family: $roboto !important;
					font-size: 14px !important;
					font-weight: 400 !important;
					font-style: normal !important;
					line-height: 26px !important;

					a {
						color: $yellow !important;
					}

					&:before {
						display: none !important;
					}
				}
			}
		}
	}
}

/**
* _magento-websiteni - Medium.
*/
@media screen and (min-width: 640px) {
	.products-grid:not(.products-related)  {
		.product-items {
			width: calc(100% + 1.875rem) !important;
			margin-left: -.9375rem !important;
			margin-right: -.9375rem !important;

			.product-item {
				width: calc(33.33333% - .9375rem * 2) !important;
				margin: 5px .9375rem 75px .9375rem !important;
			}
		}
	}

	.page-layout-magento-websiteni, .page-layout-magento-account-websiteni {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						.block, fieldset, .fieldset {
							.block-title, legend, .legend {
								strong, span {
									font-size: 23px;
    								line-height: 23px;
    							}
    						}
    					}

    					.block:not(:last-of-type), fieldset:not(:last-of-type), .fieldset:not(:last-of-type) {
							padding: 0;
							margin: 0;
							border-bottom: none;
						}
    				}
    			}
			}
		}
	}

	.login-container {
		display: flex !important;

		.block-customer-login {
			width: 50% !important;
			padding-right: 8.33333% !important;
			border-right: 1px solid $lightGrey !important;
		}

		.block-new-customer {
			width: 50% !important;
			padding-left: 8.33333% !important;
		}
	}
}

/**
* _magento-websiteni - Large.
*/
@media screen and (min-width: 1024px) {
	.products-grid:not(.products-related)  {
		.product-items {
			.product-item {
				width: calc(33.33% - .9375rem * 2) !important;
				margin: 5px .9375rem 100px .9375rem !important;

				&:hover {
					background-color: rgba($lightGrey, .2) !important;
				}
			}
		}
	}

	.item:not(.col), .product, .product-item {
		&:hover {
			.product, .product-item-info {
				.product-item-details {
					.product-item-inner {
						.actions {
							.actions-primary {
								form {
									button {
										&:hover {
								            box-shadow: 3px 3px 12px rgba($black, .3);
								            color: $grey;
								        }
								    }
								}
							}
						}
					}
				}
			}
		}
	}

	.page-layout-magento-websiteni, .page-layout-magento-account-websiteni {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						.block, fieldset, .fieldset {
							.block-title, legend, .legend {
								strong, span {
									font-size: 25px;
    								line-height: 25px;
    							}
    						}
    					}
    				}
    			}
			}
		}
	}
}

/**
* _magento-websiteni - XL.
*/
@media screen and (min-width: 1200px) {
    .products-grid:not(.products-related)  {
		.product-items {
			.product-item {
				width: calc(25% - .9375rem * 2) !important;
				margin: 5px .9375rem 100px .9375rem !important;

				&:hover {
					// background-color: rgba($lightGrey, .2) !important;
				}
			}
		}
	}
	// .products-grid.products-related {
	// 	.product-items {
	// 		.product-item {
	// 			width: calc(20% - .9375rem * 2) !important;
	// 		}
	// 	}
	// }
}

/**
* _magento-websiteni - XXL.
*/
@media screen and (min-width: 1440px) {
    
}