/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _news - Small.
*/
.wordpress-homepage-view, .wordpress-archive-view, .wordpress-term-view, .wordpress-post-view {
	.page-wrapper {
		padding-bottom: 50px;

		.page-main {
			.page-title-wrapper {
				width: 100%;
				padding: 0rem 0rem 2rem 0rem;
				margin: 0rem 0rem 2rem 0rem;
				border-bottom: 1px solid $lightGrey;

				.page-title {
					.base {
						span {
							display: inline-block;
						}
					}
				}
			}

			.columns {
				width: 100%;

				.column {
					width: 100%;
					float: none;

					.posts {
						.post {
							transform: translateY(0);
							position: relative;
							box-shadow: 3px 3px 12px rgba($black, 0);
							transition: .4s cubic-bezier(0.51, 0.18, 0, 0.88);

							.image {
								height: 250px;
								overflow: hidden;

								img {
									width: 100%;
									height: 100%;
									object-fit: cover;
									object-position: top;
								}
							}

							.content {
								padding: .9375rem;
								border: 1px solid $lightGrey;
								border-top: none;

								span {
									margin: 0rem 0rem calc(.625rem / 2) 0rem;
									display: block;
									font-size: 12px;
									font-weight: 400;
									line-height: 12px;
								}

								h4 {
									margin: 0rem 0rem 1rem 0rem !important;
									left: -1px;
									position: relative;
									color: $grey;
								}

								p {
									margin: 0;
									color: $grey;
								}
							}

							&:before, &:after {
								display: none;
							}

							&:nth-last-of-type(1) {
								margin-bottom: 0;
							}
						}
					}

					.pager {
						.pages {
							width: 100%;
							float: none;

							ul {
								width: 100%;
							    position: relative;
							    display: flex;
							    justify-content: center;
							    align-items: center;

							    .item {
							    	width: 40px;
								    height: 40px;
								    padding: 0;
								    margin: 0px 2px 0px 2px;
								    display: flex;
								    align-items: center;
								    background-color: transparent;
								    border: 1px solid $lightGrey;
								    border-radius: 0;
								    box-shadow: none;

								    strong, a {
								    	width: 100%;
									    margin: 0;
									    display: block;
									    border: none;
									    color: $mediumGrey;
									    font-family: $roboto;
									    font-size: 14px;
									    font-weight: 400;
									    font-style: normal;
									    line-height: 40px;
									    text-align: center;

									    &:before, &:after {
									    	display: none;
									    }
								    }

								    &.current {
								    	background-color: $grey;
    									border: 1px solid $grey;

    									strong, a {
    										color: $white;
    									}
								    }

								    &.pages-item-previous, &.pages-item-next {
										width: 100px;
										padding: 0rem .9375rem 0rem .9375rem;
										margin: 0rem 1.875rem 0rem 0rem;
										position: relative;

										span {
											width: 100%;
											height: 40px;
											margin: 0;
											position: relative;

											&.label {
												display: none;
											}

											&:before, &:after {
												display: none;
											}
										}
									}

									&.pages-item-next {
										margin: 0rem 0rem 0rem 1.875rem;
									}
							    }
							}
						}

						p {
							display: none;
						}
					}
				}

				.sidebar {
					padding: 0;
					display: none;

					.wp-sidebar {
						.block {
							&.block-blog-categories{
								display: none!important;
							}
							.block-title {
								padding: 0rem 0rem 1rem 0rem !important;
							    margin: 0rem 0rem 2rem 0rem !important;
							    border-bottom: 1px solid $lightGrey !important;
							    color: $grey !important;
							    font-family: $roboto !important;
							    font-size: 16px !important;
							    font-weight: 700 !important;
							    font-style: normal !important;
							    line-height: 40px !important;

							    strong {
							    	color: $grey !important;
								    font-family: $roboto !important;
								    font-size: 16px !important;
								    font-weight: 700 !important;
								    font-style: normal !important;
								    line-height: 40px !important;
							    }
							}

							.block-content {
								ul {
									margin: 0;
									list-style: none;

									li {
											margin-bottom: 8px;
										a {
											position: relative;
											display: inline-block;
											font-family: $roboto;
											font-size: 14px;
											font-weight: 400;
											font-style: normal;
											line-height: 1.5;
											text-decoration: none !important;
											&:hover{
												color: #FFD203 !important
											}
											&:before,
										    &:after {
										        width: 0;
										        height: 2px;
										        top: 80%;
										        right: 0;
										        display: block;
										        display: none;
										        position: absolute;
										        content: '';
										    }

										    &:before {
										        background-color: rgba($black, .2);
										        transition: width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s;
										    }

										    &:after {
										        background-color: $black;
										        transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
										    }
										}
									}
								}
							}
						}

						.block-blog-search, .block-blog-meta {
							display: none;
						}
					}

					&.sidebar-additional {
						display: none;
					}
				}
			}
		}
	}
}

/**
* _news - Medium.
*/
@media screen and (min-width: 640px) {
	.wordpress-homepage-view, .wordpress-archive-view, .wordpress-term-view, .wordpress-post-view {
		.page-wrapper {
			padding-bottom: 62.5px;

			.page-main {
				.page-title-wrapper {
					margin: 0rem 0rem 3rem 0rem;
				}

				.columns {
					.sidebar {
						width: calc(25% - 8.33333%);
						float: left;
						display: block;
					}

					.column {
						width: 75%;
						float: right;

						.posts {
							.post {
								&:nth-last-of-type(1),
								&:nth-last-of-type(2) {
									margin-bottom: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
* _news - Large.
*/
@media screen and (min-width: 1024px) {
	.wordpress-homepage-view, .wordpress-archive-view, .wordpress-term-view, .wordpress-post-view {
		.page-wrapper {
			padding-bottom: 75px;

			.page-main {
				.page-title-wrapper {
					margin: 0rem 0rem 4rem 0rem;
				}

				.columns {
					.sidebar {
						.wp-sidebar {
							.block {
								.block-content {
									ul {
										li {
											a {
												&:hover {
													&:before, &:after {
										                width: 100%;
										                left: 0;
										            }
												}
											}
										}
									}
								}
							}
						}
					}

					.column {
						.posts {
							.post {
								&:nth-last-of-type(1),
								&:nth-last-of-type(2),
								&:nth-last-of-type(3) {
									margin-bottom: 0;
								}

								&:hover {
									transform: translateY(-12px);
									box-shadow: 3px 3px 12px rgba($black, .3);
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
* _news - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _news - XXL.
*/
@media screen and (min-width: 1440px) {

}