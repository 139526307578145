/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _events - Small.
*/
.eventcalendar-event-eventlist {
	.page-wrapper {
		.page-main {
			.columns {
				width: 100%;

				.column {
			        width: 100%;
			        padding-bottom: 0 !important;
			        margin: 0px auto 0px auto;
			        float: none;

			        .fc-header {
		        		width: 100%;
		        		margin: 0rem 0rem 2rem 0rem;

		        		tbody {
		        			background-color: transparent;
		        			border: none;

		        			tr {
		        				display: flex;
		        				justify-content: space-between;
		        				flex-direction: row-reverse;

		        				td {
		        					padding: 0;
		        					display: flex;

		        					h2 {
		        						margin: 0;
		        						font-family: $roboto;
										font-size: 24px;
										font-weight: 700;
										font-style: normal;
										line-height: 48px;
		        					}

		        					.fc-button {
		        						width: 48px;
		        						height: 48px;
		        						margin: 0rem 0rem 0rem .625rem;
		        						display: flex;
		        						justify-content: center;
		        						align-items: center;
		        						background-color: $yellow;
		        						cursor: pointer;

		        						.fc-icon {
		        							padding: 6px;
		        							border: solid $black;
		        							border-width: 0px 3px 3px 0px;

		        							&.fc-icon-left-single-arrow {
		        								margin-left: 6px;
		        								transform: rotate(135deg);
		        							}

		        							&.fc-icon-right-single-arrow {
		        								margin-left: -3px;
		        								transform: rotate(-45deg);
		        							}
		        						}

		        						&.fc-button-today {
		        							display: none;
		        						}
		        					}

		        					&:last-of-type {
		        						display: none;
		        					}
		        				}
		        			}
		        		}
			        }

			        .fc-content {
			        	.fc-view {
			        		.fc-event-container {
			        			a {
			        				padding: .9375rem;
			        				position: relative;
			        				color: $black;
			        				font-family: $roboto;
							        font-size: 14px;
							        font-weight: 700;
							        font-style: normal;
							        line-height: 21px;
							        text-decoration: none;

			        				// width: 100% !important;
			        				// min-width: 100px;
			        				// padding: 5px 0px 0px 0px;
			        				// font-weight: 700;

			        				&:after {
			        					width: 10px;
			        					height: 100%;
			        					top: 0;
			        					right: -10px;
			        					position: absolute;
			        					background-color: $yellow;
			        					content: '';
			        				}
			        			}
			        		}

				        	table {
				        		max-width: none;
				        		margin: 0;
				        		color: $grey;
				        		font-family: $roboto;
				        	}
				        }
			        }

			        #calendar {
			        	overflow-x: auto;
			        }

			        .event-toolbar {
			        	display: none;
			        }

			        &.main {
			            min-height: 0;
			        }
			    }

			    .sidebar {
			    	padding-left: 0;
			    	display: none;

			    	.widget {
			    		h3 {
			    			margin: 0rem 0rem 2rem 0rem;
			    			word-break: break-all;
			    		}

			    		ul {
			    			margin: 0;
			    			list-style: none;

			    			li {
			    				padding: 0rem 0rem 2rem 0rem;
			    				margin: 0rem 0rem 2rem 0rem;
			    				border-bottom: 1px solid $lightGrey;

			    				.event-image {
			    					margin: 0rem 0rem 1rem 0rem;

			    					a {
			    						&:before, &:after {
			    							display: none;
			    						}
			    					}
			    				}

			    				.event-info {
			    					color: $grey;
			    					font-family: $roboto;
									font-size: 14px;
									font-weight: 400;
									font-style: normal;
									line-height: 26px;

			    					a {
			    						margin: 0px 0px 10px 0px;
			    						display: block;
			    						color: $grey;
			    						font-family: $roboto;
										font-size: 21px;
										font-weight: 700;
										font-style: normal;
										line-height: 21px;
									    text-decoration: none;
			    					}
			    				}

			    				&:last-of-type {
			    					padding: 0;
				    				margin: 0;
				    				border-bottom: none;
			    				}
			    			}
			    		}
			    	}
			    }
			}
		}
	}
}

.tooltipster-base {
	display: none !important;
}

.eventcalendar-event-eventview {
	.page-wrapper {
		.page-main {
			.page-title-wrapper {
				width: 100%;

				.page-title {
					.base {
						span {
							display: inline-block;
						}
					}
				}
			}

			.columns {
				.column {
					padding: 0;

					.events-calendar-details {
						.events-content {
							.events-single-right {
								padding-bottom: 37.5px;

								.event-description {
									.back_event {
										a {
											text-align: center;
										}
									}
								}
							}

							.events-single-left {
								.events-meta-group {
									margin-bottom: 3rem;

									.meta-inner {
										p {
											&.location {
												margin: 0;
											}
										}
									}

									table {
										color: $grey;
										font-family: $roboto;

										tbody, thead {
											tr {
												th, td {
													padding: .9375rem;

													abbr {
														border: none !important;
														text-decoration: none !important;
														cursor: auto !important;
													}
												}
											}
										}
									}

									&:last-of-type {
										margin-bottom: 0;
									}
								}

								.events-cta {
									display: none;
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
* _events - Medium.
*/
@media screen and (min-width: 640px) {
	.eventcalendar-event-eventlist {
		.page-wrapper {
			&:before {
				width: 100%;
		        max-width: 1320px;
		        padding-top: 25px;
		        padding-left: .9375rem;
		        padding-bottom: 25px;
		        padding-right: .9375rem;
		        margin: 0 auto 0 auto;
		        color: $grey;
                font-family: $roboto;
                font-size: 14px;
                font-weight: 400;
                font-style: normal;
                line-height: 18px;
			    content: 'Home > Events';
			}
		}
	}

	.eventcalendar-event-eventview {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						.events-calendar-details {
							.events-content {
								.events-single-right {
									padding-bottom: 50px;
								}
							}
						}
					}
				}
			}
		}
	}
}

/**
* _events - Large.
*/
@media screen and (min-width: 1024px) {
	.eventcalendar-event-eventlist {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
        				width: calc(75% - .9375rem);
        				float: left;
        			}

        			.sidebar {
        				width: calc(25% - .9375rem);
        				display: block;
        				float: right;
        			}
				}
			}
		}
	}
}

/**
* _events - XL.
*/
@media screen and (min-width: 1200px) {
    .eventcalendar-event-eventlist {
		.page-wrapper {
			.page-main {
				.columns {
					.column {
						width: calc(70% - .9375rem);
					}

        			.sidebar {
        				.widget {
        					h3 {
        						word-break: break-word;
        					}
        				}
        			}
        		}
        	}
        }
    }
}

/**
* _events - XXL.
*/
@media screen and (min-width: 1440px) {
    
}