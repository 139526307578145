/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _footer - Small.
*/
.global-footer {
	background-color: $greyAltAlt;
	border-top: 1px solid rgba($white, .1);
	border-bottom: 1px solid rgba($white, .1);

	h6 {
		position: relative;
		color: $yellow;
		font-weight: 400;

		&:after {
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			position: absolute;
			content: '+';
		}
	}

	p {
		margin: 2rem 0rem 2rem 0rem;
		color: $white;
		display: none;

		a {
			color: $white;
			text-decoration: none;
		}

		&:last-of-type {
			margin: 0;
		}

		&.visible {
			display: block;
		}
	}

	ul {
		margin-top: 2rem;
		margin-bottom: 0;
		display: none;
		color: $white;
		font-family: $roboto;
        font-size: 14px;
        font-weight: 400;
        font-style: normal;
        line-height: 22px;

        li {
        	margin: 0rem 0rem calc(.625rem / 2) 0rem;

        	a {
        		color: $white;
        		text-decoration: none;
        	}

        	&:last-of-type {
        		margin: 0;
        	}
        }

        &.visible {
        	display: block;
        }
	}

	.add-padd-sm {
		padding-bottom: 50px;
	}
}

/**
* _footer - Medium.
*/
@media screen and (min-width: 640px) {
    .global-footer {
    	.add-padd-md {
    		padding-bottom: 50px;
    	}
    }
}

/**
* _footer - Large.
*/
@media screen and (min-width: 1024px) {
    .global-footer {
    	h6 {
    		&:after {
    			display: none;
    		}
    	}

    	p, ul {
    		display: block;
    	}

    	.add-padd-lg {
    		padding-bottom: 50px;
    	}
    }
}

/**
* _footer - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _footer - XXL.
*/
@media screen and (min-width: 1440px) {
    
}