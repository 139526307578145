/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _all-departments - Small.
*/
.page-layout-all-departments {
	.page-wrapper {
		.all-departments {
			order: 3;
		}
	}
}

/**
* _all-departments - Medium.
*/
@media screen and (min-width: 640px) {
	
}

/**
* _all-departments - Large.
*/
@media screen and (min-width: 1024px) {
	
}

/**
* _all-departments - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _all-departments - XXL.
*/
@media screen and (min-width: 1440px) {

}