/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _classes - Small.
*/
/* General Classes. */
#skip-navigation-link {
	a {
		width: 1px;
		height: 1px;
		top: auto;
		left: -10000px;
		position: absolute;
		overflow: hidden;

		&:focus {
			width: auto;
			height: auto;
			position: static;
		}
	}
}

.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.fixed {
	position: fixed;
}

/* Padding Classes. */
.padding-top {
	padding-top: $paddingSmall;
}

.padding-top-small {
	padding-top: $paddingSmall;
}

.padding-bottom {
	padding-bottom: $paddingSmall;
}

.padding-bottom-small {
	padding-bottom: $paddingSmall;
}

/* Margin Classes. */
.margin-top {
	margin-top: $marginSmall;
}

.margin-bottom {
	margin-bottom: $marginSmall;
}

/* Element Alignment Classes. */
.websiteni-flex-sm {
	display: flex;
	flex-direction: row;
}

.websiteni-flex-hs-sm {
	justify-content: flex-start;
}

.websiteni-flex-hc-sm {
	justify-content: center;
}

.websiteni-flex-he-sm {
	justify-content: flex-end;
}

.websiteni-flex-vt-sm {
	align-items: flex-start;
}

.websiteni-flex-vc-sm {
	align-items: center;
}

.websiteni-flex-vb-sm {
	align-items: flex-end;
}

.websiteni-flex-alt-sm {
	display: flex;
	flex-direction: column;
}

.websiteni-flex-alt-hs-sm {
	align-items: flex-start;
}

.websiteni-flex-alt-hc-sm {
	align-items: center;
}

.websiteni-flex-alt-he-sm {
	align-items: flex-end;
}

.websiteni-flex-alt-vt-sm {
	justify-content: flex-start;
}

.websiteni-flex-alt-vc-sm {
	justify-content: center;
}

.websiteni-flex-alt-vb-sm {
	justify-content: flex-end;
}

/* Text Alignment Classes. */
.text-left-sm {
	text-align: left;
}

.text-center-sm {
	text-align: center;
}

.text-right-sm {
	text-align: right;
}

/* Hide/Show Classes. */
.hide-for-xl {
	display: inline-block;
}

.hide-for-xxl {
	display: inline-block;
}

.show-for-xl {
	display: none;
}

.show-for-xxl {
	display: none;
}

/**
* _classes - Medium.
*/
@media screen and (min-width: 640px) {
	/* Padding Classes. */
	.padding-top {
		padding-top: $paddingMedium;
	}

	.padding-bottom {
		padding-bottom: $paddingMedium;
	}

	/* Margin Classes. */
	.margin-top {
		margin-top: $marginMedium;
	}

	.margin-bottom {
		margin-bottom: $marginMedium;
	}

	/* Element Alignment Classes. */
	.websiteni-flex-md {
		display: flex;
		flex-direction: row;
	}

	.websiteni-flex-hs-md {
		justify-content: flex-start;
	}

	.websiteni-flex-hc-md {
		justify-content: center;
	}

	.websiteni-flex-he-md {
		justify-content: flex-end;
	}

	.websiteni-flex-vt-md {
		align-items: flex-start;
	}

	.websiteni-flex-vc-md {
		align-items: center;
	}

	.websiteni-flex-vb-md {
		align-items: flex-end;
	}

	.websiteni-flex-alt-md {
		display: flex;
		flex-direction: column;
	}

	.websiteni-flex-alt-hs-md {
		align-items: flex-start;
	}

	.websiteni-flex-alt-hc-md {
		align-items: center;
	}

	.websiteni-flex-alt-he-md {
		align-items: flex-end;
	}

	.websiteni-flex-alt-vt-md {
		justify-content: flex-start;
	}

	.websiteni-flex-alt-vc-md {
		justify-content: center;
	}

	.websiteni-flex-alt-vb-md {
		justify-content: flex-end;
	}

	/* Text Alignment Classes. */
	.text-left-md {
		text-align: left;
	}

	.text-center-md {
		text-align: center;
	}

	.text-right-md {
		text-align: right;
	}

	/* Hide/Show Classes. */

}

/**
* _classes - Large.
*/
@media screen and (min-width: 1024px) {
	/* Padding Classes. */
	.padding-top {
		padding-top: $paddingLarge;
	}

	.padding-bottom {
		padding-bottom: $paddingLarge;
	}

	/* Margin Classes. */
	.margin-top {
		margin-top: $marginLarge;
	}

	.margin-bottom {
		margin-bottom: $marginLarge;
	}

	/* Element Alignment Classes. */
	.websiteni-flex-lg {
		display: flex;
		flex-direction: row;
	}

	.websiteni-flex-hs-lg {
		justify-content: flex-start;
	}

	.websiteni-flex-hc-lg {
		justify-content: center;
	}

	.websiteni-flex-he-lg {
		justify-content: flex-end;
	}

	.websiteni-flex-vt-lg {
		align-items: flex-start;
	}

	.websiteni-flex-vc-lg {
		align-items: center;
	}

	.websiteni-flex-vb-lg {
		align-items: flex-end;
	}

	.websiteni-flex-alt-lg {
		display: flex;
		flex-direction: column;
	}

	.websiteni-flex-alt-hs-lg {
		align-items: flex-start;
	}

	.websiteni-flex-alt-hc-lg {
		align-items: center;
	}

	.websiteni-flex-alt-he-lg {
		align-items: flex-end;
	}

	.websiteni-flex-alt-vt-lg {
		justify-content: flex-start;
	}

	.websiteni-flex-alt-vc-lg {
		justify-content: center;
	}

	.websiteni-flex-alt-vb-lg {
		justify-content: flex-end;
	}

	/* Text Alignment Classes. */
	.text-left-lg {
		text-align: left;
	}

	.text-center-lg {
		text-align: center;
	}

	.text-right-lg {
		text-align: right;
	}

	/* Hide/Show Classes. */
	
}

/**
* _classes - XL.
*/
@media screen and (min-width: 1200px) {
	/* Grid Classes. */
	.grid-x > .xlarge-3 {
		width: calc(25% - 1.875rem);
	}

	.grid-x > .xlarge-6 {
		width: 50%;
	}

	/* Element Alignment Classes. */
	.websiteni-flex-xl {
		display: flex;
		flex-direction: row;
	}

	.websiteni-flex-hs-xl {
		justify-content: flex-start;
	}

	.websiteni-flex-hc-xl {
		justify-content: center;
	}

	.websiteni-flex-he-xl {
		justify-content: flex-end;
	}

	.websiteni-flex-vt-xl {
		align-items: flex-start;
	}

	.websiteni-flex-vc-xl {
		align-items: center;
	}

	.websiteni-flex-vb-xl {
		align-items: flex-end;
	}

	.websiteni-flex-alt-xl {
		display: flex;
		flex-direction: column;
	}

	.websiteni-flex-alt-hs-xl {
		align-items: flex-start;
	}

	.websiteni-flex-alt-hc-xl {
		align-items: center;
	}

	.websiteni-flex-alt-he-xl {
		align-items: flex-end;
	}

	.websiteni-flex-alt-vt-xl {
		justify-content: flex-start;
	}

	.websiteni-flex-alt-vc-xl {
		justify-content: center;
	}

	.websiteni-flex-alt-vb-xl {
		justify-content: flex-end;
	}

	/* Text Alignment Classes. */
	.text-left-xl {
		text-align: left;
	}

	.text-center-xl {
		text-align: center;
	}

	.text-right-xl {
		text-align: right;
	}

	/* Hide/Show Classes. */
	.hide-for-xl {
		display: none;
	}

	.show-for-xl {
		display: inline-block;
	}
}

/**
* _classes - XXL.
*/
@media screen and (min-width: 1440px) {
	.xxlarge-5 {
		width: 41.66667% !important;
	}

	.xxlarge-7 {
		width: 58.33333% !important;
	}

	/* Element Alignment Classes. */
	.websiteni-flex-xxl {
		display: flex;
		flex-direction: row;
	}

	.websiteni-flex-hs-xxl {
		justify-content: flex-start;
	}

	.websiteni-flex-hc-xxl {
		justify-content: center;
	}

	.websiteni-flex-he-xxl {
		justify-content: flex-end;
	}

	.websiteni-flex-vt-xxl {
		align-items: flex-start;
	}

	.websiteni-flex-vc-xxl {
		align-items: center;
	}

	.websiteni-flex-vb-xxl {
		align-items: flex-end;
	}

	.websiteni-flex-alt-xxl {
		display: flex;
		flex-direction: column;
	}

	.websiteni-flex-alt-hs-xxl {
		align-items: flex-start;
	}

	.websiteni-flex-alt-hc-xxl {
		align-items: center;
	}

	.websiteni-flex-alt-he-xxl {
		align-items: flex-end;
	}

	.websiteni-flex-alt-vt-xxl {
		justify-content: flex-start;
	}

	.websiteni-flex-alt-vc-xxl {
		justify-content: center;
	}

	.websiteni-flex-alt-vb-xxl {
		justify-content: flex-end;
	}

	/* Text Alignment Classes. */
	.text-left-xxl {
		text-align: left;
	}

	.text-center-xxl {
		text-align: center;
	}

	.text-right-xxl {
		text-align: right;
	}

	/* Hide/Show Classes. */
	.hide-for-xxl {
		display: none;
	}

	.show-for-xxl {
		display: inline-flex;
	}
}