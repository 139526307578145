/**
* WebsiteNIJointsMagento
* WebsiteNI Starter Theme built on the Magento 2 Blank Theme, SnowdogApps and Frontools; https://github.com/SnowdogApps/magento2-theme-blank-sass and https://github.com/SnowdogApps/magento2-frontools.
* Created by WebsiteNI.
*/

/**
* _header - Small.
*/
.global-pre-header {
	position: relative;
	z-index: 9999;

	.login-custom {
		height: 120px;
		padding-top: 2rem;
		padding-bottom: 2rem;
		margin: -120px 0px 0px 0px;
		background-color: $offWhite;
		opacity: 0;
		visibility: hidden;
		transition: .4s cubic-bezier(0.51, 0.18, 0, 0.88);

		a {
			width: 135px;
			padding: 0rem 1rem 0rem 1rem;
			font-size: 14px;

			&:first-of-type {
				margin: 0rem .9375rem 0rem 0rem;
			}
		}

		button {
			width: 50px;
			height: 50px;
			top: 0;
			right: 0;
			display: block;
			background-color: transparent;
            border: 1px solid $grey;
            border-radius: 100%;
            color: $grey;
            font-size: 30px;
            line-height: 30px;
            opacity: 1;
		}

		&.active {
			margin: 0;
			opacity: 1;
			visibility: visible;
		}
	}

	.inner {
		padding-top: 1rem;
		padding-bottom: 1rem;
		background-color: $black;
		border-bottom: 1px solid rgba($white, .1);

		.block-search {
			width: 100%;
			padding: 0;
			margin: 0;
			order: 1;
			float: none;
			z-index: 0;

			.action.search {
				padding: 0 !important;
			}

			form {
				position: relative;

				label {
					display: none;
				}

				.control {
					padding: 0;
					margin: 0;
					border: none;

					input {
						width: 100%;
						height: 40px;
						padding: 0rem 1rem 0rem 1rem;
						margin: 0;
						left: 0;
						position: relative;
						background-color: $white;
						border: none;
						border-radius: 5px;
						box-shadow: none;
						color: $grey !important;
						font-family: $roboto !important;
						font-size: 14px !important;
						font-weight: 400 !important;
						font-style: normal !important;
						line-height: 40px !important;
						z-index: 0 !important;

						&:focus {
							color: $grey !important;
							font-family: $roboto !important;
							font-size: 14px !important;
							font-weight: 400 !important;
							font-style: normal !important;
							line-height: 40px !important;
						}
					}

					.search-autocomplete {
						margin: -4px 0px 0px 0px;
						border-radius: 5px;
						box-shadow: 3px 3px 12px rgba($black, .3);
						overflow: auto;
						z-index: 1;

						ul {
							li {
								width: 100%;
								height: 40px;
								padding: .625rem .9375rem .625rem .9375rem;
								display: flex;
								align-items: center;
								background-color: $white;
								border: none;
								border-radius: 0;
								color: $grey;
								font-family: $roboto;
								font-size: 14px;
								font-weight: 400;
								font-style: normal;
								line-height: 40px;
								transition: .2s cubic-bezier(0.51, 0.18, 0, 0.88);

								.amount {
									top: 50%;
									right: .9375rem;
									transform: translateY(-50%);
									color: $grey;
									font-family: $roboto;
									font-size: 12px;
									font-weight: 700;
									font-style: normal;
									line-height: 40px;
								}

								&:hover {
									background-color: $offWhite;
								}
							}
						}
					}

					.nested {
						padding: .625rem 0rem 0rem 0rem;
						position: relative;
						display: none;

						a {
							position: relative;
						    display: inline-block;
						    color: $white;
						    font-family: $roboto;
						    font-size: 14px;
						    font-weight: 400;
						    font-style: normal;
						    line-height: 14px;
						    text-decoration: none !important;

						    &:before,
						    &:after {
						        width: 0;
						        height: 2px;
						        top: calc(100% + 6px);
						        right: 0;
						        display: block;
						        position: absolute;
						        content: '';
						    }

						    &:before {
						        background-color: rgba($white, .2);
						        transition: width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s;
						    }

						    &:after {
						        background-color: $white;
						        transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
						    }
						}
					}

					.search-category {
						max-width: 107.5px;
						height: 40px;
						right: 40px !important;

						select {
							width: 100%;
							height: 40px;
							background-color: $offWhite;
							border: none;
							border-radius: 5px;
							outline: none !important;
							color: $grey;
							font-family: $roboto;
						    font-size: 14px;
						    font-weight: 400;
						    font-style: normal;
						    line-height: 40px;
						}
					}

					.autocomplete-suggestions {
						padding: 1rem;
						background-color: $white;
						border-radius: 5px;

						.autocomplete-suggestion {
							margin: 0rem 0rem 1rem 0rem;

							a {
								display: flex;
								align-items: center;
								text-decoration: none !important;

								.suggestion-left {
									padding: .5rem;
									margin: 0rem 1rem 0rem 0rem;
									border: 1px solid $lightGrey;

									img {
										width: 75px;
										max-width: none;
										height: 75px;
										object-fit: cover;
										object-position: center;
									}
								}

								.suggestion-right {
									.product-line {
										color: $black;
										font-family: $roboto;
										font-size: 16px;
										font-weight: 400;
										font-style: normal;
										line-height: 24px;

										&.product-price {
											font-weight: 700;
										}
									}
								}
							}

							&:last-of-type {
								margin: 0;
							}
						}
					}
				}

				.actions {
					button {
						width: 40px;
						height: 40px;
						top: 0;
						right: 0;
						position: absolute;
						display: flex;
						justify-content: center;
						align-items: center;
						background-color: $yellow;
						border: none;
						border-radius: 5px;
						opacity: 1;

						span {
							display: none;
						}

						&:before {
							color: $grey;
							font-family: 'icons-blank-theme';
							font-weight: normal;
							content: '';
						}
					}
				}
			}
		}

		button {
			&.text-link {
				width: 50%;
				padding: 0;
				order: 2;
				background-color: transparent;
				border: none;
				color: $white;
			}
		}

		.links {
			width: 100%;
			margin: .625rem 0rem 0rem 0rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			order: 3;
			list-style: none;

			.authorization-link, li:first-of-type {
				padding: 0rem .9375rem 0rem 0rem;
				margin: 0;

				a {
					position: relative;
				    display: inline-block;
				    color: $white;
				    font-family: $roboto;
				    font-size: 14px;
				    font-weight: 400;
				    font-style: normal;
				    line-height: 14px;
				    text-decoration: none !important;

				    &:before,
				    &:after {
				        width: 0;
				        height: 2px;
				        top: calc(100% + 6px);
				        right: 0;
				        display: block;
				        position: absolute;
				        content: '';
				    }

				    &:before {
				        background-color: rgba($white, .2);
				        transition: width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s;
				    }

				    &:after {
				        background-color: $white;
				        transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
				    }
				}
			}

			.minicart-wrapper {
				padding: 0;
				margin: 0;

				a {
					.counter.qty {
						min-width: auto;
						height: 33px;
					    padding: 0rem 0rem 0rem .5rem;
					    margin: 0;
					    top: 1px;
					    position: relative;
					    display: inline-flex;
					    background-color: transparent;
					    border-radius: 0;
					    color: $white;
					    font-family: $roboto;
					    font-size: 14px;
					    font-weight: 400;
					    font-style: normal;
					    line-height: 33px;
					    text-align: left;

					    .counter-label {
					    	width: auto;
					    	height: 33px;
					    	margin: 0;
					    	position: relative;
					    	line-height: 33px;
					    	text-transform: capitalize;
					    }

					    .counter-number {
					    	display: none;
					    }
					}

					&:before {
						content: url('../images/cart.svg');
					}
				}
			}

			.compare, .welcome {
				display: none;
			}
		}

		.store-switcher {
			display: none !important;
		}
	}
}

.amsearch-form-container {
	.control {
		min-height: 40px !important;

		.search-autocomplete {
			max-height: 50vh !important;
			max-width: 100% !important;

			.amsearch-results {
				padding: .9375rem;
				background-color: $white;

				.amsearch-leftside {
					padding: 0;
					max-width: 19%!important;

					.amsearch-item-container {
						padding: 0rem 2rem 2rem 0rem;

						.amasty-xsearch-block-header {
							padding: 0rem 0rem 2rem 0rem;
							margin: 0;
							color: $black;
							font-family: $roboto;
							font-size: 20px;
							font-weight: 700;
							font-style: normal;
							line-height: 20px;
							text-transform: none;
						}

						.amsearch-item {
							padding: 0;
							margin: 0rem 0rem .625rem 0rem;

							a {
								color: $black;
								font-family: $roboto;
								font-size: 16px;
								font-weight: 700;
								font-style: normal;
								line-height: 16px;
								text-decoration: none;

								span {
									color: $yellow;
									font-family: $roboto;
									font-size: 16px;
									font-weight: 700;
									font-style: normal;
									line-height: 16px;
									text-decoration: underline;
								}
							}							

							&:last-of-type {
								margin: 0;
							}

							&:hover {
								background-color: transparent;
							}
						}

						&:first-of-type {
							.amasty-xsearch-block-header {
								display: none;
							}
						}

						&:last-of-type {
							padding: 0rem 2rem 0rem 0rem;
						}
					}
				}
				#amasty-shopby-product-list{
					max-width: 80%!important;
				}
				.amsearch-products {
					padding: 0;
					border-left: 1px solid $offWhite;
					max-width: 100%!important;

					.amasty-xsearch-block-header {
						padding: 0rem 0rem 2rem 2rem;
						margin: 0;
						color: $black;
						font-family: $roboto;
						font-size: 20px;
						font-weight: 700;
						font-style: normal;
						line-height: 20px;
						text-transform: none;
					}

					.amsearch-wrapper-content {
						padding: 0rem 0rem 2rem 2rem;

						.amsearch-item {
							margin: 0rem 0rem .625rem 0rem;

							.amsearch-autocomplete-image {
								width: 120px;
								height: 120px;
								padding: 5px;
								border: 1px solid $lightGrey;

								img {
									border: none;
								}
							}

							.amsearch-autocomplete-information {
								padding: 0rem 0rem 0rem 1rem;
								display: flex;
								align-items: center;

								.information-container {
									.product-item-details {
										min-height: auto;
										border: none;

										a {
											color: $black;
											font-family: $roboto;
											font-size: 16px;
											font-weight: 700;
											font-style: normal;
											line-height: 16px;
											text-decoration: none;

											span {
												padding: 0;
												background-color: transparent;
												color: $yellow;
												font-family: $roboto;
												font-size: 16px;
												font-weight: 700;
												font-style: normal;
												line-height: 16px;
												text-decoration: underline;
											}
										}
									}
								}
							}

							&:last-of-type {
								margin: 0;
							}

							&:hover {
								background-color: transparent;
							}
						}
					}

					.amsearch-more-results {
						padding: 0rem 0rem 0rem 2rem;

						a {
							border: none;
							color: $black;
							font-family: $roboto;
							font-size: 16px;
							font-weight: 700;
							font-style: normal;
							line-height: 16px;
							text-decoration: none;
						}
					}
				}
			}
		}

		.amsearch-wrapper-input {
			max-width: 100% !important;

			input {
				color: $grey !important;
				font-family: $roboto !important;
				font-size: 14px !important;
				font-weight: 400 !important;
				font-style: normal !important;
				line-height: 40px !important;

				&:focus {
					color: $grey !important;
					font-family: $roboto !important;
					font-size: 14px !important;
					font-weight: 400 !important;
					font-style: normal !important;
					line-height: 40px !important;
				}
			}

			.amasty-xsearch-loader {
				width: 40px;
				height: 40px;
				padding: 20px;
			    margin: 0;
			    top: 0;
				right: 80px;
				position: absolute;
				background-color: transparent;
			    border: none;
			    border-radius: 5px;
			}

			.amsearch-close {
			    width: 40px;
			    height: 40px;
			    padding: 20px;
			    margin: 0;
			    top: 0;
			    right: 40px;
			    position: absolute;
			    display: flex;
			    justify-content: center;
			    align-items: center;
			    background-color: $offWhite;
			    border: none;
			    border-radius: 5px;
			    opacity: 1;
			    filter: brightness(100%);
			}

			.amsearch-loupe {
				display: none !important;
			}
		}
	}
}

.amsearch-item.product-item .product.product-item-description {
	margin: 10px 0px;
	border: 0px;
	font-size: 13px;
}

.global-header {
	position: relative;
	z-index: 99999;

	.inner {
		padding-top: 1rem;
		padding-bottom: 1rem;
		background-color: $black;

		span {
			color: $white;
		    font-family: $roboto;
		    font-size: 14px;
		    font-weight: 400;
		    font-style: normal;
		    line-height: 14px;
		}

		.contact-numbers {
			ul {
				margin: 0;
				display: flex;
				flex-flow: row wrap;
				justify-content: flex-end;
				list-style: none;

				li {
					padding: 0rem 1.875rem 0rem 0rem;
					margin: 0rem 1.875rem 0rem 0rem;
					position: relative;
					color: $white;
					font-size: 14px;
				    font-weight: 700;
				    font-style: normal;
				    line-height: 14px;

					a {
						position: relative;
					    display: inline-block;
					    color: $white;
					    font-family: $roboto;
					    font-size: 14px;
					    font-weight: 400;
					    font-style: normal;
					    line-height: 14px;
					    text-decoration: none !important;

					    &:before,
					    &:after {
					        width: 0;
					        height: 2px;
					        top: calc(100% + 6px);
					        right: 0;
					        display: block;
					        position: absolute;
					        content: '';
					    }

					    &:before {
					        background-color: rgba($white, .2);
					        transition: width .4s cubic-bezier(0.51, 0.18, 0, 0.88) .1s;
					    }

					    &:after {
					        background-color: $white;
					        transition: width .2s cubic-bezier(0.29, 0.18, 0.26, 0.83);
					    }
					}

					&:before {
						top: -3px;
						left: -1.875rem;
						position: absolute;
						content: url('../images/phone.svg');
					}

					&:last-of-type {
						padding: 0;
						margin: 0;
					}
				}
			}
		}
	}
}


.fixed .navigation .submenu:not(:first-child){
	overflow-x: initial!important;
}

// .fixed li.level1.category-item.parent.ui-menu-item{
// 	width: 90%;
// 	margin: 0;
// 	position: relative;
// 	display: block;
// 	&:after{
// 		content: "\002B";
// 	    transition: .25s ease-in-out;
// 		cursor: pointer;
// 		position: absolute;
//         right: -10%;
//         top: 0px;
//         text-align: center;
//         z-index: 99;
//         color:#333;
//         font-weight: 700;
//         font-size: 26px;
//         display: block;
//         width: 10%;
//         // height: 100%;
//         // background: #dcb626;
// 	}
// }
// .fixed li.level1.category-item.parent.active.ui-menu-item:after {
// 		    -ms-transform: rotate(45deg);
// 	    -webkit-transform: rotate(45deg);
// 	            transform: rotate(45deg);
// 	    transform-origin: 50% 50%;
// }



@media screen and (max-width: 639px) {
	.navigation .parent ul .level1.level-top:after {
        top: 5px!important;
	}
	.navigation ul.level1.submenu{
		background-color: #e4bd06;
		li{
			margin-left: 5px;
			padding-left: 5px;
		}
	}
	.navigation .level0 > .level-top { //fixes padding issue on submenus (mobile)
        padding: 0!important;
	}
	.navigation .level0.active > a:not(.ui-state-active), .navigation .level0.has-active > a:not(.ui-state-active),
	.navigation .submenu:not(:first-child) .active > a {
        border-width: 0px!important;
	}
	.navigation .level0.active > a:not(.ui-state-active) span:not(.ui-menu-icon), .navigation .level0.has-active > a:not(.ui-state-active) span:not(.ui-menu-icon) {
        margin-left: 0px!important;
	}
}
// 	.fixed li.level1.category-item.parent.ui-menu-item{
// 		width: calc(100% - 50px);
// 		margin: 0;
// 		position: relative;
// 		display: block;
// 	}
// 	// .fixed li.level1.category-item.parent.ui-menu-item .mobmenumore:before{
// 	// 	content: "\002B";
// 	//     transition: .25s ease-in-out;
// 	// 	cursor: pointer;
// 	// 	position: absolute;
// 	//     right: -10%;
// 	//     top: 0px;
// 	//     text-align: center;
// 	//     z-index: 99;
// 	//     color:#333!important;
// 	//     font-weight: 700;
// 	//     font-size: 26px;
// 	//     display: block;
// 	//     width: 10%;
// 	//     height: 50px;
// 	//     padding-top: 15px;
// 	//     // height: 100%;
// 	//     // background: #dcb626;
// 	// }
// 	li.level1.parent .mobmenumore{
// 		position: absolute;
// 		right: -50px;
// 		top: 0px; // these are coming off the before
// 		width: 50px;
// 		height: 50px;
// 		z-index: 9999999;
// 		background-color: #c5a50e;
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		cursor: pointer;

// 		&:before{
// 			content: "\002B";
// 			transition: .25s ease-in-out;
// 			cursor: pointer;
// 			text-align: center;
// 			z-index: 99;
// 			color: #333 !important;
// 			font-weight: 700;
// 			font-size: 26px;
// 			display: block;
// 			width: 100%;
// 			height: 100%;
// 			padding-top: 15px;
// 		}
// 	}




/**
* _header - Medium.
*/
@media screen and (min-width: 640px) {
    .global-pre-header {
    	.inner {
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;

			.block-search {
				width: calc(60% - .9375rem);
				margin: 0rem .9375rem 0rem 0rem;
			}

			button {
				&.text-link {
					width: auto;
				}
			}

			.links {
				width: auto;
				margin: 0rem 0rem 0rem .9375rem;
				justify-content: flex-end;
			}

			.cell {
				justify-content: space-between;
			}
		}
	}
}

/**
* _header - Large.
*/
@media screen and (min-width: 1024px) {
    .global-pre-header {
    	.inner {
			padding-top: 2rem;
			padding-bottom: 2rem;

			.block-search {
				width: 66%;

				form {
					.control {
						.nested {
							a {
								&:hover {
						            &:before, &:after {
						                width: 100%;
						                left: 0;
						            }
						        }
						    }
						}
					}
				}
			}

			.links {
				.authorization-link {
					a {
						&:hover {
				            &:before, &:after {
				                width: 100%;
				                left: 0;
				            }
				        }
				    }
				}
			}
		}
	}

	.global-header {
		.inner {
			.contact-numbers {
				ul {
					li {
						a {
							&:hover {
					            &:before, &:after {
					                width: 100%;
					                left: 0;
					            }
					        }
						}
					}
				}
			}
		}
	}
}

/**
* _header - XL.
*/
@media screen and (min-width: 1200px) {
    
}

/**
* _header - XXL.
*/
@media screen and (min-width: 1440px) {
    
}

/**
* _header - Small Only.
*/
@media screen and (max-width: 639px) {
    .global-header {
    	.inner {
    		.hamburger {
				padding: 0;
				position: relative;
				display: flex;
				background-color: transparent !important;
				border: none !important;
				outline: 0;
				z-index: 2;

				.hamburger-box {
					width: 25px;
					height: 18px;

					.hamburger-inner {
						width: 25px;
						height: 2px;
						background-color: $white;
						border-radius: 0;

						&:before, &:after {
							height: 2px;
							background-color: $white;
							border-radius: 0;
						}

						&:before {
							width: 15px;
							top: 8px;
						}

						&:after {
							width: 20px;
							top: 16px;
						}
					}
				}

				&:hover {
					opacity: 1 !important;
				}

				&.is-active {
					.hamburger-box {
						.hamburger-inner {
							&:after {
								width: 25px;
								transform: translate3d(0px, -16px, 0px) rotate(-90deg);
							}
						}
					}

					&:hover {
						opacity: 1 !important;
					}
				}
			}

    		.navigation {
    			width: calc(100% - .625rem - 25px - .625rem);
    			height: 100%;
    			top: 0;
    			left: 0;
    			transform: translateX(-100%);
    			position: fixed;
    			background-color: $yellow;
    			box-shadow: 6px 6px 24px rgba($black, 0);
    			overflow-x: hidden;
    			overflow-y: scroll;
    			transition: .4s cubic-bezier(0.51, 0.18, 0, 0.88);

    			ul {
    				padding: 1rem;
    				margin: 0;
    				list-style: none;

    				li {
    					margin: 0;
    					border: none;
    					border-top: 1px solid rgba($black, .05);

    					a {
    						padding: 0 !important;
    						position: relative;

    						span {
    							display: block;
	    						color: $black;
	    						font-family: $roboto;
	    						font-weight: 700;
	    						font-style: normal;
	    						line-height: 64px;
	    						text-transform: capitalize;
	    					}
    					}

    					& > a {
    						&:after {
    							width: 15px;
    							height: 15px;
    							top: 50%;
    							right: 0;
    							transform: translateY(-50%);
    							position: absolute;
    							display: flex;
    							justify-content: center;
    							align-items: center;
    							color: $black;
    							font-size: 40px;
    						}
    					}

    					ul {
    						padding: 0;
    						top: 0 !important;
    						border: none;

    						li {
    							border-bottom: none !important;

    							a {
    								span {
    									font-weight: 400;
    									line-height: 48px;
    								}
    							}

    							&:first-of-type {
    								display: none;
    							}
    						}
    					}

    					&.other-brands {
	    					ul {
	    						display: flex;
	    						justify-content: space-between;
	    						flex-flow: row wrap;

	    						li {
	    							width: calc(50% - 1rem / 2);
	    							margin: 0rem 0rem 1rem 0rem;
	    							border: 1px solid rgba($black, .05) !important;

	    							a {
	    								img {
	    									filter: invert(1);
	    								}
	    							}
	    						}
	    					}
	    				}

	    				&:last-of-type {
	    					border-bottom: 1px solid rgba($black, .05);
	    				}
    				}

    				p {
    					display: none;
    				}
    			}

    			&.is-active {
    				transform: translateX(0);
    				box-shadow: 6px 6px 24px rgba($black, 1);
    			}
    		}
    	}
    }
}

/**
* _header - Medium Up.
*/
@media screen and (min-width: 640px) {
	.global-header .inner .navigation ul li.level0.has-active > a span {
		color: $yellow;
	}

	.global-header .inner .navigation .level0.active > a:not(.ui-state-active) span:not(.ui-menu-icon) {
		margin-left: 0 !important;
	}

	.global-header .inner .navigation .level0.has-active > a:not(.ui-state-active) span:not(.ui-menu-icon) {
		margin-left: 0 !important;
	}

    .global-header {
    	position: relative;
    	z-index: 999;

    	.inner {
    		.navigation {
    			background-color: transparent;
    			z-index: 0;

    			ul {
    				padding: 0;
    				margin: 0;
    				position: relative;
    				display: flex;
    				justify-content: flex-start;
    				align-items: center;
    				list-style: none;

    				li:not(.other-brands):not(.other-brand) {
    					height: 50px;
    					padding: 0px 25px 0px 0px;
    					margin: 0;
    					position: static;
    					border: none;

    					a {
    						height: 50px;
    						padding: 0px;
    						display: flex;
    						background-color: transparent !important;
    						border: none !important;
    						line-height: 50px;
    						text-decoration: none !important;

    						span {
    							display: block;
    							color: $white;
							    font-family: $roboto;
							    font-size: 13px;
							    font-weight: 700;
							    font-style: normal;
							    line-height: 50px;
							    text-transform: none;
    						}

    						&:before, &:after {
    							display: none;
    						}

    						&:hover {
    							span {
    								color: $yellow;
    							}
    						}
    					}

    					&.parent a {
    						padding: 0px 25px 0px 0px;
    					}

    					ul {
    						// width: calc(100% + 1.875rem);
    						min-width: 444px;
    						padding: 30px .9375rem 30px .9375rem;
    						// left: -.9375rem !important;
    						position: absolute;
    						display: none;
    						flex-direction: column;
    						align-items: flex-start;
    						background-color: $lightGrey;
    						border: none;
    						box-shadow: none;

    						li {
    							width: 100%;
    							height: auto;
    							padding: 0 !important;

    							a {
    								width: 100%;
    								height: auto;
    								padding: 0rem .9375rem 0rem 0rem !important;
    								position: relative;
    								// border-bottom: 1px solid $mediumGrey !important;
		    						// border-right: 1px solid $mediumGrey !important;
    								line-height: 32px;

    								span {
    									color: $black;
    									line-height: 32px;
    								}

    								&:before, &:after {
    									display: none !important;
    								}

    								&:hover {
    									background-color: transparent;

    									span {
    										color: $black !important;
    									}
    								}
    							}

    							ul.level1 {
    								min-height: 100%;
    								padding: 30px 60px 30px 60px;
    								left: 100% !important;

    								li {
    									width: 100%;
    									border: none !important;
    									
    									a {
    										border: none !important;

    										span {
    											font-weight: 400;
    											line-height: 27px;
    										}

    										&:before, &:after {
    											display: none !important;
    										}
    									}

    									ul {
    										display: none !important;
    									}
    								}
    							}

    							&.nav-1-9 {
    								ul.level1 {
    									min-width: 512px;
    									display: block;

    									li {
    										width: 50%;
    									}
    								}
    							}

    							/*&.parent {
    								a {
	    								&:after {
	    									top: 7px;
	    									right: 0;
	    									position: absolute;
	    									display: block !important;
	    									color: $yellow;
	    									font-family: 'Poppins';
	    									font-size: 14px;
	    									font-weight: 400;
	    									line-height: 14px;
	    									content: '>';
	    								}
	    							}
    							}*/

    							/*&.active, &.has-active {
    								ul {
    									display: block !important;
    								}
    							}*/

    							&.has-active {
    								& > a {
    									span {
    										color: $yellow;
    									}
    								}
    							}

    							&.all-category {
    								display: none;
    							}

    							&:hover {
    								a.ui-state-focus {
    									// border-right: 1px solid transparent !important;
    								}
    							}
    						}

    						&.level0 {
    							li {
    								& > a {
    									&:after {
    										top: 9px;
	    									right: .9375rem;
	    									position: absolute;
	    									display: block !important;
	    									color: $black;
	    									font-family: 'Poppins';
	    									font-size: 16px;
	    									font-weight: 400;
	    									line-height: 16px;
	    									content: '>';
    									}
    								}
    							}
    						}
    					}

    					&.nav-1, &.nav-2 {
    						padding: 0px 25px 0px 0px;
    					}

    					&.parent {
    						a {
    							&:before {
	    							top: 50%;
	    							right: 0;
	    							transform: translateY(-50%) rotate(90deg);
	    							position: absolute;
	    							display: block;
	    							color: $yellow;
	    							font-family: 'Poppins';
									font-size: 20px;
									font-weight: 400;
									line-height: 20px;
									content: '>';
	    						}
    						}
    					}

    					&.nav-1-9 {
    						ul {
    							// display: flex;
    							// flex-flow: row wrap;
    							columns: 2 200px;
						        column-gap: 2em;
    							// li {
    							// 	width: 50% !important;
    							// 	float: left;
    							// }
    						}
    					}

    					&.active {
    						& > a {
    							span {
    								color: $yellow;
    							}
    						}
    					}

    					&:first-of-type {
    						a {
    							padding: 0;
    						}
    					}

    					&:first-of-type {
    						padding-left: 0;
    					}

    					&:last-of-type {
    						padding-right: 0;
    					}
    				}

    				li.other-brands {
    					height: 50px;
    					padding: 0px 25px 0px 0px;
    					margin: 0;
    					position: static;
    					border: none;

    					& > a:not(.other-brand-a) {
    						height: 50px;
    						padding: 0px 25px 0px 0px;
    						display: flex;
    						background-color: transparent !important;
    						border: none !important;
    						line-height: 50px;
    						text-decoration: none !important;

    						span {
    							display: block;
    							color: $white;
							    font-family: $roboto;
							    font-size: 13px;
							    font-weight: 700;
							    font-style: normal;
							    line-height: 50px;
							    text-transform: none;
    						}

    						&:before, &:after {
    							display: none;
    						}

    						&:hover {
    							span {
    								color: $yellow;
    							}
    						}
    					}

    					ul {
    						width: calc(100% + 1.875rem);
    						padding: 30px .9375rem calc(30px - .9375rem) .9375rem;
							left: -.9375rem !important;
    						position: absolute;
    						display: flex;
    						display: none;
    						justify-content: space-between;
    						flex-flow: row wrap;
    						background-color: $lightGrey;

    						li {
    							width: calc(33.33333% - .9375rem);
    							margin: 0rem calc(.9375rem / 2) .9375rem calc(.9375rem / 2);
    							display: flex;
    							border: 1px solid $black;
    							float: left;

    							a {
    								padding: 0;

    								img {
    									mix-blend-mode: exclusion;
    								}

    								&:before {
    									display: none !important;
    								}
    							}

    							&.other-brand-5 {
		    						order: 10;
		    					}

		    					&.other-brand-6 {
		    						order: 6;
		    					}

		    					&.other-brand-7 {
		    						order: 7;
		    					}

		    					&.other-brand-8 {
		    						order: 1;
		    					}

		    					&.other-brand-9 {
		    						order: 2;
		    					}

		    					&.other-brand-10 {
		    						order: 4;
		    					}

		    					&.other-brand-11 {
		    						order: 3;
		    					}

		    					&.other-brand-12 {
		    						order: 5;
		    					}

		    					&.other-brand-13 {
		    						order: 9;
		    					}

		    					&.other-brand-14 {
		    						order: 8;
		    					}

    							&:nth-of-type(1), &:nth-of-type(2), &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5) {
    								display: none;
    							}
    						}
    					}


    					&.nav-3 {
    						padding: 0px 25px 0px 0px;
    					}

    					&.parent {
    						a {
    							&:before {
	    							top: 50%;
	    							right: 0;
	    							transform: translateY(-50%) rotate(90deg);
	    							position: absolute;
	    							display: block;
	    							color: $yellow;
	    							font-family: 'Poppins';
									font-size: 20px;
									font-weight: 400;
									line-height: 20px;
									content: '>';
	    						}
    						}
    					}

    					&:hover {
    						ul {
    							display: flex !important;
    						}
    					}
    				}
    			}

    			& > ul {
    				justify-content: space-between;
    			}
    		}
    	}
    }
}

@media screen and (max-width: 767px) {
	.global-header {
    	.inner {
    		.navigation {
    			ul {
    				li:not(.other-brands):not(.other-brand) {
    					ul {
    						li {
								&.active, &.has-active {
    								ul {
    									display: block !important;
    								}
    							}
    						}
    					}
    				}
    			}
    		}
    	}
    }
}

/**
* _header - iPad Up.
*/
@media screen and (min-width: 768px) {
	.global-pre-header {
		.inner {
			.cell {
				justify-content: flex-end;
			}
		}
	}

	.global-header {
    	.inner {
    		.navigation {
    			ul {
    				display: flex;
    				justify-content: space-between;

    				li:not(.other-brands):not(.other-brand) {
    					// padding: 0px 50px 0px 0px;

    					ul {
    						li {
    							ul.level1 {
    								// width: 50% !important;
    								// left: 50% !important;

    								li {
    									a {
    										&:before, &:after {
    											display: none !important;
    										}
    									}
    								}
    							}

    							/*&.active {
    								ul.level1 {
    									display: block !important;
    								}
    							}*/
    						}
    					}

    					&.nav-1, &.nav-2 {
    						// padding: 0px 50px 0px 0px;
    					}
    				}

    				li.other-brands {
    					// padding: 0px 50px 0px 0px;

    					ul {
    						li {
    							width: calc(25% - .9375rem);
    						}
    					}

    					&.nav-3 {
    						// padding: 0px 50px 0px 0px;
    					}
    				}
    			}

    			& > ul {
    				justify-content: flex-start;
    			}
    		}
    	}
    }
}

/**
* _header - iPad Pro Up.
*/
@media screen and (min-width: 1024px) {
	.global-header {
    	.inner {
    		.navigation {
    			ul {
    				justify-content: flex-start;

    				/* li:not(.other-brands):not(.other-brand) {
    					ul {
    						width: 570px;
    					}
    				} */

    				li.other-brands {
						ul {
    						li {
    				// 			width: calc(20% - .9375rem);
    				            width: calc(25% - .9375rem);
    						}
    					}
    				}
    			}
    		}
    	}
    }
}

/**
* _header - 1200px Up.
*/
@media screen and (min-width: 1200px) {
	.global-header {
    	.inner {
    		.navigation {
    			ul {
    				li:not(.other-brands):not(.other-brand) {
    					ul {
    						padding: 60px 103px 60px 103px !important;

    						li {
    							ul.level1 {
    								padding: 60px 103px 60px 30px !important;
    							}
    						}
    					}
    				}

    				li.other-brands {
						ul {
							padding: 60px 103px calc(60px - .9375rem) 103px;

    						/* li {
    							width: calc(16.66667% - .9375rem);
    						} */
    					}
    				}
    			}
    		}
    	}
    }
}

/**
* _header - 1440px Up.
*/
@media screen and (min-width: 1440px) {
	.global-header {
    	.inner {
    		.navigation {
    			ul {
    				li:not(.other-brands):not(.other-brand) {
    					ul {
    						// width: 1290px;
    						padding: 60px 88px 60px 88px !important;
    						// left: 0 !important;

    						li {
    							// width: 25%;

    							ul.level1 {
    								// width: 70% !important;
    								// left: 30% !important;
    								padding: 60px 88px 60px 30px !important;
    								// display: flex;

    								li {
    									// width: 50%;
    									float: left;
    								}
    							}
    						}
    					}
    				}

    				li.other-brands {
    					ul {
    						width: 1290px;
    						padding: 60px 88px calc(60px - .9375rem) 88px;
    						left: 0 !important;

    						/* li {
    							width: calc(14.28571% - .9375rem);
    						} */
    					}
    				}
    			}
    		}
    	}
    }
}